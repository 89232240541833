import { Result } from 'antd'
import { Link } from 'wouter'
import { useTranslation } from 'react-i18next'
import Button from './Button'

export default function ElementNotFound ({ text, title, homeLink = '/' }) {
  const { t } = useTranslation()
  return (
    <Result
      status='404'
      title={title || t('notFound.message')}
      subTitle={text}
      extra={<Link to={homeLink}><Button type='primary'>{t('notFound.goHome')}</Button></Link>}
    />
  )
}

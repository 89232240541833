import { useState, useEffect, useCallback, useContext, createContext } from 'react'
import { ethers } from '@vechain/ethers'

import { useAuth } from './useAuth'
const bent = require('bent')

const VOTEOS_AUTH_BACKEND = process.env.REACT_APP_VOTEOS_AUTH_BACKEND
const fetchSession = bent('POST', `${VOTEOS_AUTH_BACKEND}/tokens`, 'json')
const { Certificate, secp256k1, blake2b256 } = require('thor-devkit')

const WalletContext = createContext()

export const useWallet = () => {
  const context = useContext(WalletContext)

  if (context === undefined) {
    throw new Error('useWallet must be used within an WalletProvider')
  }

  return context
}

const getWalletForPrivateKey = (privateKey) => privateKey ? new ethers.Wallet(privateKey) : ethers.Wallet.createRandom()

export const WalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState()
  const [session, setSession] = useState()
  const { user } = useAuth()

  const loadCustodianWallet = useCallback(async () => {
    if (!user?.privKey) {
      return setWallet()
    }

    const wallet = getWalletForPrivateKey(user.privKey)
    setWallet(wallet)
  }, [user])

  useEffect(() => {
    loadCustodianWallet()
  }, [loadCustodianWallet])

  useEffect(() => {
    if (!wallet) {
      return setSession()
    }

    refreshVoteosSession(wallet)
  }, [wallet])

  async function refreshVoteosSession (wallet) {
    const { token } = await fetchSession()
    const certificate = signPayloadWithWallet(token, wallet)
    const signedSessionData = Buffer.from(JSON.stringify(certificate), 'utf-8').toString('base64')
    setSession(signedSessionData)
  }

  const value = {
    wallet,
    session,
    regenerate: () => { }
  }

  return <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
}

function signPayloadWithWallet (payload, wallet) {
  const certificate = {
    purpose: 'identification',
    payload: {
      type: 'text',
      content: payload
    },
    domain: 'voteos.cloud',
    timestamp: +(new Date()),
    signer: wallet.address
  }

  const jsonStr = Certificate.encode(certificate)
  certificate.signature = '0x' + secp256k1.sign(blake2b256(jsonStr), Buffer.from(wallet.privateKey.slice(2), 'hex')).toString('hex')

  return certificate
}

import styled from 'styled-components'
import { Spin, Typography } from 'antd'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Description = styled(Typography.Text)`
  padding-top: 8px;
`

const Loading = ({ description }) => {
  return (
    <Container>
      <Spin size='large' />
      <Description>{description}</Description>
    </Container>
  )
}

export default Loading

import { Typography } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import getColumnSearchProps from '../../../common/TableFilter'
import Table from '../../../common/Table'
const { Text } = Typography

const CONNEX_NETWORK_EXPLORER = process.env.REACT_APP_BLOCKCHAIN_EXPLORER

const PaymentHistory = ({ data = [], ...restProps }) => {
  const { t } = useTranslation()

  const COLUMNS = [
    {
      title: t('organization.audit.timestamp'),
      dataIndex: 'timestamp',
      key: 'time',
      width: '25%',
      sorter: (a, b) => a.timestamp - b.timestamp,
      ...getColumnSearchProps({ dataIndex: 'time', handleSearch: (keys, confirm) => confirm() }),
      render: timestamp => (new Date(timestamp * 1000)).toISOString().split('.')[0].split('T').join(' ')
    },
    {
      title: t('organization.audit.content'),
      key: 'text',
      ellipsis: true,
      filterSearch: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.name - b.name,
      ...getColumnSearchProps({ dataIndex: 'name', handleSearch: (keys, confirm) => confirm() }),
      render: ({ name, inputs, values }) => {
        if (name === 'SetSubscribed') {
          return <Text>{t('organization.account.subscription' + (values?.value ? 'Started' : 'Canceled'))}</Text>
        }
        return (
          <>
            <Text>{name}</Text>
            <ul>
              {inputs.map(({ name }) => <li key={name}><Text type='secondary'>{name}: {values[name]}</Text></li>)}
            </ul>
          </>
        )
      }
    },
    {
      title: t('organization.audit.executor'),
      dataIndex: 'origin',
      key: 'origin',
      ellipsis: true,
      filterSearch: true,
      width: 350,
      onFilter: (value, record) => record.origin === value,
      sorter: (a, b) => a.origin - b.origin,
      ...getColumnSearchProps({ dataIndex: 'origin', handleSearch: (keys, confirm) => confirm() }),
      render: origin => <Typography.Link href={`${CONNEX_NETWORK_EXPLORER}/accounts/${origin}`} target='_blank' rel='noreferrer' code>{origin}</Typography.Link>
    },
    {
      dataIndex: 'txId',
      key: 'txId',
      width: 48,
      render: txId => <a href={`${CONNEX_NETWORK_EXPLORER}/transactions/${txId}`} target='_blank' rel='noreferrer'><LinkOutlined /></a>
    }
  ]

  return (
    <Table
      rowKey='txId'
      columns={COLUMNS}
      dataSource={data}
      scroll={{ x: 800 }}
      {...restProps}
    />
  )
}

export default PaymentHistory

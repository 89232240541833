import styled from 'styled-components'
import { Card, Row, Col } from 'antd'
import { HolderOutlined } from '@ant-design/icons'
import { Text } from '../../../../common/Typography'

const CardContainer = styled(Card)`
  border-radius: ${({ theme }) => theme.borderRadius};
  &:hover {
    box-shadow: 0px 9px 28px 8px #B5D0F2, 0px 6px 16px rgba(0, 0, 0, 0.08);
  }
  transition: box-shadow 0.3s ease-in-out;
  ${({ isDragging, theme }) => isDragging && `box-shadow: 0px 9px 28px 8px ${theme.voteos4}, 0px 6px 16px rgba(0, 0, 0, 0.08);`}
`

const ReorderCard = ({ title, ...restProps }) => {
  return (
    <CardContainer {...restProps}>
      <Row align='middle' wrap={false}>
        <Col flex={1}>
          <Text strong>{title}</Text>
        </Col>
        <HolderOutlined />
      </Row>
    </CardContainer>
  )
}

export default ReorderCard

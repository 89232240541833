import { randomBytes } from 'crypto';
import { ec as EC } from 'elliptic';
const curve = new EC('secp256k1');
const N = Buffer.from('fffffffffffffffffffffffffffffffebaaedce6af48a03bbfd25e8cd0364141', 'hex');
const ZERO = Buffer.alloc(32, 0);
function isValidPrivateKey(key) {
    return Buffer.isBuffer(key) &&
        key.length === 32 &&
        !key.equals(ZERO) &&
        key.compare(N) < 0;
}
function isValidMessageHash(hash) {
    return Buffer.isBuffer(hash) && hash.length === 32;
}
/** secp256k1 methods set */
export var secp256k1;
(function (secp256k1) {
    /**
     * generate private key
     * @param rng the optional random number generator, which exactly generates 32 random bytes
     */
    function generatePrivateKey(rng) {
        rng = rng || (() => randomBytes(32));
        for (;;) {
            const privKey = rng();
            if (isValidPrivateKey(privKey)) {
                return privKey;
            }
        }
    }
    secp256k1.generatePrivateKey = generatePrivateKey;
    /**
     * derive public key(uncompressed) from private key
     * @param privKey the private key
     */
    function derivePublicKey(privKey) {
        if (!isValidPrivateKey(privKey)) {
            throw new Error('invalid private key');
        }
        const keyPair = curve.keyFromPrivate(privKey);
        return Buffer.from(keyPair.getPublic().encode('array', false));
    }
    secp256k1.derivePublicKey = derivePublicKey;
    /**
     * sign a message using elliptic curve algorithm on the curve secp256k1
     * @param msgHash hash of message
     * @param privKey serialized private key
     */
    function sign(msgHash, privKey) {
        if (!isValidMessageHash(msgHash)) {
            throw new Error('invalid message hash');
        }
        if (!isValidPrivateKey(privKey)) {
            throw new Error('invalid private key');
        }
        const keyPair = curve.keyFromPrivate(privKey);
        const sig = keyPair.sign(msgHash, { canonical: true });
        const r = Buffer.from(sig.r.toArray('be', 32));
        const s = Buffer.from(sig.s.toArray('be', 32));
        return Buffer.concat([r, s, Buffer.from([sig.recoveryParam])]);
    }
    secp256k1.sign = sign;
    /**
     * recovery signature to public key
     * @param msgHash hash of message
     * @param sig signature
     */
    function recover(msgHash, sig) {
        if (!isValidMessageHash(msgHash)) {
            throw new Error('invalid message hash');
        }
        if (!Buffer.isBuffer(sig) || sig.length !== 65) {
            throw new Error('invalid signature');
        }
        const recovery = sig[64];
        if (recovery !== 0 && recovery !== 1) {
            throw new Error('invalid signature recovery');
        }
        const r = sig.slice(0, 32);
        const s = sig.slice(32, 64);
        return Buffer.from(curve.recoverPubKey(msgHash, { r, s }, recovery).encode('array', false));
    }
    secp256k1.recover = recover;
})(secp256k1 || (secp256k1 = {}));

import { Typography, Row, Col } from 'antd'
import Message from './Message'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import VerticalSpace from './VerticalSpace'

const { Title, Paragraph } = Typography

export default function DeleteConfirmation ({ address, title, onConfirm: handleConfirm, onCancel: handleCancel, error, loading, type, action = 'remove' }) {
  const { t } = useTranslation()
  return (
    <Row justify='space-around'>
      <Col xs={24} md={18} lg={12} xl={8}>
        <Title>{t(action)} {title}?</Title>
        <Paragraph type='secondary'>ID: {address}</Paragraph>
        <Paragraph>{t(`${type}.removeConfirm`, { title: title || address })} {type === 'organization' ? t('irreversible') : ''}.</Paragraph>
        {!!error && <Message type='error' text={error} />}
        <VerticalSpace>
          <Button block onClick={handleConfirm} danger loading={loading}>{t(`${type}.remove`)}</Button>
          <Button block onClick={handleCancel}>{t('cancel')}</Button>
        </VerticalSpace>
      </Col>
    </Row>
  )
}

import { Base58 } from '@vechain/ethers/utils/basex';
import * as HD from '@vechain/ethers/utils/hdnode';
import { createHash } from 'crypto';
import { ec as EC } from 'elliptic';
import { address } from './address';
// see https://github.com/satoshilabs/slips/blob/master/slip-0044.md
const VET_DERIVATION_PATH = `m/44'/818'/0'/0`;
const xpubPrefix = Buffer.from('0488b21e000000000000000000', 'hex');
const xprivPrefix = Buffer.from('0488ade4000000000000000000', 'hex');
const curve = new EC('secp256k1');
export var HDNode;
(function (HDNode) {
    /** create node from mnemonic words */
    function fromMnemonic(words) {
        // normalize words to lowercase
        const joinedWords = words.join(' ').toLowerCase();
        const node = HD.fromMnemonic(joinedWords).derivePath(VET_DERIVATION_PATH);
        return createHDNode(node);
    }
    HDNode.fromMnemonic = fromMnemonic;
    /**
     * create node from xpub
     * @param pub public key
     * @param chainCode chain code
     */
    function fromPublicKey(pub, chainCode) {
        const compressed = curve.keyFromPublic(pub).getPublic(true, 'array');
        const key = Buffer.concat([xpubPrefix, chainCode, Buffer.from(compressed)]);
        const checksum = sha256(sha256(key)).slice(0, 4);
        const node = HD.fromExtendedKey(Base58.encode(Buffer.concat([key, checksum])));
        return createHDNode(node);
    }
    HDNode.fromPublicKey = fromPublicKey;
    /**
     * create node from xpriv
     * @param priv private key
     * @param chainCode chain code
     */
    function fromPrivateKey(priv, chainCode) {
        const key = Buffer.concat([xprivPrefix, chainCode, Buffer.from([0]), priv]);
        const checksum = sha256(sha256(key)).slice(0, 4);
        const node = HD.fromExtendedKey(Base58.encode(Buffer.concat([key, checksum])));
        return createHDNode(node);
    }
    HDNode.fromPrivateKey = fromPrivateKey;
    function createHDNode(ethersNode) {
        const pub = Buffer.from(curve.keyFromPublic(ethersNode.publicKey.slice(2), 'hex').getPublic(false, 'array'));
        const priv = ethersNode.privateKey ? Buffer.from(ethersNode.privateKey.slice(2), 'hex') : null;
        const cc = Buffer.from(ethersNode.chainCode.slice(2), 'hex');
        const addr = address.fromPublicKey(pub);
        return {
            get publicKey() {
                return pub;
            },
            get privateKey() {
                return priv;
            },
            get chainCode() {
                return cc;
            },
            get address() {
                return addr;
            },
            derive(index) {
                return createHDNode(ethersNode.derivePath('' + index));
            }
        };
    }
    function sha256(data) {
        return createHash('sha256').update(data).digest();
    }
})(HDNode || (HDNode = {}));

import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import styled from 'styled-components'

const { Text } = Typography

const RedClose = styled(CloseCircleFilled)`
  color: #FF4D4F;
`

const GreenCheck = styled(CheckCircleFilled)`
  color: #52C41A;
`

const StyledText = styled(Text)`
  margin-left: 10px;
`

const Message = ({ text, type = 'success' }) => {
  const Icon = type === 'success' ? GreenCheck : RedClose

  return (
    <>
      <Icon />
      <StyledText>{text}</StyledText>
    </>
  )
}

Message.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error'])
}

export default Message

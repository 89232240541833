import styled from 'styled-components'
import { Form, Row, Col, Typography } from 'antd'
import Input, { TextArea } from '../../../common/Input'
import Button from '../../../common/Button'
import { useTranslation } from 'react-i18next'

const { Link } = Typography
const FormItem = styled(Form.Item)`
  .ant-form-item-row > .ant-form-item-label {
    color: ${({ theme }) => theme.darkGray}
  }
`
const ContentRow = styled(Row)`
  height: 100px;
  background: ${({ theme }) => theme.background};
  // match border of text area
  border: 1px solid #ccc;
  border-radius: ${({ theme }) => theme.borderRadius};
`
const Editor = ({ subject, topText, bottomText, link, onChange }) => {
  const { t } = useTranslation()

  const handleChange = key => event => {
    if (event && event.target && (event.target.value || event.target.value === '')) {
      onChange({ subject, topText, bottomText, [key]: event.target.value })
    }
  }

  return (
    <Form
      layout='vertical'
      size='large'
    >
      <FormItem label={t('election.mail.subject')}>
        <Input value={subject} onChange={handleChange('subject')} />
      </FormItem>
      <FormItem label={t('election.mail.introduction')}>
        <TextArea autoSize value={topText} onChange={handleChange('topText')} />
      </FormItem>
      <FormItem label={t('election.mail.link')}>
        <ContentRow align='middle' justify='space-around'>
          <Col span={24} align='center'>
            <Link>{link}</Link>
          </Col>
          <Button size='default' type='primary'>{t('election.mail.buttonText')}</Button>
        </ContentRow>
      </FormItem>
      <FormItem label={t('election.mail.main')}>
        <TextArea autoSize value={bottomText} onChange={handleChange('bottomText')} />
      </FormItem>
    </Form>
  )
}

export default Editor

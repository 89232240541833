import { Typography, Row, Col, InputNumber, Segmented } from 'antd'
import { PieChartOutlined, BarChartOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const { Text } = Typography
const Title = styled(Typography.Paragraph)`
  margin-bottom: 8px !important;
`

const NumberInput = styled(InputNumber)`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}
`

const ChartSelection = styled(Segmented)`
  width: 100%;
  .ant-segmented-group {
    justify-content: space-between;
    .ant-segmented-item {
      width: 48%;
    }
  }
`

const BarChart = styled(BarChartOutlined)`
  transform: scaleX(-1) rotate(-90deg);
`

const PollSettings = ({ minVotes = 1, maxVotes = 1, onChange, answerCount = 0, allowAbstention, resultDisplayType }) => {
  const { t } = useTranslation()

  const handleChangeMax = (max) => {
    if (max) {
      onChange('maxVotes', max)
    }
  }

  const handleChangeMin = (min) => {
    if (min) {
      onChange('minVotes', min)
    }
  }

  const chartTypes = [
    { value: 'bar', icon: <BarChart />, label: t('election.poll.barChart') },
    { value: 'pie', icon: <PieChartOutlined />, label: t('election.poll.donutChart') }
  ]

  return (
    <Row gutter={[16, 8]} align='top' justify='flex-start'>
      <Col sm={24} xl={12}>
        <Title>{t('election.poll.selectableAnswers')} </Title>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <NumberInput
              size='large'
              value={minVotes}
              onChange={handleChangeMin}
              min={1} max={answerCount}
            />
            <Text type='secondary'>{t('election.poll.min')}</Text>
          </Col>
          <Col span={12}>
            <NumberInput
              size='large'
              value={maxVotes || answerCount}
              onChange={handleChangeMax}
              min={1} max={answerCount}
            />
            <Text type='secondary'>{t('election.poll.max')}</Text>
          </Col>
        </Row>
      </Col>
      <Col sm={24} xl={12}>
        <Title>{t('election.poll.chartType')}</Title>
        <ChartSelection
          size='large'
          options={chartTypes}
          value={resultDisplayType}
          onChange={(value) => onChange('resultDisplayType', value)}
        />
      </Col>
    </Row>
  )
}

export default PollSettings

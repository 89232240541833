import { useEffect } from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useOrganization } from '@hpm/voteos-hooks'
import { Headline, Description } from '../../common/Typography'
import Table from '../../common/Table'
import getColumnSearchProps from '../../common/TableFilter'
import { LinkOutlined } from '@ant-design/icons'

const { Text } = Typography
const CONNEX_NETWORK_EXPLORER = process.env.REACT_APP_BLOCKCHAIN_EXPLORER

export default function OrganizationAudit ({ params }) {
  const { t } = useTranslation()
  const { events = [], loadEvents, listenForAllEvents, loading, setAddress } = useOrganization()
  useEffect(() => setAddress(params.address), [params, setAddress])

  const COLUMNS = [
    {
      title: t('organization.audit.timestamp'),
      dataIndex: 'timestamp',
      key: 'time',
      width: 200,
      sorter: (a, b) => a.timestamp - b.timestamp,
      ...getColumnSearchProps({ dataIndex: 'time', handleSearch: (keys, confirm) => confirm() }),
      render: timestamp => (new Date(timestamp * 1000)).toISOString().split('.')[0].split('T').join(' ')
    },
    {
      title: t('organization.audit.content'),
      key: 'text',
      ellipsis: true,
      filterSearch: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.name - b.name,
      ...getColumnSearchProps({ dataIndex: 'name', handleSearch: (keys, confirm) => confirm() }),
      render: ({ name, inputs = [], values }) => (
        <div key={name}>
          <Text>{name}</Text>
          <ul>
            {inputs.map(({ name }) => <li key={name}><Text type='secondary'>{name}: {values[name]}</Text></li>)}
          </ul>
        </div>
      )
    },
    {
      title: t('organization.audit.executor'),
      dataIndex: 'origin',
      ellipsis: true,
      filterSearch: true,
      width: 350,
      onFilter: (value, record) => record.origin === value,
      sorter: (a, b) => a.origin - b.origin,
      ...getColumnSearchProps({ dataIndex: 'origin', handleSearch: (keys, confirm) => confirm() }),
      render: origin => <Typography.Link href={`${CONNEX_NETWORK_EXPLORER}/accounts/${origin}`} target='_blank' rel='noreferrer' code>{origin}</Typography.Link>
    },
    {
      dataIndex: 'txId',
      width: 48,
      render: txId => <a href={`${CONNEX_NETWORK_EXPLORER}/transactions/${txId}`} target='_blank' rel='noreferrer'><LinkOutlined /></a>
    }
  ]

  useEffect(() => {
    loadEvents()
    const unlisteners = listenForAllEvents() || []
    return () => unlisteners.map(cb => cb())
  }, [loadEvents, listenForAllEvents])

  return (
    <>
      <Headline>{t('navigation.history')}</Headline>
      <Description margin={32}>{t('history.description')}</Description>
      <Table
        sticky
        bordered
        loading={loading}
        rowKey={({ meta, txId, name, timestamp, values, ...column }) => txId + name + timestamp + (values?.key || values?.organizer)}
        columns={COLUMNS}
        dataSource={events}
        scroll={{ x: 792 }}
      />
    </>
  )
}

import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Spin } from 'antd'
import { ContactsOutlined } from '@ant-design/icons'
import Button from '../../../../common/Button'
import Breadcrumb from '../../../../common/Breadcrumb'
import { Headline, Description } from '../../../../common/Typography'
import useVoterRegistry from '../../../../../hooks/useVoterRegistry'
import { useTranslation } from 'react-i18next'
import { useOrganization } from '@hpm/voteos-hooks'
import { useBeforeunload } from 'react-beforeunload'
import { generateVoter } from '../../../../../lib/importExportTools'
import Edit from './Edit'
import cuid from 'cuid'

function VoterRegistryEdit ({ params: { address: organizationAddress, registryId: registryIdParam } }) {
  const { setAddress } = useOrganization()
  const { isNewRegistry, registryContent, setRegistryContent, loading, loadingHint, hasPendingChanges, handleTitleChange, handleSave, handleExport, handleTableDataChange } = useVoterRegistry({ organizationAddress, registryIdParam })
  const { t } = useTranslation()
  useEffect(() => setAddress(organizationAddress), [organizationAddress, setAddress])

  useBeforeunload((event) => {
    if (hasPendingChanges) {
      event.preventDefault()
    }
  })

  const breadcrumbs = [
    { title: t('voterRegistries.manage'), icon: <ContactsOutlined />, link: `/organizations/${organizationAddress}/voterregistries` },
    { title: isNewRegistry ? t('voterRegistries.createRegistry') : (registryContent?.title || t('voterRegistries.editRegistry')) }
  ]

  useEffect(() => {
    if (!registryIdParam && !hasPendingChanges) {
      const title = t('voterRegistries.untitled')
      const voters = [generateVoter()]
      setRegistryContent({ ...registryContent, id: `VR_${cuid()}`, voters, title })
    }
  }, [registryIdParam, registryContent, setRegistryContent, t, hasPendingChanges])

  return (
    <Spin
      spinning={loading}
      size='large'
      tip={loadingHint}
      delay={150}
    >
      <Row gutter={[64, 16]}>
        <Col span={24}>
          <Breadcrumb breadcrumbs={breadcrumbs} />
        </Col>
        <Col span={24}>
          <Headline>{registryContent?.title}</Headline>
          <Description>{t('voterRegistries.editDescription')}</Description>
        </Col>
        <Col xs={24} align='right'>
          <Button
            size='large'
            type='primary'
            disabled={!hasPendingChanges}
            onClick={handleSave}
          >
            {t('voterRegistries.saveRegistry')}
          </Button>
        </Col>
        <Col span={24}>
          <Edit
            voters={registryContent?.voters}
            onChangeVoters={handleTableDataChange}
            handleExportCsv={handleExport}
            title={registryContent?.title}
            onChangeTitle={handleTitleChange}
          />
        </Col>
      </Row>
    </Spin>
  )
}
VoterRegistryEdit.propTypes = {
  params: PropTypes.shape({
    address: PropTypes.string.isRequired,
    registryId: PropTypes.string
  }).isRequired
}

export default VoterRegistryEdit

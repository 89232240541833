import { useState } from 'react'
import { Radio, Tooltip } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import VerticalSpace from '../../../common/VerticalSpace'
import RoundedModal from '../../../common/RoundedModal'
import { Subheadline, Description, Text } from '../../../common/Typography'
import Generate from './Generate'

const DownloadModal = ({ onConfirm, onClose, disableEmail, errors, defaultOption = 'download', generateVotesSelection, ...restProps }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(defaultOption)
  const [generationComplete, setGenerationComplete] = useState(false)
  const onChange = (e) => {
    setValue(e.target.value)
  }

  const handleClose = () => {
    setValue(defaultOption)
    setGenerationComplete()
    onClose()
  }

  const handleConfirm = () => {
    onConfirm(value === 'send')
  }

  const handleCompletion = () => {
    setGenerationComplete(t('election.votes.generated') + (value === 'send' ? t('election.votes.andSent') : ''))
    setValue(defaultOption)
  }

  if (generateVotesSelection) {
    return (
      <RoundedModal
        {...restProps}
        footer={null}
        onCancel={handleClose}
        maskClosable={false}
      >
        <Generate
          shouldSendMail={value === 'send'}
          selectedVoters={generateVotesSelection}
          onComplete={handleCompletion}
          generationComplete={generationComplete}
          onClose={handleClose}
        />
      </RoundedModal>
    )
  }
  return (
    <RoundedModal
      {...restProps}
      closable={false}
      maskClosable={false}
      onCancel={handleClose}
      onConfirm={handleConfirm}
      okText={t('confirm')}
    >
      <Subheadline>{t('election.votes.create')}</Subheadline>
      <Description margin={24}>
        {t('election.votes.askMail')}
      </Description>
      <VerticalSpace size={24}>
        <Radio.Group onChange={onChange} value={value} size='large'>
          <VerticalSpace size={16}>
            <Radio value='send' disabled={disableEmail}>
              <Tooltip autoAdjustOverflow placement='right' title={disableEmail ? t('election.votes.emailRequired') : ''}>
                <Text align='center' type={disableEmail ? 'secondary' : null}>
                  {t('election.votes.sendMail')}
                </Text>
              </Tooltip>
            </Radio>
            <Radio value='download'>
              <Text align='center'>
                {t('election.votes.downloadOnly')}
              </Text>
            </Radio>
          </VerticalSpace>
        </Radio.Group>

        <Text type='warning'><WarningFilled /> {t('election.votes.warning')}</Text>

      </VerticalSpace>
    </RoundedModal>
  )
}

export default DownloadModal

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Upload } from 'antd'
import TransformImage from '@shellophobia/transform-image-js'
import ImagePreview from './ImagePreview'
import ImgCrop from 'antd-img-crop'

const CustomUpload = styled(Upload)`
  .ant-upload-select-picture-card {
    width: 80px;
    height: 80px;
    background-color: transparent;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`
const resizeImage = ({ maxHeight, maxWidth }) => async (file) => {
  const transformImageBase64 = new TransformImage({
    maxHeight: parseInt(maxHeight, 10),
    maxWidth: parseInt(maxWidth, 10),
    outputType: 'base64'
  })
  const resizedBase64Image = await transformImageBase64.resizeImage(file)
  return resizedBase64Image.output
}

const SelectImage = ({
  onSelect: handleSelect,
  onError: handleError,
  maxWidth,
  maxHeight,
  imageSelectText,
  imageTransformer,
  maxCount = 1,
  defaultImage,
  ...restProps
}) => {
  const [base64Image, setBase64Image] = useState(defaultImage)

  if (!imageTransformer) {
    imageTransformer = resizeImage({ maxHeight, maxWidth })
  }

  const handleChange = async file => {
    if (!file) {
      return
    }
    try {
      if (!file.type.includes('image')) {
        throw new Error('Selected file is not an image, please select file of mimetype image/*')
      }
      const base64Image = await imageTransformer(file)
      setBase64Image(base64Image)
    } catch (error) {
      console.error(error)
      handleError(error)
    }
  }

  useEffect(() => {
    if (!handleSelect) {
      return
    }
    handleSelect(base64Image)
  }, [handleSelect, base64Image])

  const cropProps = {
    minZoom: 0.5,
    shape: 'square',
    grid: true,
    cropperProps: {
      restrictPosition: false
    },
    onModalOk: handleChange
  }
  return (
    <ImgCrop {...cropProps}>
      <CustomUpload
        accept='image/*'
        customRequest={() => false}
        listType='picture-card'
        showUploadList={false}
        maxCount={maxCount}
        {...restProps}
      >
        <ImagePreview {...{ base64Image, imageSelectText }} />
      </CustomUpload>
    </ImgCrop>

  )
}

export default SelectImage

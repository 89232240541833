import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

const formatDate = (date = new Date()) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  return date.toLocaleString('de-DE', options) + ' ' + date.toLocaleTimeString('de-DE')
}

const LastChange = ({ date }) => {
  const { t } = useTranslation()
  if (!date) {
    return null
  }
  return (
    <Text type='secondary'>{`${t('election.lastChange')} ${formatDate(date)}`}</Text>
  )
}

export default LastChange

import styled from 'styled-components'
import { Row, Col } from 'antd'
import ReadOnlyCard from './ReadOnlyCard'
import VerticalSpace from './VerticalSpace'
import { TitleText, Text } from './Typography'
import { ReactComponent as Empty } from '../../assets/empty.svg'

const IconContainer = styled(Col)`
  width: 90px;
`

const EmptyCard = ({ title, description }) => {
  return (
    <ReadOnlyCard>
      <Row align='middle' wrap={false} gutter={16}>
        <Col flex={1}>
          <VerticalSpace>
            <TitleText>{title}</TitleText>
            <Text>{description}</Text>
          </VerticalSpace>
        </Col>
        <IconContainer align='right'><Empty /></IconContainer>
      </Row>
    </ReadOnlyCard>
  )
}

export default EmptyCard

import { Row, Col, Typography } from 'antd'
import { LinkOutlined as IconDownload } from '@ant-design/icons'

const { Link } = Typography

const ActiveAttachments = ({ files = [] }) => {
  const fileItems = files.map((file, index) => (
    <Row key={file.name + index} align='middle'>
      <Col span={2}>
        {file.link && <a target='_blank' rel='noreferrer' href={file.link}><IconDownload /></a>}
      </Col>
      <Col span={18}>
        <Link target='_blank' rel='noreferrer' href={file.link}>{file.name}</Link>
      </Col>
    </Row>
  ))

  return (
    <Row gutter={[16, 8]}>
      {files && !!files.length && (
        <Col span={24}>
          {fileItems}
        </Col>
      )}
    </Row>
  )
}

export default ActiveAttachments

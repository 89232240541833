import { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { List, Row, Col, Card, Divider, Checkbox, Typography, Modal } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import Question from './Question'
import Answer from './Answer'
import Settings from './Settings'
import Attachments from '../../../../common/Attachments/Default'
import Button from '../../../../common/Button'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useStorage } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
import reorder from '../../../../../lib/reorder'

const Text = styled(Typography.Paragraph)`
  margin-bottom: ${(props) => props.margin || 8}px !important;
`

const CardContainer = styled(Card)`
  border-radius: ${({ theme }) => theme.borderRadius};
  border-color: ${({ theme }) => theme.borderColor};
`

const FullHeightDivider = styled(Divider)`
  height: 100%;
`

const AnswerList = styled(List)`
  .ant-list-item {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`
const EditCard = ({
  title,
  description,
  files,
  onChange,
  onRemove,
  answers = [''],
  allowAbstention,
  onToggleAbstention,
  minVotes = 1,
  maxVotes = 1,
  resultDisplayType,
  loading
}) => {
  const { t } = useTranslation()
  const { uploadFile, getPublicUri, loading: uploadLoading } = useStorage()

  const refs = useMemo(() =>
    answers.map(() => ({
      current: null
    })
    ), [answers])

  const [activeIndex, setActiveIndex] = useState()

  useEffect(() => {
    if (activeIndex && refs && refs[activeIndex] && refs[activeIndex].current) {
      refs[activeIndex].current.focus()
    }
  }, [answers, activeIndex, refs])

  const handleAnswerChange = (index) => (title) => {
    const newAnswers = [...answers]
    newAnswers[index] = { ...newAnswers[index], title }
    onChange('answers', newAnswers)
  }

  const handleImageChange = (index) => (image) => {
    const newAnswers = [...answers]
    newAnswers[index] = { ...newAnswers[index], uri: image }
    onChange('answers', newAnswers)
  }

  const handleFileChange = (files) => {
    onChange('files', files)
  }

  const addAnswer = () => {
    const newAnswers = [...answers, '']
    onChange('answers', newAnswers)
    setActiveIndex(newAnswers.length - 1)
  }

  const deleteAnswer = (index) => {
    const newAnswers = [...answers]
    newAnswers.splice(index, 1)
    onChange('answers', newAnswers)
  }

  const handleFocus = (index, newAnswer) => () => {
    if (newAnswer) {
      addAnswer()
    }
    setActiveIndex(index)
  }

  const handleRemovePoll = () => {
    Modal.confirm({
      title: t('election.poll.remove'),
      content: t('election.poll.removeConfirm'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: onRemove
    })
  }

  const listItems = [...answers.filter(text => !!text), '']
  const onDragEnd = (result) => {
    if (!result.destination || !result.source || !answers[result.source.index]) {
      return
    }
    const noChange = result.destination.index === result.source.index
    const outOfBounds = result.destination.index >= listItems.length - 1
    if (noChange || outOfBounds) {
      return
    }
    // swap images
    handleImageChange(result.destination.index)(answers[result.source.index].uri)
    handleImageChange(result.source.index)(answers[result.destination.index].uri)
    const newAnswerOrder = reorder(
      answers,
      result.source.index,
      result.destination.index
    )
    onChange('answers', newAnswerOrder)
  }

  const toggleAbstention = () => {
    onChange('allowAbstention', !allowAbstention)
  }

  return (
    <CardContainer>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Question {...{ title, description, onChange }} />
        </Col>
        <Col xs={24} md={11}>
          <Text>{t('attachments')}</Text>
          <Attachments {...{ loading, files, onUpdateFiles: handleFileChange, uploadLoading, uploadFile, getPublicUri }} />
        </Col>
        <Col xs={0} md={2} align='middle'>
          <FullHeightDivider type='vertical' />
        </Col>
        <Col xs={24} md={11}>
          <Text margin={16}>{t('election.poll.abstention')}</Text>
          <Checkbox checked={allowAbstention} onChange={toggleAbstention}>{t('election.poll.allowAbstention')}</Checkbox>
        </Col>
        <Col span={24}>
          <Text>{t('election.answers')}</Text>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable-1' index={0}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <AnswerList
                    itemLayout='horizontal'
                    dataSource={listItems}
                    rowKey={(item) => item.index + item.title}
                    renderItem={(answer, index) => {
                      const isLastItem = index === listItems.length - 1
                      return (
                        <Draggable draggableId={index + (answer.title || 'placeholder')} index={index} key={index} isDragDisabled={isLastItem}>
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Answer
                                editing
                                draggable={!isLastItem}
                                {...answer}
                                ref={refs[index]}
                                singleChoice={maxVotes === 1}
                                onChangeText={handleAnswerChange(index)}
                                onChangeImage={handleImageChange(index)}
                                onDelete={(answers.length > 1 || !!answers[0]) && index < answers.length ? () => deleteAnswer(index) : null}
                                onPressEnter={handleFocus(index + 1, index === listItems.length - 2)}
                                onFocus={handleFocus(index)}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    }}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {allowAbstention && (
            <Answer
              editing
              disabled
              title={t('election.poll.abstain')}
              onDelete={onToggleAbstention}
            />
          )}
        </Col>
        <Col span={24}>
          <Settings
            {...{ allowAbstention, minVotes, maxVotes, resultDisplayType }}
            answerCount={answers.length}
            onChange={onChange}
          />
        </Col>
        {!!onRemove && (
          <Col span={4}>
            <Button danger icon={<DeleteOutlined />} onClick={handleRemovePoll}>{t('election.poll.remove')}</Button>
          </Col>
        )}
      </Row>
    </CardContainer>
  )
}

EditCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ title: PropTypes.string })])),
  minVotes: PropTypes.number,
  maxVotes: PropTypes.number,
  onChangeTitle: PropTypes.func,
  onChangeDescription: PropTypes.func,
  onChangeVoteCount: PropTypes.func,
  updateAnswers: PropTypes.func
}
EditCard.defaultProps = {
  minVotes: 1,
  maxVotes: 1
}

export default EditCard

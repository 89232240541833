import { Input, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button from './Button'

// blatantly stolen from https://ant.design/components/table/#components-table-demo-custom-filter-panel

const FilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, handleSearch, dataIndex }) => { // eslint-disable-line react/prop-types
  const updateSearchValue = (e) => setSelectedKeys(e?.target?.value ? [e.target.value] : [])
  const { t } = useTranslation()
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={t('searchBy', { property: dataIndex })}
        value={selectedKeys[0]}
        onChange={updateSearchValue}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          onClick={() => {
            updateSearchValue()
            handleSearch([''], confirm, dataIndex)
          }}
          size='small'
          type='link'
          style={{ width: 90 }}
        >
          {t('reset')}
        </Button>
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90 }}
        >
          {t('search')}
        </Button>

      </Space>
    </div>
  )
}

export default function getColumnSearchProps ({ dataIndex, handleSearch }) {
  return {
    filterDropdown: props => <FilterDropdown dataIndex={dataIndex} handleSearch={handleSearch} {...props} />,
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
  }
}

import { Row, Col } from 'antd'

import ReorderCard from './Card'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import reorder from '../../../../../lib/reorder'

const Reorder = ({ polls, onChangePolls }) => {
  const handlePollChange = (index) => (key, value) => {
    const newPolls = [...polls]
    const isNewPoll = !newPolls[index]
    newPolls[index] = { created: isNewPoll, ...newPolls[index], [key]: value, changed: true }
    onChangePolls(newPolls)
  }
  const handleRemovePoll = (index) => () => {
    const filteredPolls = polls.filter((item, pollIndex) => pollIndex !== index)
    onChangePolls(filteredPolls)
  }

  const onDragEnd = (result) => {
    if (!result.destination || !result.source) {
      return
    }
    const noChange = result.destination.index === result.source.index
    if (noChange) {
      return
    }
    const newPollOrder = reorder(
      polls,
      result.source.index,
      result.destination.index
    )
    onChangePolls(newPollOrder)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable-1' index={0}>
        {(provided, snapshot) => (
          <Row
            gutter={[0, 24]}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {polls.map((poll, index) => (
              <Draggable draggableId={poll.id} index={index} key={poll.id}>
                {(provided, snapshot) => (
                  <Col
                    span={24} key={poll.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ReorderCard {...poll} onChange={handlePollChange(index)} onRemove={handleRemovePoll(index)} {...snapshot} />
                  </Col>
                )}
              </Draggable>
            ))}
          </Row>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default Reorder

import { address } from './address';
import { blake2b256 } from './blake2b';
import { RLP } from './rlp';
import { secp256k1 } from './secp256k1';
/** Transaction class defines VeChainThor's multi-clause transaction */
export class Transaction {
    /**
     * construct a transaction object with given body
     * @param body body of tx
     */
    constructor(body) {
        this.body = Object.assign({}, body);
    }
    /** decode from Buffer to transaction
     * @param raw encoded buffer
     * @param unsigned to indicator if the encoded buffer contains signature
     */
    static decode(raw, unsigned) {
        let body;
        let signature;
        if (unsigned) {
            body = unsignedTxRLP.decode(raw);
        }
        else {
            const decoded = txRLP.decode(raw);
            signature = decoded.signature;
            delete decoded.signature;
            body = decoded;
        }
        const reserved = body.reserved;
        if (reserved.length > 0) {
            if (reserved[reserved.length - 1].length === 0) {
                throw new Error('invalid reserved fields: not trimmed');
            }
            const features = featuresKind.buffer(reserved[0], 'reserved.features').decode();
            body.reserved = {
                features
            };
            if (reserved.length > 1) {
                body.reserved.unused = reserved.slice(1);
            }
        }
        else {
            delete body.reserved;
        }
        const tx = new Transaction(body);
        if (signature) {
            tx.signature = signature;
        }
        return tx;
    }
    /**
     * returns transaction ID
     * null returned if something wrong (e.g. invalid signature)
     */
    get id() {
        if (!this._signatureValid) {
            return null;
        }
        try {
            const signingHash = this.signingHash();
            const pubKey = secp256k1.recover(signingHash, this.signature.slice(0, 65));
            const origin = address.fromPublicKey(pubKey);
            return '0x' + blake2b256(signingHash, Buffer.from(origin.slice(2), 'hex')).toString('hex');
        }
        catch (_a) {
            return null;
        }
    }
    /**
     * compute signing hashes.
     * It returns tx hash for origin or delegator depends on param `delegateFor`.
     * @param delegateFor address of intended tx origin. If set, the returned hash is for delegator to sign.
     */
    signingHash(delegateFor) {
        const reserved = this._encodeReserved();
        const buf = unsignedTxRLP.encode(Object.assign(Object.assign({}, this.body), { reserved }));
        const hash = blake2b256(buf);
        if (delegateFor) {
            if (!/^0x[0-9a-f]{40}$/i.test(delegateFor)) {
                throw new Error('delegateFor expected address');
            }
            return blake2b256(hash, Buffer.from(delegateFor.slice(2), 'hex'));
        }
        return hash;
    }
    /** returns tx origin. null returned if no signature or not incorrectly signed */
    get origin() {
        if (!this._signatureValid) {
            return null;
        }
        try {
            const signingHash = this.signingHash();
            const pubKey = secp256k1.recover(signingHash, this.signature.slice(0, 65));
            return address.fromPublicKey(pubKey);
        }
        catch (_a) {
            return null;
        }
    }
    /** returns tx delegator. null returned if no signature or not incorrectly signed */
    get delegator() {
        if (!this.delegated) {
            return null;
        }
        if (!this._signatureValid) {
            return null;
        }
        const origin = this.origin;
        if (!origin) {
            return null;
        }
        try {
            const signingHash = this.signingHash(origin);
            const pubKey = secp256k1.recover(signingHash, this.signature.slice(65));
            return address.fromPublicKey(pubKey);
        }
        catch (_a) {
            return null;
        }
    }
    /** returns whether delegated. see https://github.com/vechain/VIPs/blob/master/vips/VIP-191.md */
    get delegated() {
        // tslint:disable-next-line:no-bitwise
        return (((this.body.reserved || {}).features || 0) & Transaction.DELEGATED_MASK) === Transaction.DELEGATED_MASK;
    }
    /** returns intrinsic gas it takes */
    get intrinsicGas() {
        return Transaction.intrinsicGas(this.body.clauses);
    }
    /** encode into Buffer */
    encode() {
        const reserved = this._encodeReserved();
        if (this.signature) {
            return txRLP.encode(Object.assign(Object.assign({}, this.body), { reserved, signature: this.signature }));
        }
        return unsignedTxRLP.encode(Object.assign(Object.assign({}, this.body), { reserved }));
    }
    _encodeReserved() {
        const reserved = this.body.reserved || {};
        const list = [featuresKind.data(reserved.features || 0, 'reserved.features').encode(),
            ...(reserved.unused || [])];
        // trim
        while (list.length > 0) {
            if (list[list.length - 1].length === 0) {
                list.pop();
            }
            else {
                break;
            }
        }
        return list;
    }
    get _signatureValid() {
        const expectedSigLen = this.delegated ? 65 * 2 : 65;
        return this.signature ? this.signature.length === expectedSigLen : false;
    }
}
Transaction.DELEGATED_MASK = 1;
(function (Transaction) {
    /**
     * calculates intrinsic gas that a tx costs with the given clauses.
     * @param clauses
     */
    function intrinsicGas(clauses) {
        const txGas = 5000;
        const clauseGas = 16000;
        const clauseGasContractCreation = 48000;
        if (clauses.length === 0) {
            return txGas + clauseGas;
        }
        return clauses.reduce((sum, c) => {
            if (c.to) {
                sum += clauseGas;
            }
            else {
                sum += clauseGasContractCreation;
            }
            sum += dataGas(c.data);
            return sum;
        }, txGas);
    }
    Transaction.intrinsicGas = intrinsicGas;
    function dataGas(data) {
        const zgas = 4;
        const nzgas = 68;
        let sum = 0;
        for (let i = 2; i < data.length; i += 2) {
            if (data.substr(i, 2) === '00') {
                sum += zgas;
            }
            else {
                sum += nzgas;
            }
        }
        return sum;
    }
})(Transaction || (Transaction = {}));
const unsignedTxRLP = new RLP({
    name: 'tx',
    kind: [
        { name: 'chainTag', kind: new RLP.NumericKind(1) },
        { name: 'blockRef', kind: new RLP.CompactFixedBlobKind(8) },
        { name: 'expiration', kind: new RLP.NumericKind(4) },
        {
            name: 'clauses', kind: {
                item: [
                    { name: 'to', kind: new RLP.NullableFixedBlobKind(20) },
                    { name: 'value', kind: new RLP.NumericKind(32) },
                    { name: 'data', kind: new RLP.BlobKind() },
                ],
            },
        },
        { name: 'gasPriceCoef', kind: new RLP.NumericKind(1) },
        { name: 'gas', kind: new RLP.NumericKind(8) },
        { name: 'dependsOn', kind: new RLP.NullableFixedBlobKind(32) },
        { name: 'nonce', kind: new RLP.NumericKind(8) },
        { name: 'reserved', kind: { item: new RLP.BufferKind() } },
    ],
});
const txRLP = new RLP({
    name: 'tx',
    kind: [...unsignedTxRLP.profile.kind, { name: 'signature', kind: new RLP.BufferKind() }],
});
const featuresKind = new RLP.NumericKind(4);

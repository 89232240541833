import { Space, Typography } from 'antd'
import styled from 'styled-components'
import Logo from '../../../../common/Logo'
import ReadOnlyCard from '../../../../common/ReadOnlyCard'

const Text = styled(Typography.Text)`
  font-size: 20px;
  line-height: 28px;
`
const OrganizationPreview = ({ title, logo, description }) => {
  return (
    <ReadOnlyCard>
      <Space size={16}>
        <Logo title={title} src={logo} />
        <Text>{title}</Text>
      </Space>
    </ReadOnlyCard>
  )
}

export default OrganizationPreview

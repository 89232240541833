import { useState, useEffect } from 'react'
import { Link } from 'wouter'
import { List, Typography, Row, Col } from 'antd'
import Button from '../../../common/Button'
import Loading from '../../../common/Loading'
import { useElection } from '@hpm/voteos-hooks'
import ErrorMessage from '../../../common/ErrorMessage'
import IconButton from '../../../common/IconButton'
import Item from './Item'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography

export default function ElectionOrganizers ({ params }) {
  const { t } = useTranslation()
  const { election, loading, setRoleFor, setAddress } = useElection()
  const [newOrganizers, setNewOrganizers] = useState()
  const [error, setError] = useState()
  const [updating, setUpdating] = useState(false)

  useEffect(() => setAddress(params.address), [params.address, setAddress])
  const handleSave = async () => {
    setError()
    setUpdating(true)
    try {
      const updatedOrganizers = newOrganizers.filter(({ address, role }) => {
        const currentOrganizer = election.organizers.find(organizer => organizer.address === address)
        return currentOrganizer.role !== role
      })

      if (updatedOrganizers.length > 0) {
        await Promise.all(updatedOrganizers.map(({ address, role }) => setRoleFor(address, role)))
      }
    } catch (err) {
      setError(err.message)
    }
    setUpdating(false)
  }

  const handleSelect = (address) => (role) => {
    setNewOrganizers([...newOrganizers].map(organizer => {
      if (organizer.address === address) {
        return {
          ...organizer,
          role
        }
      }

      return organizer
    }))
  }

  useEffect(() => {
    if (!election) { return setNewOrganizers([]) }
    setNewOrganizers(election.organizers.sort((a, b) => (!['0', 'inactive'].includes(b.role) || b.role === a.role) ? 0 : -1))
  }, [election])

  if (loading && !election) {
    return <Loading />
  }

  return (
    <Row gutter={0}>
      <Col span={24}>
        <Link to={`/elections/${params.address}`}><IconButton /></Link>
      </Col>
      <Col xs={24} lg={{ span: 14, offset: 4 }}>

        <Title>{election?.title}</Title>
        <Paragraph>{t('election.edit.organizers')}</Paragraph>
        <Paragraph>{t('election.edit.roles')}</Paragraph>

        <List
          itemLayout='vertical'
          dataSource={newOrganizers}
          size='large'
          renderItem={(organizer, index) => <Item key={organizer.address} {...organizer} number={index + 1} onSelect={handleSelect(organizer.address)} />}
          loading={loading || updating}
        />

      </Col>
      <Col xs={24} lg={{ span: 14, offset: 4 }}>
        <ErrorMessage error={error} />
      </Col>

      <Col xs={24} lg={{ span: 14, offset: 4 }}>
        <Button type='primary' block onClick={handleSave} loading={updating}>speichern</Button>
      </Col>
    </Row>
  )
}

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { List, Row, Col, Avatar } from 'antd'
import Icon, { InfoCircleOutlined } from '@ant-design/icons'
import { ReactComponent as WeightSvg } from '../../../../../../assets/Weight.svg'
import Progress from '../../../../../common/Progress'
import DonutChart from './DonutChart'
import { Text, SecondaryText } from '../../../../../common/Typography'
import calculatePercentage from '../../../../../../lib/calculatePercentage'

const ResultRow = styled(Row)`
  width: 100%;
`

const ListItem = styled(List.Item)`
  border-bottom: none !important;
`

const AnswerText = styled(Text)`
  color: ${({ theme }) => theme.titleText};
`

export const WeightIcon = (props) => <Icon component={WeightSvg} {...props} />
export const InfoCircle = styled(InfoCircleOutlined)`
  padding-top: 24px;
  margin-left: 1px;
  margin-right: 7px
`

const Result = ({
  metadata,
  answers,
  voterCount,
  minVotes,
  maxVotes,
  allowAbstention,
  abstentionCount,
  abstentionWeight,
  currentVoteWeight,
  totalVoteWeight
}) => {
  const { t } = useTranslation()

  const infoText = (
    <SecondaryText>
      <InfoCircle />{minVotes === maxVotes ? t('election.poll.equalVoteCountDescription', { count: minVotes }) : t('election.poll.voteCountDescription', { min: minVotes, max: maxVotes })}
    </SecondaryText>
  )

  if (metadata?.resultDisplayType === 'pie') {
    const data = answers.map(answer => {
      return {
        type: answer.title,
        value: answer.votes,
        image: answer.image
      }
    })
    if (allowAbstention) {
      data.push({
        type: t('election.poll.abstain'),
        value: abstentionWeight
      })
    }
    return (
      <Row>
        <Col span={24}>
          <DonutChart data={data} />
        </Col>
        {infoText}
      </Row>
    )
  }

  return (
    <Row>
      <Col span={24}>
        <List
          itemLayout='vertical'
          dataSource={answers}
          renderItem={answer => {
            return (
              <ListItem>
                <ResultRow align='middle'>
                  {answer.image && (
                    <Col flex='50px'>
                      <Avatar src={answer.image} shape='circle' size={40} />
                    </Col>
                  )}
                  <Col flex={1}>
                    <AnswerText>{answer.title} <Text type='secondary'>({answer.votes}{currentVoteWeight > voterCount && ' '}{currentVoteWeight > voterCount ? <WeightIcon /> : null})</Text></AnswerText>
                  </Col>
                  <Col span={24}>
                    <Progress
                      status='normal'
                      percent={calculatePercentage(answer.votes, (maxVotes > 1 ? totalVoteWeight : currentVoteWeight))}
                    />
                  </Col>
                </ResultRow>
              </ListItem>
            )
          }}
        />
      </Col>
      {allowAbstention && (
        <Col span={24}>
          <AnswerText>{t('election.poll.abstention')} <Text type='secondary'>({abstentionWeight} {abstentionWeight > abstentionCount ? <WeightIcon /> : null})</Text></AnswerText>
          <Progress
            status='normal'
            percent={calculatePercentage(abstentionWeight, currentVoteWeight)}
          />
        </Col>
      )}
      <Col span={24}>
        {infoText}
      </Col>
    </Row>
  )
}

export default Result

import { Redirect } from 'wouter'
import { useAuth } from '../../hooks/useAuth'

export default function SignIn () {
  const { signedIn, login } = useAuth()

  if (signedIn) {
    return <Redirect to='/' />
  }

  login()
  return <></>
}

import styled from 'styled-components'
import { List, Row, Col, Avatar, Checkbox, Radio } from 'antd'
import { Text } from '../../../../common/Typography'

const Image = styled(Avatar)`
  margin-right: 8px;
`

const ListRow = styled(Row)`
  flex: 1;
`

const SquareCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 2px !important;
  }
`

const ListItem = styled(List.Item)`
  border-bottom: none !important;
`

const AnswerText = styled(Text)`
  color: ${({ theme }) => theme.titleText};
`

const Answer = ({
  title = '',
  image,
  allowMultiple,
  disabled
}) => {
  return (
    <ListItem>
      <ListRow gutter={[8, 0]} align='middle' wrap={false}>
        <Col>
          {allowMultiple ? <SquareCheckbox checked={false} /> : <Radio checked={false} />}
        </Col>
        <Col flex={1}>
          {image && <Image src={image} size={40} />}
          <AnswerText>
            {title}
          </AnswerText>
        </Col>
      </ListRow>
    </ListItem>
  )
}

export default Answer

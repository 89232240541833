import { useState, useEffect } from 'react'
import { useLocation } from 'wouter'
import { message } from 'antd'
import { generateVoter } from '../../../../../lib/importExportTools'
import cuid from 'cuid'

import { useElection, useOrganization } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
import useVoterRegistry from '../../../../../hooks/useVoterRegistry'

const useSaveState = () => {
  const { t } = useTranslation()
  const [, setLocation] = useLocation()
  const { organization, addElection, refresh: refreshOrganization } = useOrganization()
  const {
    election,
    setIsModerated,
    setMetadata,
    setVoterRegistryUri,
    loading,
    polls: electionPolls,
    refreshPolls,
    addPoll,
    updatePoll,
    removePoll,
    setPollOrders,
    setAddress
  } = useElection()
  const [creatingNewRegistry, setCreatingNewRegistry] = useState(false)
  const {
    registryContent,
    loading: loadingVoters,
    handleSave: handleSaveRegistry,
    handleExport,
    handleTableDataChange,
    handleTitleChange,
    setRegistryContent,
    hasPendingChanges: hasRegistryChanged
  } = useVoterRegistry({ registryIdParam: !creatingNewRegistry && election?.uriVoterRegistry })
  const [polls, setPolls] = useState()
  const [changedData, setChangedData] = useState()
  const [changedFiles, setChangedFiles] = useState()
  const [changedModerated, setChangedModerated] = useState(false)
  const [changedPollOrder, setChangedPollOrder] = useState()
  const [changedEmailTemplate, setChangedEmailTemplate] = useState()
  const [emailTemplate, setEmailTemplate] = useState({ subject: '', topText: '', bottomText: '' })
  const [saving, setSaving] = useState()
  const [creating, setCreating] = useState()

  useEffect(() => {
    if (election && election.uriVoterRegistry) {
      setCreatingNewRegistry(false)
    }
    if (election && election.metadata && election.metadata.emailTemplate) {
      setEmailTemplate(election.metadata.emailTemplate)
    }
    if (election?.isModerated) {
      setChangedModerated(election.isModerated)
    }
  }, [election])

  useEffect(() => {
    if (electionPolls) {
      const formattedPolls = electionPolls?.map((poll) => ({
        ...poll,
        description: poll?.metadata.description,
        files: poll?.metadata?.attachments,
        resultDisplayType: poll?.metadata?.resultDisplayType
      }))
      setPolls(formattedPolls)
    }
  }, [electionPolls])

  const handleUpdateFiles = (attachments) => {
    if (attachments) {
      setChangedFiles(attachments)
    }
  }

  const handleSaveMetadata = async () => {
    await setMetadata({ ...election.metadata, ...changedData, emailTemplate })
    setChangedData(null)
    setChangedEmailTemplate(false)
  }

  const handleChangeModerated = async () => {
    setChangedModerated(!changedModerated)
  }

  const handleSaveChangedFiles = async () => {
    await setMetadata({ ...election.metadata, attachments: changedFiles })
    setChangedFiles(null)
  }

  const handleChangeTemplate = (template) => {
    setEmailTemplate(template)
    setChangedEmailTemplate(true)
  }

  const handleSaveAll = async () => {
    await setMetadata({ ...election.metadata, ...changedData, attachments: changedFiles, emailTemplate })
    setChangedData(null)
    setChangedFiles(null)
    setChangedEmailTemplate(false)
  }

  const handleUpdatePollOrder = async () => {
    const pollIds = polls.map(poll => poll.id).filter(id => !!id)
    await setPollOrders(pollIds)
    setChangedPollOrder(false)
  }

  const handleChangePolls = (changedPolls) => {
    setPolls(changedPolls)
  }

  const handleChangePollOrder = async (polls) => {
    setPolls(polls)
    setChangedPollOrder(true)
  }

  const handleCreateRegistry = () => {
    setCreatingNewRegistry(true)
    const title = t('election.registry.for', { title: election.title })
    const voters = [generateVoter()]
    setRegistryContent({ ...registryContent, id: `VR_${cuid()}`, voters, title })
  }

  const handleSave = async () => {
    setSaving(true)
    const key = 'saving'
    message.loading({ content: t('election.saving.start'), key, duration: 0 })
    try {
      if (hasRegistryChanged) {
        if (creatingNewRegistry) {
          message.loading({ content: t('election.saving.newRegistry'), key, duration: 0 })
          await setVoterRegistryUri(registryContent.id)
        }
        message.loading({ content: t('election.saving.registry'), key, duration: 0 })
        await handleSaveRegistry({ avoidRedirect: true })
      }

      if (changedPollOrder) {
        message.loading({ content: t('election.saving.pollOrder'), key, duration: 0 })
        await handleUpdatePollOrder(polls)
      }
      if (changedModerated !== election.isModerated) {
        message.loading({ content: t('election.saving.moderated'), key, duration: 0 })
        await setIsModerated(changedModerated)
      }
      let reloadPolls = false
      const { createdPolls, changedPolls } = polls.reduce((acc, poll) => {
        const answerImages = poll.answers?.reduce((imageMap, currentAnswer, index) => {
          imageMap[currentAnswer.id || index] = currentAnswer.uri
          return imageMap
        }, poll.answerImages || {})
        const maxVotes = !poll.maxVotes || isNaN(poll.maxVotes) ? 1 : poll.maxVotes
        const minVotes = !poll.minVotes || isNaN(poll.minVotes) ? 1 : poll.minVotes

        const pollData = {
          id: poll.id,
          title: poll.title || t('untitled'),
          allowAbstention: poll.allowAbstention,
          minVotes,
          maxVotes,
          answers: poll.answers.filter(answer => !!answer) || [],
          metadata: {
            attachments: poll.files,
            description: poll.description,
            answerImages,
            resultDisplayType: poll.resultDisplayType
          }
        }
        if (poll.created) {
          acc.createdPolls.push(pollData)
        } else if (poll.changed) {
          acc.changedPolls.push(pollData)
        }
        return acc
      }, { createdPolls: [], changedPolls: [] })
      let counter = 1
      if (changedPolls.length) {
        for (const poll of changedPolls) {
          message.loading({ content: t('election.saving.updatePolls', { count: changedPolls.length }) + ` (${counter}/${changedPolls.length})`, key, duration: 0 })
          await updatePoll(poll)
          counter += 1
        }
        counter = 1
        reloadPolls = true
      }
      if (createdPolls.length) {
        for (const poll of createdPolls) {
          message.loading({ content: t('election.saving.createPolls', { count: createdPolls.length }) + ` (${counter}/${createdPolls.length})`, key, duration: 0 })
          await addPoll(poll)
          counter += 1
        }
        reloadPolls = true
        counter = 1
      }

      const deletedPolls = electionPolls.filter(poll => !polls.some(({ id }) => id === poll.id))
      if (deletedPolls.length) {
        message.loading({ content: t('election.saving.deletePolls', { count: deletedPolls.length }) + ` (${counter}/${deletedPolls.length})`, key, duration: 0 })
        for (const poll of deletedPolls) {
          await removePoll(poll.id)
          counter += 1
        }
        reloadPolls = true
        counter = 1
      }
      if (reloadPolls) {
        await refreshPolls()
      }

      if ((changedData || changedEmailTemplate) && changedFiles) {
        message.loading({ content: t('election.saving.filesAndData'), key, duration: 0 })
        await handleSaveAll()
      } else if (changedData || changedEmailTemplate) {
        message.loading({ content: t('election.saving.metadata'), key, duration: 0 })
        await handleSaveMetadata()
      } else if (changedFiles) {
        message.loading({ content: t('election.saving.files'), key, duration: 0 })
        await handleSaveChangedFiles()
      }
      message.success({ content: t('election.saving.saveComplete'), key })
      if (changedData) {
        refreshOrganization()
      }
    } catch (err) {
      message.error({ content: t('election.error.save') + ': ' + err.message, key })
    }
    setSaving(false)
  }
  const { hasModifiedPolls, hasCreatedPolls } = (polls && polls.length && polls.reduce((acc, poll) => {
    if (poll.created) {
      acc.hasCreatedPolls = true
    }
    if (poll.changed) {
      acc.hasModifiedPolls = true
    }
    return acc
  }, { hasModifiedPolls: false, hasCreatedPolls: false })) || {}

  const handleCreate = async () => {
    setCreating(true)
    try {
      const emailTemplate = {
        subject: t('election.mail.subjectTemplate', { title: changedData?.title ? ': ' + changedData.title : '' }),
        topText: t('election.mail.topText', { organization: organization.title || 'VOTEOS' }),
        bottomText: t('election.mail.bottomText')
      }
      const electionAddress = await addElection({
        title: t('election.untitled'),
        ...changedData,
        attachments: changedFiles,
        emailTemplate,
        organizationAddress: organization.address
      })
      setAddress(electionAddress)
      setLocation(`/elections/${electionAddress}`)
    } catch (error) {
      message.error(error.message)
    }
    setCreating(false)
  }

  const hasPendingChanges = changedFiles || changedPollOrder || changedData || hasCreatedPolls || hasModifiedPolls || hasRegistryChanged || changedEmailTemplate || (changedModerated !== !!election?.isModerated)

  return {
    loading: loading || loadingVoters,
    saving,
    handleSave,
    hasPendingChanges,
    hasCreatedPolls,

    changedData,
    setChangedData,
    changedModerated,
    handleChangeModerated,
    changedFiles,
    handleUpdateFiles,
    polls,
    handleChangePolls,
    changedPollOrder,
    handleChangePollOrder,

    voters: registryContent?.voters,
    handleChangeVoters: handleTableDataChange,
    registryTitle: registryContent?.title,
    handleChangeRegistryTitle: handleTitleChange,
    handleExportCsv: handleExport,
    handleCreateRegistry,
    creatingNewRegistry,

    emailTemplate,
    handleChangeTemplate,

    handleCreate,
    creating
  }
}

export default useSaveState

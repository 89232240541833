import { List, Select } from 'antd'
import styled from 'styled-components'
import Roles from '../../../config/membershipRoles'
import { useTranslation } from 'react-i18next'
import { UserOutlined } from '@ant-design/icons'

const { Option } = Select

const ListItem = styled(List.Item)`
  align-items: center !important;
`
export default function Item ({ address, email, role, number, onSelect: handleSelect }) {
  const { t } = useTranslation()

  const roleSelect = (
    <Select
      dropdownMatchSelectWidth
      onSelect={handleSelect}
      defaultValue={role}
      style={{ minWidth: '160px' }}
      disabled
    >
      <Option key='inactive' value='0'>{t('roles.inactive')}</Option>
      {Roles.map((role) => <Option key={role.id} value={role.id}>{t('roles.' + role.title)}</Option>)}
    </Select>
  )
  return (
    <ListItem extra={roleSelect}>
      <UserOutlined />
      &nbsp;{number}&nbsp;&nbsp;
      {email || address}
    </ListItem>
  )
}

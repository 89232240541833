import { Card, List } from 'antd'
import { Link } from 'wouter'
import Button from '../../../../common/Button'
import Logo from '../../../../common/Logo'

const { Item } = List

function StatusOptions ({ status, onAccept: handleAccept, onReject: handleReject }) {
  if (status === 'accepted') {
    return <></>
  }

  if (status === 'invited') {
    return (
      <>
        <Button block key='accept' onClick={handleAccept} type='primary'>Accept</Button>
        <Button block key='reject' onClick={handleReject} type='danger'>Reject</Button>
      </>
    )
  }
}

export default function OrganizationItem ({ logo, title, description, roleName, status, uriView, version, onAccept, onReject }) {
  const actions = StatusOptions({ status, onAccept, onReject })

  return (
    <Item extra={actions}>
      <Link href={uriView}>
        <Card
          hoverable
          title={title}
        >
          <Card.Meta
            avatar={<Logo title={title} src={logo} />}
            title={roleName}
            description={`v${version}`}
            content={description}
          />
        </Card>
      </Link>
    </Item>
  )
}

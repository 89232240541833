import Papa from 'papaparse'
import * as CSV from 'csv-string'
import cuid from 'cuid'

export const countDelimiterInstances = (string, delimiter) => {
  const chars = [...string]
  let occurrences = 0
  let escaped = false
  const otherDelimiters = [',', ';'].filter((possibleDelimiter) => possibleDelimiter !== delimiter)
  chars.forEach((c, i) => {
    if (['"', "'"].includes(c)) {
      escaped = !escaped
    }
    if (!escaped && otherDelimiters.includes(c)) {
      throw new Error(c)
    }
    if (c === delimiter && !escaped) {
      occurrences += 1
    }
  })
  return occurrences
}

export const validateCSV = (content) => {
  const contentWithUniformNewlines = content.replace(/\r?\n/g, '\r\n')
  const splitLines = contentWithUniformNewlines.split('\r\n')
  if (splitLines.length === 1) {
    return content
  }
  let contentWithoutTitle = splitLines
  const delimiter = CSV.detect(splitLines[splitLines.length > 1 ? 1 : 0])
  let delimitersInFirstLine = 0
  try {
    delimitersInFirstLine = countDelimiterInstances(splitLines[0], delimiter)
    const delimitersInSecondLine = countDelimiterInstances(splitLines[1], delimiter)

    // remove header sometimes populated by Numbers.app
    if (delimitersInFirstLine !== delimitersInSecondLine) {
      contentWithoutTitle = splitLines.slice(1)
    }
  } catch (err) {
    throw new Error(` ${delimitersInFirstLine ? 2 : 1}: "${splitLines[delimitersInFirstLine ? 1 : 0]}"`)
  }

  // check inconsistent delimiters if there are multiple rows
  if (splitLines.length > 2) {
    let delimiterCount = delimitersInFirstLine
    contentWithoutTitle.forEach((row, index) => {
      try {
        const rowDelimiters = countDelimiterInstances(row, delimiter)
        if (index === 0) {
          delimiterCount = rowDelimiters
        }

        if (rowDelimiters !== delimiterCount) {
          throw new Error(row.trim())
        }
      } catch (err) {
        throw new Error(` ${index}: "${err.message}"`)
      }
    })
  }
  return contentWithoutTitle.join('\n')
}

export const parseCSV = content => {
  const trimmedContent = content.trim()

  const contentWithoutTitle = validateCSV(trimmedContent)

  const { data } = Papa.parse(contentWithoutTitle, {
    columns: true,
    skipEmptyLines: true,
    header: true,
    escapeChar: '"',
    transformHeader: text => text.trim(),
    transform: text => text.trim()
  })

  return data
}

export const formatCSV = (fields, entries) => {
  return Papa.unparse(entries, {
    columns: fields
  })
}

const hasContentChanged = (objA, objB) => {
  const keysToCompare = Object.keys(objA)
  for (let i = 0; i < keysToCompare.length; i++) {
    const key = keysToCompare[i]
    const isDefaultWeight = key === 'weight' && ((!objB[key] && objA[key] === 1) || (!objA[key] && objB[key] === 1))
    if (String(objA[key]) !== String(objB[key]) && !isDefaultWeight) {
      return true
    }
  }

  return false
}

export const generateUniqueId = (existingIds = []) => {
  let newId = 1
  const checkId = () => {
    return existingIds.some((id) => Number(id) === newId)
  }
  let idExists = checkId()
  while (idExists) {
    newId += 1
    idExists = checkId()
  }
  return newId
}

export const determineChanges = (currentEntries, importEntries) => {
  const changes = { new: [], removed: [], changed: [] }

  const currentIds = currentEntries.map(e => String(e.externalId)).filter(e => !!e)
  changes.new = importEntries.filter(importEntry => !importEntry.externalId || !currentIds.includes(importEntry.externalId))

  changes.changed = importEntries.reduce((entries, currentEntry) => {
    if (!currentIds.includes(currentEntry.externalId)) {
      return entries
    }
    const originalEntry = currentEntries.find(entry => entry && entry.externalId ? String(entry.externalId) === currentEntry.externalId : false)
    const contentHasChanged = originalEntry && hasContentChanged(currentEntry, originalEntry)
    const duplicateIndex = entries.findIndex((entry) => entry.externalId === currentEntry.externalId)
    if (!contentHasChanged && duplicateIndex === -1) {
      return entries
    }
    if (duplicateIndex >= 0) {
      if (contentHasChanged) {
        entries[duplicateIndex] = currentEntry
      } else {
        entries.splice(duplicateIndex, 1)
      }
      return entries
    }
    return [...entries, currentEntry]
  }, [])

  const importIds = importEntries.filter(e => !!e).map(e => String(e.externalId))
  changes.removed = currentEntries.filter(currentEntry => currentEntry && currentEntry.externalId && !importIds.includes(String(currentEntry.externalId)))
  return changes
}

const deepCopy = input => JSON.parse(JSON.stringify(input))

export const applyChanges = (currentEntries, changes, mergeMethods) => {
  let updatedEntries = deepCopy(currentEntries)
  if (mergeMethods.includes('removed')) {
    const externalIdsToRemove = changes.removed.map(e => e.externalId).filter(e => !!e)
    updatedEntries = updatedEntries.filter(e => !externalIdsToRemove.includes(e.externalId))
  }
  if (mergeMethods.includes('changed')) {
    changes.changed.forEach(change => {
      const currentEntry = updatedEntries.find(entry => String(entry.externalId) === change.externalId)
      Object.keys(change).forEach(k => {
        currentEntry[k] = change[k]
        if (k === 'weight' && !change[k]) {
          currentEntry[k] = 1
        }
      })
    })
  }
  if (mergeMethods.includes('new')) {
    const currentIds = updatedEntries.map(e => e.externalId).filter(e => !!e)
    const newEntries = changes.new.reduce((entries, change) => {
      let externalId = change.externalId
      if (!externalId) {
        const newId = generateUniqueId(currentIds)
        externalId = newId
      }
      if (currentIds.includes(externalId)) {
        return entries
      }
      currentIds.push(externalId)
      return [...entries, { ...change, externalId, id: cuid(), weight: change.weight || 1 }]
    }, [])
    updatedEntries = updatedEntries.concat(newEntries)
  }
  return updatedEntries
}

export const generateVoter = (data, voters = []) => {
  const externalId = generateUniqueId(voters.map((voter) => voter.externalId || voter))
  return {
    externalId,
    id: cuid(),
    name: '',
    email: '',
    note: '',
    weight: 1,
    ...data
  }
}

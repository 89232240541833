import ActiveAttachments from './Active'
import DefaultAttachments from './Default'

const Attachments = ({
  state,
  files,
  onUpdateFiles,
  loading,
  uploadLoading,
  uploadFile,
  getPublicUri
}) => {
  if (state === 'started' || state === 'ended' || state === 'cancelled') {
    return (
      <ActiveAttachments
        files={files}
        getPublicUri={getPublicUri}
      />
    )
  } else {
    return (
      <DefaultAttachments
        files={files}
        onUpdateFiles={onUpdateFiles}
        loading={loading}
        uploadLoading={uploadLoading}
        uploadFile={uploadFile}
        getPublicUri={getPublicUri}
      />
    )
  }
}

export default Attachments

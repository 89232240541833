import { Table } from 'antd'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  .ant-table-column-title {
    font-weight: normal !important;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: ${({ theme }) => theme.voteos3};
  }
`

export default StyledTable

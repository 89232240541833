import { useState } from 'react'
import { EyeOutlined, EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Preview from './Preview'
import Editor from './Editor'
import Tabs from '../View/Tabs'

const Template = ({ subject, topText, bottomText, onChange, onSave, loading, generate }) => {
  const { t } = useTranslation()
  const [showPreview, setShowPreview] = useState(generate)
  const [link, setLink] = useState('https://vote.voteos.com/0xa12bcc34d56ccadda7bbbfb8ad9c22dbb25b2d3e')
  const togglePreview = () => setShowPreview(!showPreview)

  const handleChange = (values) => {
    if (values.link) {
      setLink(values.link)
      delete values.link
    }
    onChange(values)
  }

  const tabOptions = [
    { id: 'preview', icon: <EyeOutlined />, children: <Preview {...{ subject, topText, bottomText, link }} /> },
    { id: 'edit', icon: <EditOutlined />, children: <Editor {...{ subject, topText, bottomText, link, onChange: handleChange }} /> }
  ].map((option) => ({ ...option, name: t('election.mail.' + option.id) }))

  return (
    <Tabs options={tabOptions} onChange={togglePreview} />
  )
}

export default Template

import { useState, useEffect, useCallback } from 'react'
import { Modal, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useOrganization, useOrganizations } from '@hpm/voteos-hooks'
import EditForm from './Form'
import Message from '../../../common/Message'
import Spacer from '../../../common/Spacer'
import { Headline, Description } from '../../../common/Typography'
import Loading from '../../../common/Loading'
import NotFound from '../../../common/NotFound'
import Button from '../../../common/Button'
import Version from '../../../common/Version'
import { useAuth } from '../../../../hooks/useAuth'

const Error = ({ error }) => {
  if (!error) {
    return <></>
  }
  return <Message type='error' text={error} />
}

export default function OrganizationEdit ({ params }) {
  const { t } = useTranslation()
  const { logout, rememberCurrentOrganizationAddress } = useAuth()
  const { loading, organization, updateMetadata, setAddress, leaveOrganization } = useOrganization()
  const { refreshOrganizations } = useOrganizations()
  const [error, setError] = useState()

  useEffect(() => setAddress(params.address), [params.address, setAddress])

  const handleLeave = async () => {
    try {
      await leaveOrganization()
      await refreshOrganizations()
      rememberCurrentOrganizationAddress(null)
      await logout()
      window.location.replace('/')
    } catch (err) {
      message.error(err.message)
      console.error(err)
    }
  }

  const handleSubmit = useCallback(async (metadata) => {
    setError('')
    try {
      await updateMetadata(metadata)
      await refreshOrganizations()
      message.success(t('organization.changesSaved'))
    } catch (err) {
      console.error(err)
      setError('organization.errorSave')
    }
  }, [updateMetadata, t, refreshOrganizations])

  const confirmLeave = () => {
    Modal.confirm({
      title: t('organization.leave'),
      content: t('organization.leaveConfirm'),
      okText: t('continue'),
      okType: 'danger',
      cancelText: t('cancel'),
      onOk: handleLeave
    })
  }

  if (loading && !organization) {
    return <Loading />
  }

  if (!organization) {
    return <NotFound text={error} title='Organization' />
  }

  const leaveDisabled = organization &&
    organization.roleName === 'owner' &&
    organization.members.filter((member) => member.stateName === 'active').length > 1 &&
    organization.members.filter((member) => member.roleName === 'owner').length < 2

  return (
    <>
      <Headline>{t('settings')}</Headline>
      <Description margin={32}>{t('organization.editDescription')}</Description>
      {organization.version ? <Version version={organization.version} /> : null}
      <Error error={t(error)} />
      <EditForm
        {...organization}
        onSubmit={handleSubmit}
        loading={loading}
      />
      <Spacer height={48} />
      <Button
        danger
        title={leaveDisabled ? t('organization.leaveBlocked') : ''}
        icon={<DeleteOutlined />}
        onClick={confirmLeave}
        disabled={leaveDisabled}
      >
        {t('organization.leave')}
      </Button>
    </>
  )
}

import styled from 'styled-components'
import { Layout, message } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import Menu from './Menu'
import { useOrganizations } from '@hpm/voteos-hooks'
import { useAuth } from '../../../hooks/useAuth'
import { useLayout } from '../../../hooks/useLayout'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { useTranslation } from 'react-i18next'

const Sider = styled(Layout.Sider)`
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.lightBackground};
  .ant-layout-sider-zero-width-trigger {
    overflow: hidden;
    top: -3px;
    right: -31px;
    color: ${({ theme }) => theme.primaryColor};
    clip-path: inset(0 -30px -30px 5px);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    background-color: ${({ theme }) => theme.lightBackground};
    border-radius: ${({ theme }) => theme.borderRadius} !important;
    border-top-left-radius: 0px !important;
  }
  @media (min-width: 768px) {
    overflow: auto; // hide menu borders
  }
  @media only screen and (max-width: 767.99px) {
    z-index: 10;
    border: 1px solid #f0f0f0;
  }
  .ant-layout-sider-children {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .ant-layout-sider-children::-webkit-scrollbar {
    display: none;
  }
`

const Navigation = ({ width = 254 }) => {
  const { t } = useTranslation()
  const { currentOrganizationAddress, rememberCurrentOrganizationAddress, logout } = useAuth()
  const { organizations, loading } = useOrganizations()
  const activeOrganization = organizations && organizations.length ? organizations.find(organization => organization.address === currentOrganizationAddress) : null
  const { width: windowWidth } = useWindowDimensions()
  const { isNavbarCollapsed, toggleNavbar } = useLayout()

  const handleLogout = async () => {
    try {
      await logout()
      window.location.replace('/')
    } catch (error) {
      const errorMessage = t('Header.SignOut.Error')
      message.error(errorMessage, 15)
      console.error(error)
    }
  }
  const handleCollapse = () => {
    toggleNavbar(windowWidth >= 768)
  }

  if (activeOrganization && activeOrganization.membershipStateName === 'inactive') {
    rememberCurrentOrganizationAddress()
    handleLogout()
  }

  return (
    <Sider
      width={width}
      breakpoint='md'
      collapsedWidth='0'
      collapsed={isNavbarCollapsed}
      trigger={isNavbarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      onCollapse={handleCollapse}
    >
      <Menu {...{ activeOrganization, organizations, handleLogout, handleCollapse, loading }} />
    </Sider>
  )
}

export default Navigation

import Button from './Button'
import { Link } from 'wouter'
import { ArrowLeftOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

const icons = {
  back: <ArrowLeftOutlined />,
  edit: <EditOutlined />,
  delete: <DeleteOutlined />
}

const IconButton = ({ type = 'back', link, onClick, ...restProps }) => {
  const button = (
    <Button type='text' onClick={onClick} {...restProps}>
      {icons[type]}
    </Button>
  )

  if (!link) {
    return button
  }

  return (
    <Link to={link}>
      {button}
    </Link>
  )
}

export default IconButton

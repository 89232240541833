import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Avatar, Upload, Popover, Space, Spin, message } from 'antd'
import { DeleteOutlined, UploadOutlined, CameraOutlined } from '@ant-design/icons'
import Button from '../../../../../common/Button'
import ImgCrop from 'antd-img-crop'
import { useTranslation } from 'react-i18next'
import { useStorage } from '@hpm/voteos-hooks'

const Image = styled(Avatar)`
  ${({ src }) => !src && 'background-color: #00000000;'}
  border: 1px dashed ${({ theme, src }) => !src && theme.lightGray};
`

const CameraIcon = styled(CameraOutlined)`
  color: ${({ theme }) => theme.lightGray};
`

const MoodImage = ({ image, uri, onChange, onRemove, editing, disabled }) => {
  const { t } = useTranslation()
  const { uploadFile, getPublicUri, loading } = useStorage()
  const [uploadedImage, setUploadedImage] = useState()
  const [showPopover, setShowPopover] = useState(false)
  useEffect(() => {
    if (uri && uri.includes('ipfs://')) {
      setUploadedImage({ link: getPublicUri(uri) })
    }
    if (uploadedImage && !uri) {
      setUploadedImage()
    }
  }, [uri, getPublicUri]) // eslint-disable-line

  if (editing && disabled) {
    return null
  }
  if (disabled) {
    return <Avatar src={image} shape='circle' size={24} />
  }
  const handleUpload = async (file) => {
    if (loading) {
      return
    }
    const uploadResponse = await uploadFile({ file, filename: file.name })
    if (uploadResponse.error) {
      message.error(uploadResponse.error)
    }
    onChange(uploadResponse.uri)
    const link = getPublicUri(uploadResponse.uri, true)
    const newFile = { uri: uploadResponse.uri, name: file.name, link }
    setUploadedImage(newFile)
  }

  const closePopover = () => {
    setShowPopover(false)
  }
  const handleRemove = () => {
    if (image) {
      onRemove()
    }
    setUploadedImage()
    closePopover()
  }
  const uploadProps = {
    accept: 'image/*',
    maxCount: 1,
    showUploadList: false
  }
  const cropProps = {
    minZoom: 0.5,
    shape: 'round',
    grid: true,
    cropperProps: {
      restrictPosition: false
    },
    onModalOk: handleUpload
  }
  const hasExistingImage = image || uploadedImage

  const popoverContent = (
    <Space>
      <ImgCrop {...cropProps}>
        <Upload {...uploadProps}>
          <Button onClick={closePopover} loading={loading} icon={<UploadOutlined />}>{t(hasExistingImage ? 'replace' : 'upload')}</Button>
        </Upload>
      </ImgCrop>
      {hasExistingImage && <Button danger icon={<DeleteOutlined />} onClick={handleRemove}>{t('remove')}</Button>}
    </Space>
  )
  return (
    <ImgCrop {...cropProps}>
      <Upload
        {...uploadProps}
        openFileDialogOnClick={false}
      >
        <Popover
          open={showPopover}
          onOpenChange={setShowPopover}
          trigger='click'
          placement='right'
          content={popoverContent}
        >
          <Image size={40} shape='circle' src={(uploadedImage && uploadedImage.link) || image} icon={loading ? <Spin /> : <CameraIcon />} />
        </Popover>
      </Upload>
    </ImgCrop>
  )
}

export default MoodImage

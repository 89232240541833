import { useState, useEffect, useCallback } from 'react'
import { message } from 'antd'
import RoundedModal from '../../../../common/RoundedModal'
import Input from '../../../../common/Input'
import VerticalSpace from '../../../../common/VerticalSpace'
import { Subheadline, Text } from '../../../../common/Typography'
import RolePicker from '../RolePicker'
import roles from '../../../../config/membershipRoles'
import { useTranslation } from 'react-i18next'

export default function OrganizationMemberEdit ({ member, setMembershipRole, onCancel }) {
  const { t } = useTranslation()
  const [role, setRole] = useState(member?.role)
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState()
  useEffect(() => {
    setRole(member?.role)
    setEmail(member?.email)
  }, [member])

  const handleUpdate = useCallback(async () => {
    try {
      setLoading(true)
      await setMembershipRole(member.id, role)
      onCancel()
    } catch (err) {
      message.error(err.message)
    }
    setLoading(false)
  }, [member, setMembershipRole, onCancel, role])

  const handleCancel = () => {
    onCancel()
  }

  return (
    <RoundedModal open={!!member} onCancel={handleCancel} onOk={handleUpdate} loading={loading}>
      <VerticalSpace size='middle'>
        <Subheadline>{t('organization.member.update')}</Subheadline>
        <Text type='secondary'>{member?.id}</Text>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} disabled />
        <RolePicker roles={roles} onSelect={setRole} value={role} />
      </VerticalSpace>
    </RoundedModal>
  )
}

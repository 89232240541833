import {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
  createElement
} from 'react'
import useWindowDimensions from './useWindowDimensions'
import { Grid } from 'antd'
const { useBreakpoint } = Grid
const LayoutContext = createContext()

export const useLayout = () => {
  const context = useContext(LayoutContext)

  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider')
  }

  return context
}

export const LayoutProvider = ({ children }) => {
  const screens = useBreakpoint()
  const { width: windowWidth } = useWindowDimensions()
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)

  useEffect(() => {
    if (windowWidth < 574 && !isNavbarCollapsed && !isSidebarCollapsed) {
      setIsSidebarCollapsed(true)
    }
  }, [windowWidth, isNavbarCollapsed, isSidebarCollapsed, setIsSidebarCollapsed])

  const toggleNavbar = useCallback((overrideValue) => setIsNavbarCollapsed((current) => {
    if (overrideValue) return false
    if (current && !isSidebarCollapsed && screens.xs) {
      setIsSidebarCollapsed(true)
    }
    return !current
  }), [isSidebarCollapsed, screens])

  const toggleSidebar = useCallback(() => setIsSidebarCollapsed((current) => {
    if (current && !isNavbarCollapsed && screens.xs) {
      setIsNavbarCollapsed(true)
    }
    return !current
  }), [isNavbarCollapsed, screens])

  const value = {
    isNavbarCollapsed,
    isSidebarCollapsed,
    toggleNavbar,
    toggleSidebar,
    screens,
    windowWidth
  }
  return createElement(LayoutContext.Provider, { value }, children)
}

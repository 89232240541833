import styled, { useTheme } from 'styled-components'
import { Route, useRoute } from 'wouter'
import { Layout } from 'antd'
import Footer from './Footer'
import Navigation from './Navigation'
import Header from './Navigation/Header'
const { Content } = Layout

const LayoutContent = styled(Layout)`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.lightBackground};
  border-left: 1px solid ${({ theme }) => theme.transparentGray};
  @media (min-width: 1262px) {
    ${({ centercontent }) => centercontent ? 'align-items: center;' : ''}
  }
  @media (min-width: 769px) {
    margin-left: ${({ theme, navbar }) => navbar ? theme.navbarWidth : 0}px;
  }
  // avoid squishing content with open sidebar on mobile https://github.com/ant-design/ant-design/issues/23111#issuecomment-702718425
  @media only screen and (max-width: 768px) {
    position: relative;
    overflow: hidden;
    min-width: 100vw;
  }
`
const StyledContent = styled(Content)`
  padding: 48px;
  width: 100%;
  ${({ maxcontent, theme }) => maxcontent ? `max-width: ${theme.contentWidth};` : ''}
  ${({ showfooter }) => !showfooter && 'min-height: 100vh;'}
  @media (min-width: 1440px) {
    padding: 48px 0px 48px 0px;
  }
`

const Page = ({ signedIn, children, ...restProps }) => {
  const theme = useTheme()
  const [matchOrganizationRoute] = useRoute('/organizations/:address?/:function?/:subpage?/:id?')
  const [matchLoginRoute] = useRoute('/')
  const [matchCreateRoute] = useRoute('/organizations/create/default')
  const [matchInviteRoute] = useRoute('/organizations/:address?/join/:key?')

  return (
    <Layout {...restProps} hasSider style={{ backgroundColor: theme.lightBackground }}>
      {(matchLoginRoute || matchInviteRoute || matchCreateRoute) && <Header />}
      {signedIn && !matchInviteRoute && !matchCreateRoute && <Navigation width={theme.navbarWidth} />}
      <LayoutContent navbar={signedIn && !matchInviteRoute && !matchCreateRoute ? 1 : 0} centercontent={(matchOrganizationRoute || matchCreateRoute) ? 1 : 0}>
        <StyledContent maxcontent={matchOrganizationRoute || matchCreateRoute ? 1 : 0} showfooter={matchInviteRoute ? 1 : 0}>
          {children}
        </StyledContent>
        <Route path='/organizations/:address?/:feature/:id?' component={Footer} />
      </LayoutContent>
    </Layout>
  )
}

export default Page

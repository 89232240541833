import styled from 'styled-components'
import { Link } from 'wouter'
import { Card, Dropdown, Modal, Tooltip, Row } from 'antd'
import { ExportOutlined, ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons'
import Button from '../../../../common/Button'
import ContextMenu from '../../../../common/ContextMenu'
import VerticalSpace from '../../../../common/VerticalSpace'
import { Text, Paragraph } from '../../../../common/Typography'
import Logo from '../../../../common/Logo'
import Tag from './Tag'
import LastChange from './LastChange'
import { useTranslation } from 'react-i18next'

const Title = styled(Text)`
  font-weight: 400;
  font-size: 20px !important;
  line-height: 28px !important;
`

const CardByState = styled(Card)`
  min-height: 210px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border-color: ${({ theme }) => theme.borderColor};
  opacity: ${({ state }) => ['cancelled', 'closed'].includes(state) ? 0.5 : null};
  .ant-card-head {
    border-bottom-width: 0px;
  }
  .ant-card-head-title {
    padding-top: 16px !important;
    padding-bottom: 24px !important;
  }
  .ant-card-body { padding-top: 0px; padding-bottom: 0px; }
  .ant-card-meta-detail {
    display: flex;
    align-items: center;
    .ant-typography {
      margin-bottom: 0px
    }
  }
`

// ant-design warning-color from https://ant.design/docs/react/customize-theme#Ant-Design-Less-variables
const WarningIcon = styled(ExclamationCircleOutlined)`
  color: #faad14;
  fontSize: 18px;
`

const ElectionError = ({ message }) => {
  return (
    <Tooltip title={message}>
      <WarningIcon />
    </Tooltip>
  )
}

const ElectionCard = ({ address, title, description, logo, state, lastChange, onRemove, loading, error }) => {
  const { t } = useTranslation()

  const showConfirm = () => {
    Modal.confirm({
      title: t('election.remove'),
      content: t('election.removeConfirm'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: () => onRemove(address)
    })
  }
  const contextMenu = [
    { title: t('remove'), onClick: showConfirm, type: 'danger', icon: <ExportOutlined /> }
  ]

  return (
    <CardByState
      state={state}
      hoverable
      title={
        <Link to={`/elections/${address}`}>
          {error ? <ElectionError message={t(error)} /> : <Title level={4}>{title}</Title>}
        </Link>
      }
      extra={(
        <Dropdown trigger='click' overlay={<ContextMenu items={contextMenu} />}>
          <Button type='text' icon={<EllipsisOutlined />} />
        </Dropdown>
      )}
      loading={loading}
    >
      <VerticalSpace size={24}>
        <Link to={`/elections/${address}`}>
          <CardByState.Meta
            avatar={<Logo title={title} src={logo} />}
            description={<Paragraph ellipsis={{ rows: 3 }} style={{ whiteSpace: 'break-spaces' }}>{description || t('election.missingDescription')}</Paragraph>}
          />
        </Link>
        <Row justify='space-between' wrap={false}>
          <Tag error={!state ? 'Missing state' : error} state={state} />
          {lastChange && <LastChange date={lastChange} />}
        </Row>
      </VerticalSpace>
    </CardByState>
  )
}

export default ElectionCard

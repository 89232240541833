import { useState, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Typography, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import Mustache from 'mustache'
import template from './template-inlined.html'

const Subject = styled(Typography)`
  padding: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  border-radius: ${({ theme }) => theme.borderRadius};
`

const Preview = ({ subject, topText, bottomText, link = 'https://voteos.com' }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [html, setHtml] = useState()
  useEffect(() => {
    const templateVariables = {
      theme,
      topText: topText.replaceAll('\n', '<br/>'),
      bottomText: bottomText.replaceAll('\n', '<br/>'),
      link,
      preHeader: t('election.mail.preHeader'),
      buttonText: t('election.mail.buttonText')
    }
    const output = Mustache.render(template, templateVariables)
      .replace('<html>', '').replace('</html>', '')
      .replace('<head>', '').replace('</head>', '')
    setHtml(output)
  }, [t, subject, topText, bottomText, link, theme])

  return (
    <Form
      layout='vertical'
      size='large'
    >
      <Form.Item label={t('election.mail.subject')}>
        <Subject>{subject}</Subject>
      </Form.Item>
      <Form.Item label={t('election.mail.text')}>
        {html ? parse(html) : ''}
      </Form.Item>
    </Form>
  )
}

export default Preview

import { Card, Row, Col, Typography } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Input, { TextArea } from '../../../../common/Input'
import { SecondaryText } from '../../../../common/Typography'

const { Title, Paragraph } = Typography
const Text = styled(Typography.Paragraph)`
  margin-bottom: 8px !important;
`

const CardMeta = styled(Card.Meta)`
  .ant-card-meta-title {
    white-space: pre-line;
  }
`

const Question = ({ title, description, onChange }) => {
  const { t } = useTranslation()

  const handleChangeTitle = (event) => {
    onChange('title', event.target.value)
  }

  const handleChangeDescription = (event) => {
    onChange('description', event.target.value)
  }

  if (!onChange) {
    return <CardMeta title={<Title level={3}>{title}</Title>} description={<Paragraph style={{ whiteSpace: 'break-spaces' }}>{description}</Paragraph>} />
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Text>{t('question')}</Text>
        <Input
          size='large'
          bold='true'
          value={title}
          onChange={handleChangeTitle}
          placeholder={t('election.poll.question')}
        />
      </Col>

      <Col span={24}>
        <Text>{t('description')}</Text>
        <TextArea
          showCount
          value={description}
          onChange={handleChangeDescription}
          placeholder={t('election.poll.description')}
          autoSize={{ minRows: 3, maxRows: 10 }}
        />
        <SecondaryText>{t('election.poll.descriptionHelp')}</SecondaryText>
      </Col>
    </Row>
  )
}

export default Question

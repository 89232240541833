import { useState, useEffect } from 'react'
import { useLocation } from 'wouter'
import styled from 'styled-components'
import { Row, Col, InputNumber, Typography, Space, message } from 'antd'
import { BankOutlined, FileDoneOutlined } from '@ant-design/icons'
import Button from '../../../../common/Button'
import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../../../common/Breadcrumb'
import VerticalSpace from '../../../../common/VerticalSpace'
import { Headline, Subheadline, Text, Description } from '../../../../common/Typography'
import PriceCard from './PriceCard'
import CouponModal from '../CouponModal'
import SubscriptionInfo from './SubscriptionInfo'
import { useWallet } from '../../../../../hooks/useWallet'
import { useOrganization } from '@hpm/voteos-hooks'

const PAYMENT_ENDPOINT = process.env.REACT_APP_IPFS_PAYMENT_ENDPOINT

const NumberInput = styled(InputNumber)`
  width: 100%;
`

const TotalCost = styled(Typography)`
  font-size: 20px;
  text-align: right;
`
const TotalInfo = styled(Typography)`
  text-align: right;
`
const AmountInfo = styled(Text)`
  color: ${({ theme }) => theme.primaryLight};
`
const formatCurrency = (value, currency = '€') => {
  return value.toFixed(2) + ' ' + currency
}

const BuyVotes = ({ params }) => {
  const { t, i18n } = useTranslation()
  const [location, setLocation] = useLocation()
  const { organization } = useOrganization()
  const { session } = useWallet()
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState()
  const [electionAddress, setElectionAddress] = useState()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const breadcrumbs = [
    { title: t('account'), link: `/organizations/${params.address}/account`, icon: <BankOutlined /> },
    { title: t('organization.account.buyVotes'), icon: <FileDoneOutlined /> }
  ]

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('amount')) {
      setAmount(queryParams.get('amount'))
    }
    if (queryParams.has('electionAddress')) {
      setElectionAddress(queryParams.get('electionAddress'))
    }
  }, [location])

  const handlePayment = async () => {
    setLoading(true)
    try {
      const returnPath = `/organizations/${params.address}/payment`
      const paymentData = {
        amount,
        redirectUrl: window.location.origin + returnPath,
        electionAddress,
        organizationAddress: params.address,
        language: i18n.language
      }
      const res = await window.fetch(PAYMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'X-Contract-Address': params.address,
          Authorization: `Bearer ${session}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(paymentData)
      })
      const { error, sessionUrl, paymentIntent } = await res.json()
      if (paymentIntent) {
        window.localStorage.setItem('paymentId', paymentIntent)
      }
      if (error) {
        message.error(error.message)
      } else {
        window.location.href = sessionUrl
      }
    } catch (err) {

    }
    setLoading(false)
  }

  const prices = [
    {
      lowerLimit: 1,
      upperLimit: 100,
      price: 2,
      shouldDisplayLowerLimit: false,
      size: 'small'
    },
    {
      lowerLimit: 101,
      upperLimit: 500,
      price: 1.5,
      discount: '-25%',
      shouldDisplayLowerLimit: false,
      size: 'medium'
    },
    {
      lowerLimit: 501,
      upperLimit: Number.MAX_SAFE_INTEGER,
      price: 1,
      discount: '-50%',
      shouldDisplayLowerLimit: true,
      size: 'large'
    }
  ]

  const activeIndex = amount ? prices.findIndex(({ upperLimit }) => amount <= upperLimit) : -2
  const getCost = () => {
    if (activeIndex >= 0) {
      return prices[activeIndex].price
    }
    return prices[0].price
  }
  const total = amount * getCost()

  const goBack = () => {
    setLocation(`/organizations/${params.address}/account`)
  }
  console.log('organization', organization)
  return (
    <Row gutter={[40, 32]}>
      <Col span={24}>
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </Col>
      <Col xs={{ span: 24, order: 0 }}>
        <Headline>{t('organization.account.prices')}</Headline>
        <Description>{t('votes.description')}</Description>
      </Col>
      <Col xs={{ span: 24, order: 3 }} lg={{ span: 12, order: 1 }} xxl={{ span: 11, order: 1 }}>
        <Subheadline>{t('votes.priceInformation')}</Subheadline>
        <Row gutter={[16, 16]}>
          {prices.map(({ upperLimit, lowerLimit, shouldDisplayLowerLimit, ...restPrice }, index) => {
            return (
              <Col key={upperLimit} xs={24}>
                <PriceCard
                  amount={shouldDisplayLowerLimit ? lowerLimit : upperLimit}
                  hasNoUpperLimit={shouldDisplayLowerLimit}
                  onClick={() => setAmount(lowerLimit)}
                  {...restPrice}
                />
              </Col>
            )
          })}
        </Row>
      </Col>
      <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 3 }} xxl={{ span: 11, offset: 1, order: 1 }}>
        <Subheadline>{t('votes.amount')}</Subheadline>
        <VerticalSpace size={16}>
          <Text>{t('votes.amountDescription')}</Text>
          <NumberInput
            min={1}
            value={amount}
            onChange={setAmount}
            placeholder={t('votes.enterAmount')}
            size='large'
          />
          <AmountInfo>({t('votes.amountAt', { cost: formatCurrency(getCost()) })})</AmountInfo>
        </VerticalSpace>
        <TotalCost>{formatCurrency(total || 0)}</TotalCost>
        <TotalInfo type='secondary' align='right'>{t('votes.totalInfo')}</TotalInfo>
      </Col>
      <Col span={24} order={4} xxl={23} align='right'>
        <Row justify='space-between'>
          <SubscriptionInfo organization={organization} />
          <Space >
            <Button type='secondary' size='large' onClick={() => setIsModalOpen(true)}>{t('organization.account.redeemCoupon')}</Button>
            <Button disabled={!amount} loading={loading} type='primary' size='large' onClick={handlePayment}>{t('votes.checkout')}</Button>
          </Space>
        </Row>
      </Col>
      <CouponModal open={isModalOpen} onClose={() => setIsModalOpen(false)} address={params.address} onRefresh={goBack} />
    </Row>
  )
}

export default BuyVotes

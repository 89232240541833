import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Row, Col, Spin, Input } from 'antd'
import { Redirect } from 'wouter'
import { useAuth } from '../../hooks/useAuth'
import Button from '../common/Button'
import { Headline, Description } from '../common/Typography'
import Footer from '../Layout/Footer'

const StyledContent = styled.div`
  height: 100vh;
  margin: -48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const HomeContent = ({ onLogin, invite, loading, setLoginEmail }) => {
  const { t } = useTranslation()

  const handleClick = async () => {
    try {
      await onLogin()
    } catch (error) {
      console.error(error)
    }
  }
  const hasRedirected = !!window.location.hash
  return (
    <Row gutter={[0, 24]} align='center'>
      <Col span={24} />
      <Col span={24} />
      <Col span={24} />
      <Col span={24} />
      <Col span={24} />
      <Col xs={24} align='center'>
        <Headline>{t(invite ? 'organization.invite.invited' : 'home.welcome')}</Headline>
        <Description margin={0}>{t(invite ? 'organization.invite.signIn' : 'home.welcomeMessage')}</Description>
      </Col>
      <Col
        xs={24}
        align='center'
        flex='200px'
      >
        <Input
          placeholder={t('home.email')}
          size="large"
          onChange={(e) => {setLoginEmail(e?.target?.value)}}
          onPressEnter={handleClick}
           style={{ marginBottom: 20, display: 'block' }}
        />
        <Spin spinning={loading && !hasRedirected}>
          <Button block type='primary' onClick={handleClick} loading={hasRedirected} size='large'>
            {t('home.signIn')}
          </Button>
        </Spin>
      </Col>
      <Col span={24} />
      <Col span={24} />
    </Row>
  )
}

const Home = () => {
  const { signedIn, currentOrganizationAddress, login, loading, setLoginEmail } = useAuth()

  if (signedIn && currentOrganizationAddress) {
    return <Redirect to={`/organizations/${currentOrganizationAddress}/`} />
  }

  if (signedIn) {
    return <Redirect to='/organizations' />
  }

  return (
    <StyledContent>
      <HomeContent onLogin={login} loading={loading} setLoginEmail={setLoginEmail} />
      <Footer />
    </StyledContent>
  )
}

export default Home

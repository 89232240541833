import { Steps, Row, Col, Typography } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { useLocation } from 'wouter'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const { Text } = Typography

const Title = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`

const Subtitle = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 44px;
`

const Step = styled(Steps.Step)`
  .ant-steps-item-container > .ant-steps-item-tail {
    display: none !important;
  }
  .ant-steps-item-container > .ant-steps-item-icon {
    margin-top: ${({ status }) => status === 'finish' ? 6 : 8}px !important;
  }
`

const CheckDot = styled(CheckCircleFilled)`
  color: ${({ theme }) => theme.secondaryColor}}
  font-size: 10px;
`

const customDot = (dot, { status, index }) => status === 'finish' ? <CheckDot /> : dot

const Instructions = ({ status, election, polls = [], voters = [] }) => {
  const { t } = useTranslation()
  const [, setLocation] = useLocation()

  const handleChange = (index) => {
    const paths = ['data', 'questions', 'voters', 'votes']
    setLocation(`/elections/${election.address}/${paths[index]}`)
  }
  return (
    <Row gutter={[64, 16]}>
      <Col span={24}>
        <Title>{t('election.instructions.instruction')}</Title>
      </Col>

      <Col span={24}>
        <Subtitle>{t('election.instructions.toPublish')}</Subtitle>
        <Steps progressDot={customDot} size='small' direction='vertical' current={null} onChange={handleChange}>
          <Step title={t('election.instructions.title')} status={election.title && election.title !== t('election.untitled') ? 'finish' : null} />
          <Step title={t('election.instructions.polls')} status={polls.length ? 'finish' : null} />
          <Step title={t('election.instructions.voters')} status={election.uriVoterRegistry ? 'finish' : null} />
        </Steps>
        <Subtitle>{t('election.instructions.toStart')}</Subtitle>
        <Steps progressDot={customDot} size='small' direction='vertical' current={null} onChange={() => handleChange(3)}>
          <Step title={t('election.instructions.ballots')} status={election.voteCount ? 'finish' : null} />
        </Steps>
      </Col>
    </Row>
  )
}

export default Instructions

import { useState } from 'react'
import styled from 'styled-components'
import { Table, Row, Col, Typography } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, TeamOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import getColumnSearchProps from '../../../common/TableFilter'
import LabeledValue from '../../../common/LabeledValue'
import VerticalSpace from '../../../common/VerticalSpace'
import { WeightIcon } from '../Voters/Edit'

const Title = styled(Typography.Text)`
  font-size: 20px;
  line-height: 28px;
`
const ReadOnlyTable = styled(Table)`
  .ant-table-column-title {
    font-weight: normal !important;
  }
  .ant-table-cell {
    background: ${({ theme }) => theme.readOnlyGray};
  }
`
const renderVoteStatus = (hasVote) => {
  const style = {
    fontSize: 20,
    color: hasVote ? 'green' : 'red'
  }
  if (hasVote) return <CheckCircleOutlined style={style} />
  return <CloseCircleOutlined style={style} />
}

const VoterTable = ({ voters, loading, title, weightSum, ...rest }) => {
  const { t } = useTranslation()
  const [, setSearchInfo] = useState({ searchText: '', searchedColumn: '' })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchInfo({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }
  const columns = [
    {
      title: t('election.votes.status'),
      dataIndex: 'hasVote',
      render: renderVoteStatus,
      align: 'center',
      filters: [{ text: t('election.votes.hasVote'), value: 'true' }, { text: t('election.votes.noVote'), value: '' }],
      defaultFilteredValue: ['true'],
      onFilter: (value, record) => !!value === record?.hasVote,
      sorter: ({ hasVote: propA }, { hasVote: propB }) => (propA === propB) ? 0 : propA ? -1 : 1
    },
    {
      title: t('voterRegistries.tableHeaders.externalId'),
      dataIndex: 'externalId',
      width: 140,
      sorter: ({ externalId: propA }, { externalId: propB }) => (propA || '').localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'externalId', handleSearch })
    },
    {
      title: t('name'),
      dataIndex: 'name',
      render: (text, record) => !record.externalId ? t('election.votes.deletedVoter') : text,
      sorter: ({ name: propA }, { name: propB }) => propA.localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'name', handleSearch })
    },
    {
      title: t('email'),
      dataIndex: 'email',
      sorter: ({ email: propA }, { email: propB }) => propA.localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'email', handleSearch })
    },
    {
      title: t('voterRegistries.tableHeaders.note'),
      dataIndex: 'note',
      sorter: ({ name: propA }, { name: propB }) => propA.localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'name', handleSearch })
    },
    {
      title: t('voterRegistries.tableHeaders.weight'),
      dataIndex: 'weight',
      align: 'right',
      fixed: 'right',
      sorter: ({ weight: propA }, { weight: propB }) => propA - propB,
      ...getColumnSearchProps({ dataIndex: 'weight', handleSearch })
    }

  ]

  const voterCount = voters && voters.length && voters.filter((voter) => voter.hasVote).length
  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Title>{title}</Title>
      </Col>
      <Col xs={24} sm={12} md={10} lg={8}>
        <VerticalSpace>
          <LabeledValue icon={<TeamOutlined />} title={t('election.registry.voters')}>{voterCount}</LabeledValue>
          {weightSum && weightSum !== voters?.count && <LabeledValue icon={<WeightIcon />} title={t('election.votes.weight')}>{weightSum}</LabeledValue>}
        </VerticalSpace>
      </Col>
      <Col span={24}>
        <ReadOnlyTable
          loading={loading}
          rowKey='id'
          pagination={false}
          scroll={{ x: 700 }}
          columns={columns}
          dataSource={voters}
        />
      </Col>
    </Row>
  )
}

export default VoterTable

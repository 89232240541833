import { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Row, Col, Typography, Spin } from 'antd'
import ErrorMessage from '../ErrorMessage'
import Button from '../Button'
import { LoadingOutlined, DeleteOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

const { Text, Paragraph } = Typography

const UploadDropzone = styled.div`
  border: 1px dashed ${({ theme }) => theme.secondaryText}};
  border-radius: ${({ theme }) => theme.borderRadius};
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledParagraph = styled(Paragraph)`
  margin: 0 !important;
  text-decoration: underline;
`

const LoadingIcon = styled(LoadingOutlined)`
  margin-left: 8px;
  margin-right: 8px;
`
const Attachments = ({ files = [], onUpdateFiles, uploadFile, getPublicUri, uploadLoading }) => {
  const [error, setError] = useState()
  const [uploadingName, setUploadingName] = useState()
  const { t } = useTranslation()

  const onDrop = useCallback(async acceptedFiles => {
    setError()
    const newFiles = await Promise.all(acceptedFiles.map(async (acceptedFile) => {
      setUploadingName(acceptedFile.name)
      const uploadResponse = await uploadFile({ file: acceptedFile, filename: acceptedFile.name })
      if (uploadResponse.error) {
        setError(uploadResponse.error)
      }
      const newFile = { uri: uploadResponse.uri, name: acceptedFile.name, link: await getPublicUri(uploadResponse.uri, true) }
      return newFile
    }))
    onUpdateFiles([...files, ...newFiles])
  }, [files, uploadFile, getPublicUri, onUpdateFiles])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const removeFile = file => async () => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    onUpdateFiles(newFiles)
  }

  const editName = index => name => {
    const filesCopy = [...files]
    filesCopy[index] = { ...filesCopy[index], name }
    onUpdateFiles(filesCopy)
  }

  const fileItems = files.map((file, index) => (
    <Row key={file.name + index} align='middle' gutter={8} wrap={false}>
      <Col>
        {file.link && <a target='_blank' rel='noreferrer' href={file.link}><LinkOutlined /></a>}
      </Col>
      <Col flex={1}>
        <StyledParagraph editable={{ onChange: editName(index), triggerType: 'text' }}>{file.name}</StyledParagraph>
      </Col>
      <Col align='right'>
        <Button type='link' danger onClick={removeFile(file)}><DeleteOutlined /></Button>
      </Col>
    </Row>
  ))

  return (
    <Row gutter={[16, 8]}>
      {files && !!files.length && (
        <Col span={24}>
          {fileItems}
        </Col>
      )}
      {uploadLoading && <Spin indicator={<LoadingIcon />} size='small' />}
      {uploadLoading && !!uploadingName && <StyledParagraph>{uploadingName}</StyledParagraph>}

      {!!error && (
        <Col span={24}>
          <ErrorMessage error={error} />
        </Col>
      )}

      <Col span={24}>
        <UploadDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <Text type='secondary'><UploadOutlined /> {t('election.attachments.choose')}</Text>
        </UploadDropzone>

      </Col>
    </Row>
  )
}

export default Attachments

import { Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../../../../../lib/formatDate'
import styled from 'styled-components'
import Button from '../../../../common/Button'
import EmptyCard from '../../../../common/EmptyCard'
import getColumnSearchProps from '../../../../common/TableFilter'

const StyledTable = styled(Table)`
  .ant-table-column-title {
    font-weight: normal !important;
  }
  .ant-table-cell {
    cursor: pointer;
  }
`

const RegistryTable = ({ voterRegistries, loading, onRemove, onRowClick: handleRowClick = () => {} }) => {
  const { t } = useTranslation()

  if (!loading && (!voterRegistries || !voterRegistries.length)) {
    return <EmptyCard title={t('organization.voterRegistry.empty')} description={t('organization.voterRegistry.emptyDescription')} />
  }
  const columns = [
    {
      title: t('voterRegistries.title'),
      dataIndex: 'title',
      width: '25%',
      render: (text) => text
    },
    {
      title: t('voterRegistries.entryCount'),
      dataIndex: 'entryCount',
      sorter: ({ entryCount: propA }, { entryCount: propB }) => propA - propB,
      width: 100,
      align: 'right',
      render: (text) => text || '0'
    },
    {
      title: t('voterRegistries.createdAt'),
      dataIndex: 'createdAt',
      width: 196,
      sorter: ({ createdAt: propA }, { createdAt: propB }) => propA - propB,
      render: (text) => formatDateTime(text) || '-'
    },
    {
      title: t('voterRegistries.updatedAt'),
      dataIndex: 'updatedAt',
      width: 196,
      render: (text) => formatDateTime(text) || '-',
      sorter: ({ updatedAt: propA }, { updatedAt: propB }) => propA - propB
    },
    {
      fixed: 'right',
      width: 60,
      key: 'extra',
      align: 'center',
      render: (text, record) => <Button danger type='text' icon={<DeleteOutlined />} onClick={onRemove(record)} />
    }
  ].map((column) => {
    if (column.key === 'extra') {
      return column
    }
    return {
      ...getColumnSearchProps({ dataIndex: column, handleSearch: (keys, confirm) => confirm() }),
      sorter: ({ [column.dataIndex]: propA }, { [column.dataIndex]: propB }) => propA.localeCompare(propB),
      ...column
    }
  })

  return (
    <StyledTable
      columns={columns}
      pagination={false}
      dataSource={voterRegistries}
      loading={loading}
      rowKey={record => JSON.stringify(record)}
      onRow={record => ({ onClick: handleRowClick(record) })}
      scroll={{ x: 800 }}
    />
  )
}

export default RegistryTable

import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const FullWidthImage = styled.img`
  width: 100%;
`

const UploadText = styled.div`
  margin-top: 8px;
`

const PlusIcon = styled(PlusOutlined)`
  margin-top: ${({ $hasText }) => $hasText ? null : '16px'};
`

const ImagePreview = ({ base64Image, imageSelectText, ...restProps }) => {
  if (base64Image) {
    return <FullWidthImage src={base64Image} {...restProps} />
  }
  return (
    <div {...restProps}>
      <PlusIcon $hasText={Boolean(imageSelectText)} />
      <UploadText>{imageSelectText}</UploadText>
    </div>
  )
}

export default ImagePreview

import { WalletProvider, useWallet } from '../hooks/useWallet'
import { OrganizationsProvider, OrganizationProvider, ElectionProvider } from '@hpm/voteos-hooks'
import { AuthProvider } from '../hooks/useAuth'
import { LayoutProvider } from '../hooks/useLayout'

const providers = [
  AuthProvider,
  WalletProvider,
  OrganizationsProvider,
  OrganizationProvider,
  ElectionProvider,
  LayoutProvider
]

const combineComponents = (components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent walletHook={useWallet}>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => <>{children}</>
  )
}

export const AppContextProvider = combineComponents(providers)

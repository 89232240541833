import { Input } from 'antd'
import styled from 'styled-components'

const ThemedInput = styled(Input)`
  .ant-input {
    font-size: 16px !important;
  }
  border-radius: ${({ theme }) => theme.borderRadius};
  ${({ bold }) => bold && 'font-weight: 600;'}
`

export const TextArea = styled(Input.TextArea)`
  font-size: 16px !important;
  .ant-input {
    font-size: 16px !important;
  }
  border-radius: ${({ theme }) => theme.borderRadius};
  ::after {
    font-size: 14px;
  }
`

export default ThemedInput

import { useState, useEffect, useCallback, useRef } from 'react'
import { useOrganization } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { formatCSV, generateUniqueId } from '../lib/importExportTools'
import { saveAs } from 'file-saver'
import cuid from 'cuid'

export default function VoterRegistryProvider ({ organizationAddress, registryIdParam }) {
  const { t } = useTranslation()
  const [, setLocation] = useLocation()
  const registryId = registryIdParam || `VR_${cuid()}`
  const isNewRegistry = !registryIdParam
  const registryDefault = !isNewRegistry ? {} : { id: registryId, title: t('voterRegistries.newTitlePlaceholder') }

  const tableRef = useRef(null)
  const { uploadPrivateFile, downloadPrivateFile, organization, loading: contractIsLoading, updateMetadata, setAddress } = useOrganization()
  const [error, setError] = useState('')
  const [hasPendingChanges, setHasPendingChanges] = useState(false)
  const [ipfsIsLoading, setIpfsIsLoading] = useState(false)
  const [loadingHint, setLoadingHint] = useState(t('voterRegistries.loadingHints.contractLoading'))
  const [registryContent, setRegistryContent] = useState(registryDefault)
  const [downloadedRegistry, setDownloadedRegistry] = useState(registryDefault)
  const [lastRegistryVersion, setLastRegistryVersion] = useState(0)

  useEffect(() => organizationAddress ? setAddress(organizationAddress) : null, [organizationAddress, setAddress])

  const uploadRegistry = async registry => {
    setLoadingHint(t('voterRegistries.loadingHints.ipfsUploading'))
    setIpfsIsLoading(true)
    const version = new Date().getTime()
    const jsonContent = JSON.stringify(registry)
    const ipfsResponse = await uploadPrivateFile({
      contentType: 'application/json;charset=utf-8',
      content: jsonContent,
      alias: registry.id,
      lastVersion: lastRegistryVersion,
      version
    })
    setLastRegistryVersion(version)
    setIpfsIsLoading(false)
    return ipfsResponse
  }

  const handleSave = async ({ avoidRedirect } = {}) => {
    try {
      const registryToSave = {
        ...registryContent,
        createdAt: registryContent.createdAt || new Date().getTime(),
        updatedAt: new Date().getTime()
      }
      const { statusCode, alias } = await uploadRegistry(registryToSave)
      if (statusCode === 409 || !alias) {
        return setError(t('voterRegistries.uploadVersionMismatch'))
      }
      setDownloadedRegistry(registryToSave)
      setRegistryContent({ ...registryToSave, voters: [...registryToSave.voters] })

      registryToSave.entryCount = registryContent.voters?.length || 0
      const weightSum = registryToSave.voters.reduce((previous, current) => {
        return previous + Number(current.weight)
      }, 0)
      delete registryToSave.voters
      registryToSave.alias = alias
      registryToSave.weightSum = weightSum
      const voterRegistries = Object.values(
        [...(organization?.voterRegistries || []), registryToSave].reduce((map, registry) => {
          return ({ ...map, [registry.id]: registry })
        }, {})
      )

      setLoadingHint(t('voterRegistries.loadingHints.updatingContract'))
      await updateMetadata({
        ...organization.metadata,
        voterRegistries
      })
      setHasPendingChanges(false)
      if (isNewRegistry && !avoidRedirect) {
        setLocation(`/organizations/${organization.address}/voterregistries/edit/${registryToSave.id}`)
      }
    } catch (error) {
      console.error(error)
      setError(error.message)
    }
  }

  const handleTitleChange = (newTitle) => {
    if (newTitle !== registryContent?.title) {
      setRegistryContent({ ...registryContent, title: newTitle })
    }
  }

  const handleTableDataChange = useCallback((newTableData) => {
    setRegistryContent({ ...registryContent, voters: newTableData })
  }, [registryContent])

  const downloadRegistry = useCallback(async registry => {
    setError('')
    if (!registry.alias) {
      return setError(t('voterRegistries.downloadFailed', { name: registryIdParam }))
    }
    setLoadingHint(t('voterRegistries.loadingHints.ipfsDownloading'))
    setIpfsIsLoading(true)
    try {
      const file = await downloadPrivateFile(registry.alias, false)
      const parsedRegistryContent = JSON.parse(file.file)
      setDownloadedRegistry(parsedRegistryContent)
      const weightSum = parsedRegistryContent.weightSum || parsedRegistryContent?.voters?.reduce((sum, current) => {
        return sum + Number(current.weight)
      }, 0)
      if (parsedRegistryContent && parsedRegistryContent.voters) {
        const hasMissingId = parsedRegistryContent.voters?.some((voter) => !voter.externalId)
        if (hasMissingId) {
          const currentIds = parsedRegistryContent.voters.map((voter) => voter.externalId)
          parsedRegistryContent.voters = parsedRegistryContent.voters.reduce((previous, current) => {
            if (!current.externalId) {
              const newId = generateUniqueId(currentIds)
              current.externalId = newId
              currentIds.push(newId)
            }
            return [...previous, current]
          }, [])
        }
      }
      setRegistryContent({ ...parsedRegistryContent, weightSum })
      tableRef?.current?.setVoters(parsedRegistryContent?.voters || [])
      setLastRegistryVersion(file.version)
      setIpfsIsLoading(false)
      setLoadingHint()
    } catch (error) {
      console.error(error)
      setError(t('voterRegistries.downloadFailed'))
    }
  }, [t, registryIdParam, downloadPrivateFile])

  const handleExport = () => {
    const csvContent = formatCSV(['externalId', 'name', 'email', 'weight', 'note'], registryContent?.voters || [])
    saveAs(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }), `${registryContent.title}.csv`)
  }

  useEffect(() => { // initial loading
    const initialLoad = async () => {
      const registry = organization.voterRegistries.find(({ id }) => id === registryIdParam)
      if (!registry || !registry.alias) {
        console.error('Could not find registry in contract')
        return setError(t('voterRegistries.downloadFailed', { name: registryIdParam }))
      }
      await downloadRegistry(registry)
    }
    if (isNewRegistry && organization) {
      return
    }
    if (!isNewRegistry && !contractIsLoading && organization && organization.voterRegistries) {
      initialLoad()
    }
  }, [registryIdParam, organization, contractIsLoading, downloadRegistry, isNewRegistry, t])

  useEffect(() => { // hasPendingChanges
    const hasChanges = JSON.stringify(downloadedRegistry.voters) !== JSON.stringify(registryContent.voters) || downloadedRegistry.title !== registryContent.title
    if (hasPendingChanges !== hasChanges) {
      setHasPendingChanges(hasChanges)
    }
  }, [downloadedRegistry, registryContent, hasPendingChanges])

  useEffect(() => { // disable loading states on error
    if (error?.length) {
      setIpfsIsLoading(false)
    }
  }, [error])

  return {
    error,
    handleExport,
    handleSave,
    handleTableDataChange,
    handleTitleChange,
    hasPendingChanges,
    isNewRegistry,
    loading: contractIsLoading || ipfsIsLoading,
    loadingHint,
    registryContent,
    setRegistryContent,
    setError,
    tableRef
  }
}

import { useState, useEffect, useCallback } from 'react'
import styled, { useTheme } from 'styled-components'
import { Row, Col, Result, Space, Typography, Alert } from 'antd'
import { DownloadOutlined, CheckCircleFilled, WarningFilled, LoadingOutlined } from '@ant-design/icons'
import Progress from '../../../common/Progress'
import Loading from '../../../common/Loading'
import Button from '../../../common/Button'
import ErrorMessage from '../../../common/ErrorMessage'
import VerticalSpace from '../../../common/VerticalSpace'
import { Subheadline, Description, Text } from '../../../common/Typography'
import { useTranslation } from 'react-i18next'
import { useWallet } from '../../../../hooks/useWallet'
import { useElection, useOrganization } from '@hpm/voteos-hooks'
import useVoterRegistry from '../../../../hooks/useVoterRegistry'
import mergeVotersWithVotes from './createVoterArrayWithVotes'
import { formatCSV } from '../../../../lib/importExportTools'
import { saveAs } from 'file-saver'
import Mustache from 'mustache'
import template from '../Mail/template-inlined.html'

const { Paragraph } = Typography

const WarningIcon = styled(WarningFilled)`
  font-size: 48px;
  color: ${({ theme }) => theme.sunriseYellow};
`
const SuccessIcon = styled(CheckCircleFilled)`
  font-size: 48px;
  color: ${({ theme }) => theme.lime};
`
const EMAIL_ENDPOINT = process.env.REACT_APP_IPFS_ENDPOINT_EMAIL
const VOTER_BASE_URL = process.env.REACT_APP_VOTEOS_VOTER_URL
const textTemplate = `{{topText}}

{{link}}

{{bottomText}}
`

const generateFileName = (title) => `${new Date().toISOString().substring(0, 10)}-${title}.csv`
const formatPercentage = (value) => value ? `${Math.round(value * 100)}` : ''

const GenerateVotes = ({ shouldSendMail, selectedVoters = [], onComplete, generationComplete, onClose }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { session } = useWallet()
  const { refresh } = useOrganization()
  const { election, listVotes, generateVotes, loading: loadingElection, loadingProgress } = useElection()
  const { registryContent, loading: loadingVoters } = useVoterRegistry({ organizationAddress: election?.organizationAddress, registryIdParam: election?.uriVoterRegistry })
  const [voters, setVoters] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [csvFile, setCsvFile] = useState()
  const [generated, setGenerated] = useState(false)
  const [status, setStatus] = useState('')
  const [sentEmails, setSentEmails] = useState(0)
  const [emailErrors, setEmailErrors] = useState([])

  const sendMail = useCallback(async (voterData) => {
    setStatus('sending')
    const errors = []
    for (const voter of voterData) {
      try {
        const emailTemplate = election?.metadata.emailTemplate
        const emailData = {
          theme,
          topText: emailTemplate.topText.replaceAll('\n', '<br/>'),
          bottomText: emailTemplate.bottomText.replaceAll('\n', '<br/>'),
          link: voter.link,
          subject: emailTemplate.subject,
          preHeader: t('election.mail.preHeader'),
          buttonText: t('election.mail.buttonText')
        }
        const output = Mustache.render(template, emailData)
        const text = Mustache.render(textTemplate, emailData)
        const body = JSON.stringify({
          to: voter.email,
          subject: election?.metadata?.emailTemplate?.subject,
          html: output,
          text
        })
        const res = await window.fetch(EMAIL_ENDPOINT, {
          method: 'POST',
          headers: {
            'X-Contract-Address': election.organizationAddress,
            Authorization: `Bearer ${session}`,
            'Content-Type': 'application/json'
          },
          body
        })
        const { messageId, error } = await res.json()
        if (error) {
          errors.push({ ...voter, error })
          setEmailErrors(errors)
        }
        if (messageId) {
          setSentEmails(current => current + 1)
        }
      } catch (error) {
        errors.push({ ...voter, error })
        setEmailErrors(errors)
      }
    }
  }, [election, session, t, theme])

  const exportVotes = useCallback(async (generatedVotes, voteList, votersWithVotes) => {
    setStatus('exporting')
    const voterMap = votersWithVotes.reduce((map, voter) => {
      map[voter.id] = voter
      return map
    }, {})
    voteList.forEach((vote) => {
      if (voterMap[vote.voterId]) {
        voterMap[vote.voterId].tokenId = vote.tokenId
      }
    })
    const resultsWithLinks = generatedVotes.map((result) => {
      const { email, name, tokenId, externalId, weight } = voterMap[result.voterId] || {}
      return {
        ...result,
        link: VOTER_BASE_URL.replace(':address', election.address).replace(':privateKey', result.privateKey),
        externalId,
        tokenId,
        email,
        name,
        weight
      }
    })
    if (shouldSendMail) {
      await sendMail(resultsWithLinks)
    }
    const fields = ['tokenId', 'externalId', 'name', 'email', 'link', 'address', 'privateKey', 'voterId', 'weight']
    const csvContent = formatCSV(fields, resultsWithLinks)
    const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8' })
    const name = generateFileName(election.title)
    setCsvFile(file)
    saveAs(file, name)
  }, [election, sendMail, shouldSendMail])

  const handleGenerateVotes = useCallback(async (voterSelection) => {
    setLoading(true)
    setError()
    let generatedVotes
    try {
      if (voterSelection.length) {
        const { result, errors } = await generateVotes(voterSelection)
        if (errors.length) {
          const errorMessage = t('election.votes.errors', { count: errors.length })
          console.log('errorMessage', errorMessage)
          setError(errorMessage)
        }
        generatedVotes = result
      }
    } catch (err) {
      setError(`${t('election.votes.generateError')}: ${err.message}`)
    }
    try {
      setStatus('processing')
      const voteList = await listVotes(0, registryContent.voters.length) // TODO: return tokenId in result of generateVotes
      const votersWithVotes = mergeVotersWithVotes(registryContent.voters, voteList)
      if (generatedVotes && generatedVotes.length) {
        await exportVotes(generatedVotes, voteList, votersWithVotes)
      }
    } catch (err) {
      setError(`${t('election.votes.exportError')}: ${err.message}`)
    }
    setLoading(false)
    setGenerated(true)
    onComplete()
    await refresh()
  }, [generateVotes, exportVotes, listVotes, registryContent, onComplete, refresh, t])

  useEffect(() => {
    if (selectedVoters && selectedVoters.length && registryContent && registryContent.voters && voters && !status) {
      setStatus('generating')
      handleGenerateVotes(selectedVoters)
    }
  }, [selectedVoters, handleGenerateVotes, registryContent, status, voters])

  const processVoterData = useCallback(async () => {
    if (!election || !registryContent || !registryContent.voters) { return }
    try {
      setLoading(true)
      const votes = await listVotes(0, registryContent.voters.length)
      const votesAndVoters = mergeVotersWithVotes(registryContent.voters, votes)
      setVoters(votesAndVoters)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading()
    }
  }, [election, registryContent, listVotes])

  useEffect(processVoterData, [processVoterData])

  const onDownloadFile = () => {
    if (!csvFile) {
      return
    }
    const name = generateFileName(election.title)
    saveAs(csvFile, name)
  }

  if ((loadingElection || loadingVoters) && !election) {
    return <Loading />
  }

  if (error) {
    return (
      <Result
        status='error'
        title={error}
        extra={<Button type='primary' onClick={onClose}>{t('election.votes.goBack')}</Button>}
      />
    )
  }

  if (generated) {
    return (
      <Row gutter={[0, 24]}>
        <Subheadline margin={0}>{generationComplete || t('election.votes.generated')}</Subheadline>
        <Col span={24} align='center'>
          {emailErrors?.length ? <WarningIcon /> : <SuccessIcon />}
        </Col>
        <Col span={24}>
          <Description margin={24} style={{ whiteSpace: 'break-spaces' }}>{t('election.votes.successMessage')}</Description>
          <Description margin={8} type='warning'><WarningFilled /> {t('election.votes.warningMessage')}</Description>
          {!!emailErrors.length && (
            <Alert
              type='error'
              message={t('election.votes.emailErrors') + ':'}
              description={emailErrors.map((e) => (
                <Paragraph key={e.externalId} copyable={{ text: e.link, tooltips: [t('election.votes.copy'), t('election.votes.copied')] }}>{e.email}</Paragraph>
              ))}
            />
          )}
        </Col>
        <Col span={24} align='right'>
          <Space size={16}>
            <Button size='large' icon={<DownloadOutlined />} onClick={onDownloadFile}>{t('election.votes.download')}</Button>
            <Button size='large' type='primary' onClick={onClose}>{t('election.goBack')}</Button>
          </Space>
        </Col>
      </Row>
    )
  }

  const percentage = formatPercentage(sentEmails ? sentEmails / selectedVoters?.length || 0 : loadingProgress)
  const progressInfo = (
    <VerticalSpace size={24}>
      <Subheadline margin={0}>{t('election.votes.generate')}</Subheadline>

      <Text align='center'>
        <LoadingOutlined spin /> {t('election.votes.' + (sentEmails ? 'sending' : 'generating'), { count: selectedVoters.length })}
      </Text>
      <Progress status={loading ? 'active' : 'normal'} percent={percentage} />
      <Description type='warning'><WarningFilled /> {t('election.votes.dontClose')}</Description>
    </VerticalSpace>
  )
  return (
    <Row gutter={[16, 16]}>
      {!!error && (
        <Col span={24}>
          <ErrorMessage error={error} />
        </Col>
      )}
      <Col xs={24}>
        {progressInfo}
      </Col>
    </Row>
  )
}

export default GenerateVotes

import styled from 'styled-components'
import { Result } from 'antd'
import { useTranslation } from 'react-i18next'
import Button from '../../../common/Button'
import { Text, Subheadline } from '../../../common/Typography'
import Icon from '@ant-design/icons'
import { ReactComponent as Error } from '../../../../assets/error.svg'
import { ReactComponent as Sync } from '../../../../assets/sync.svg'

const LargeIcon = styled(Icon)`
  font-size: 200px !important;
`

const StyledResult = styled(Result)`
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .ant-result-icon {
    margin-bottom: 48px;
  }
  .ant-result-title {
    margin-bottom: 24px;
  }
`

const CreateResult = ({ error, onReturn }) => {
  const { t } = useTranslation()

  if (error) {
    return (
      <StyledResult
        title={error}
        status='error'
        icon={<LargeIcon component={Error} />}
        extra={<Button onClick={onReturn}>{t('retry')}</Button>}
      />
    )
  }
  return (
    <StyledResult
      title={<Subheadline>{t('home.welcome')}</Subheadline>}
      subTitle={<Text>{t('organization.creating')}</Text>}
      icon={<LargeIcon component={Sync} spin />}
    />
  )
}

export default CreateResult

import React from 'react'
import ReactMarkdown from 'markdown-to-jsx'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'wouter'
import { ReactComponent as Logo } from '../Layout/Navigation/Logo.svg'
import { useAuth } from '../../hooks/useAuth'
import Footer from '../Layout/Footer'

const StyledContent = styled.div`
  height: 100vh;
  margin-bottom: -48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const StyledLogo = styled(Logo)`
  margin-top: 3vh;
  margin-left: 2vw;
  path { fill: ${({ theme }) => theme.primaryColor}}; };
  cursor: pointer;
`

const WrapHeader = styled.div`
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
`

const WrapBody = styled.div`
  padding-top: 4vh;
  padding-left: 2vw;
  padding-right: 2vw;
`

const styledHeader = styled.h1`
  font-size: 1.8em;
`

const Terms = () => {
  const { signedIn } = useAuth()
  const { t } = useTranslation()

  return (
    <StyledContent>
      {!signedIn &&
        <WrapHeader>
          <Link to='/'>
            <StyledLogo />
          </Link>
        </WrapHeader>}
      <WrapBody>
        <ReactMarkdown options={{
          overrides: {
            h1: {
              component: styledHeader,
              props: {
                className: 'foo'
              }
            }
          }
        }}
        >
          {t('terms')}
        </ReactMarkdown>
      </WrapBody>
      <Footer />
    </StyledContent>
  )
}

export default Terms

import { useEffect, useCallback, useState } from 'react'
import { Row, Col, Grid, Space } from 'antd'
import { FileUnknownOutlined, SolutionOutlined, CalendarOutlined, FieldTimeOutlined } from '@ant-design/icons'
import Agenda from './Agenda'
import Voters from './VoterTable'
import Loading from '../../../common/Loading'
import NotFound from '../../../common/NotFound'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../View/Sidebar'
import AuditLog from '../AuditLog'
import Attachments from './Agenda/Attachments'
import Tabs from '../View/Tabs'
import { LayoutContainer, Content } from '../View'
import LastChange from '../../../common/LastChange'
import Logo from '../../../common/Logo'
import { Headline, Paragraph } from '../../../common/Typography'
import mergeVotersWithVotes from '../Votes/createVoterArrayWithVotes'
import { useElection } from '@hpm/voteos-hooks'
import useVoterRegistry from '../../../../hooks/useVoterRegistry'
import { useTranslation } from 'react-i18next'
const { useBreakpoint } = Grid

export default function Overview ({ params: { address, feature } }) {
  const { t } = useTranslation()
  const screens = useBreakpoint()
  const { election, polls, loading, loadingPolls, setAddress, setState, subscribeToEvents, listVotes, events, listenForAllEvents, loadEvents } = useElection()
  const {
    registryContent,
    loading: loadingVoters
  } = useVoterRegistry({ registryIdParam: election?.uriVoterRegistry, organizationAddress: election?.organizationAddress })
  const [voters, setVoters] = useState()
  const [weightSum, setWeightSum] = useState(0)

  useEffect(() => setAddress(address), [address, setAddress])
  useEffect(subscribeToEvents, [subscribeToEvents])
  useEffect(() => {
    try {
      loadEvents()
    } catch (err) {
      console.error(err)
    }
    const unlisteners = listenForAllEvents()
    return () => {
      unlisteners.forEach(cb => {
        try {
          cb()
        } catch (err) {
          console.error(err)
        }
      })
    }
  }, [loadEvents, listenForAllEvents])

  const processVoterData = useCallback(async () => {
    if (!election || !registryContent || !registryContent.voters) { return }
    try {
      const votes = await listVotes()
      const totalWeight = votes.reduce((previous, current) => {
        return previous + (current.weight || 1)
      }, 0)
      const votesAndVoters = mergeVotersWithVotes(registryContent.voters, votes)
      setWeightSum(totalWeight)
      setVoters(votesAndVoters)
    } catch (err) {
      console.error(err.message)
    }
  }, [election, registryContent, listVotes])
  useEffect(processVoterData, [processVoterData])

  if (loading && !election) {
    return <Loading description={t('election.loading')} />
  }

  if (!loading && !loadingPolls && !loadingVoters && !election && !polls) {
    return <NotFound title={t('election.notFound')} homeLink='/elections' />
  }

  const breadcrumbs = [
    {
      title: t('navigation.elections'),
      link: election && election.organizationAddress ? `/organizations/${election.organizationAddress}/elections` : null,
      icon: <CalendarOutlined />
    },
    { title: t('election.features.moderation') }
  ]

  const { title, lastChange } = election || {}
  const agenda = (
    <Agenda
      {...election}
      loading={!polls && loadingPolls}
      polls={polls}
      events={events}
      totalVoteWeight={weightSum || 0}
    />
  )
  const voterTab = <Voters {...election} {...registryContent} voters={voters} weightSum={weightSum} loading={loadingVoters} />

  const tabOptions = [
    { id: 'agenda', icon: <FileUnknownOutlined />, name: t('election.features.agenda'), children: agenda },
    { id: 'voters', icon: <SolutionOutlined />, name: t('election.registry.voters'), description: t('election.registry.votersDescription'), children: voterTab },
    { id: 'history', icon: <FieldTimeOutlined />, name: t('election.features.history'), children: <AuditLog /> }
  ]

  return (
    <LayoutContainer>
      <Content>
        <Row gutter={[64, 32]}>
          <Col span={24}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
          </Col>

          <Col span={24}>
            <Headline margin='32'>{title || '-'}</Headline>
            <LastChange date={lastChange} />
          </Col>

          {(election?.logo || election?.description) && (
            <Col span={24}>
              <Space size={16}>
                {election?.logo && <Logo src={election.logo} />}
                <Paragraph>{election?.description}</Paragraph>
              </Space>
            </Col>
          )}

          {election?.metadata?.attachments && (
            <Col span={24}>
              <Attachments attachments={election.metadata.attachments} />
            </Col>
          )}
          <Col span={24}>
            <Tabs
              options={tabOptions}
              activeKey={feature}
              collapsed={screens.xs}
            />
          </Col>

          <Col span={24} />
        </Row>
      </Content>
      <Sidebar
        election={election}
        loading={loading}
        setState={setState}
        polls={polls}
      />
    </LayoutContainer>
  )
}

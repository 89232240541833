import { useState, useEffect, useCallback } from 'react'
import { useLocation, Redirect } from 'wouter'
import styled from 'styled-components'
import { Typography, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useOrganizations } from '@hpm/voteos-hooks'
import SelectImage from '../../../common/SelectImage'
import Button from '../../../common/Button'
import Input from '../../../common/Input'
import { Headline } from '../../../common/Typography'

import Result from './Result'
const { Paragraph } = Typography
const Spacer = styled(Form.Item)`
  height: 0px;
`

export default function CreateOrganization ({ params: { auto } }) {
  const { t } = useTranslation()
  const { organizations, loading, createOrganization, refreshOrganizations } = useOrganizations()
  const [creating, setCreating] = useState(false)
  const [, setLocation] = useLocation()
  const [newOrganizationAddress, setNewOrganizationAddress] = useState()
  const [logo, setLogo] = useState()
  const [title, setTitle] = useState('')
  const [error, setError] = useState()

  const handleChangeTitle = (event) => {
    setTitle(event.target.value)
  }
  const handleChangeLogo = (logo) => setLogo(logo)

  const handleCancel = () => setLocation('/organizations')
  const handleSubmit = useCallback(async () => {
    setError()
    const organizationData = { title, logo }
    if (!title) {
      organizationData.title = t('organization.defaultTitle')
    }
    try {
      setCreating(true)
      const address = await createOrganization(organizationData)
      setNewOrganizationAddress(address)
      setCreating(false)
      refreshOrganizations()
    } catch (err) {
      console.error(err)
      setError(err.message)
    }
  }, [createOrganization, logo, title, refreshOrganizations, t])

  useEffect(() => {
    if (auto && !creating && !loading && !organizations.some((organization) => organization.membershipStateName === 'active')) {
      handleSubmit()
    }
  }, [auto, handleSubmit, creating, loading, organizations])
  if (newOrganizationAddress) {
    return <Redirect to={`/organizations/${newOrganizationAddress}/`} />
  }

  if (creating || loading) {
    return <Result error={t(error)} onReturn={handleCancel} />
  }

  return (
    <Form
      layout='vertical'
      onFinish={handleSubmit}
    >
      <Headline>{t('organization.add')}</Headline>
      <Form.Item>
        <Paragraph>{t('organization.description')}</Paragraph>
      </Form.Item>
      <Form.Item label={t('organization.title')} required help={t('form.required')}>
        <Input
          size='large'
          value={title}
          onChange={handleChangeTitle}
          placeholder={t('title')}
          onPressEnter={handleSubmit}
          bold='true'
        />
      </Form.Item>
      <Spacer />
      <Form.Item label={t('organization.logo')} help={t('form.fileSize')}>
        <SelectImage
          maxWidth={480}
          maxHeight={480}
          defaultImage={logo}
          onSelect={handleChangeLogo}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
        <Button
          size='large'
          type='primary'
          htmlType='submit'
          style={{ float: 'right' }}
          disabled={!title}
          loading={loading}
        >
          {t('organization.create')}
        </Button>
      </Form.Item>
    </Form>
  )
}

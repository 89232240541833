import styled from 'styled-components'
import { Row, Col } from 'antd'
import { FilePdfOutlined, FundProjectionScreenOutlined, CopyOutlined } from '@ant-design/icons'
import Sider from './Sider'
import Status from './Status'
import Instruction from './Instruction'
import Button from '../../../../common/Button'

import { useTranslation } from 'react-i18next'

const RowContainer = styled(Row)`
  padding-left: 24px;
  padding-right: 40px;
  padding-top: 80px;
  padding-bottom: 40px;
`

const InstructionContainer = styled(Col)`
  padding-top: 16px;
`
const PRESENTATION_URL = process.env.REACT_APP_VOTEOS_PRESENTATION_URL

const Sidebar = ({ election = {}, voters, polls, loading, setState, hasPendingChanges, handleChangePolls, debug = false, ...restProps }) => {
  const { t } = useTranslation()

  const isEnded = election && election.state === 'ended'
  const showLinkToPresentation = election && ['opened', 'started'].includes(election.state) && election.isModerated
  const showInstructions = election && ['created', 'opened'].includes(election.state)

  const openPresentationMode = () => {
    const presentationUrl = `${PRESENTATION_URL}/${election.address}/`
    window.open(presentationUrl, '_blank', 'noreferrer')
  }
  const openReport = () => {
    const reportUrl = `${PRESENTATION_URL}/${election.address}?print=true`
    window.open(reportUrl, '_blank', 'noreferrer')
  }

  const exportPollData = () => {
    navigator.clipboard.writeText(JSON.stringify(polls))
  }
  const importPollData = async () => {
    const text = await navigator.clipboard.readText()
    const data = JSON.parse(text)
    handleChangePolls(data.map((poll) => ({ ...poll, created: true })))
  }

  return (
    <Sider {...restProps}>
      <RowContainer gutter={[64, 24]}>
        <Col span={24}>
          <Status
            status={election.state}
            isModerated={election.isModerated}
            setState={setState}
            loading={loading}
            voteCount={election.voteCount}
            polls={polls}
            hasPendingChanges={hasPendingChanges}
            debug={debug}
          />
        </Col>
        {showInstructions && (
          <InstructionContainer span={24}>
            <Instruction election={election} polls={polls} voters={voters} />
          </InstructionContainer>
        )}
        {isEnded && (
          <Col span={24}>
            <Button
              block
              type='primary'
              onClick={openReport}
              icon={<FilePdfOutlined />}
            >
              {t('election.report')}
            </Button>
          </Col>
        )}
        {showLinkToPresentation && (
          <Col span={24}>
            <Button
              block
              onClick={openPresentationMode}
              icon={<FundProjectionScreenOutlined />}
            >
              {t('election.present')}
            </Button>
          </Col>
        )}
        {debug && (
          <Col span={24}>
            <Button
              block
              type='primary'
              onClick={isEnded ? exportPollData : importPollData}
              icon={<CopyOutlined />}
            >
              {isEnded ? 'copy polls' : 'pastePolls'}
            </Button>
          </Col>
        )}
      </RowContainer>
    </Sider>
  )
}

export default Sidebar

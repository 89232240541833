import { Row, Col, Typography, Spin } from 'antd'
const { Text } = Typography

const LabeledValue = ({ title, icon, loading, children }) => {
  return (
    <Row>
      {!!icon && (
        <Col flex='20px'>
          {icon}
        </Col>
      )}
      <Col flex={1}>
        {!!title && <Text>{title}:</Text>}
      </Col>
      <Col flex={1} align='right'>
        {loading ? <Spin size='small' /> : children}
      </Col>
    </Row>
  )
}

export default LabeledValue

import { useState } from 'react'
import { Empty, Row, Col, Skeleton, Checkbox } from 'antd'
import { CheckOutlined, DragOutlined } from '@ant-design/icons'
import VerticalSpace from '../../../common/VerticalSpace'
import Button from '../../../common/Button'
import EditCard from './Poll/EditCard'
import Reorder from './Reorder'
import { useTranslation } from 'react-i18next'
import empty from './empty.svg'

const Agenda = ({ polls = [], pollCount, loadingPolls, isModerated, onChangeModeration, onChangePolls, onChangePollOrder, hasCreatedPolls }) => {
  const { t } = useTranslation()
  const [isReordering, setIsReordering] = useState()

  const handlePollChange = (index) => (key, value) => {
    const newPolls = [...polls]
    const isNewPoll = !newPolls[index]
    newPolls[index] = { created: isNewPoll, ...newPolls[index], [key]: value, changed: true }
    if (key === 'minVotes' && (!newPolls[index].maxVotes || newPolls[index].maxVotes < value)) {
      newPolls[index].maxVotes = value || 1
    }
    if (key === 'maxVotes' && newPolls[index].minVotes >= value) {
      newPolls[index].minVotes = value || 1
    }
    onChangePolls(newPolls)
  }
  const handleRemovePoll = (index) => () => {
    const filteredPolls = polls.filter((item, pollIndex) => pollIndex !== index)
    onChangePolls(filteredPolls)
  }

  if (!polls.length && loadingPolls) {
    const skeletons = []// new Array(pollCount || 1).fill(<Skeleton active key={Math.floor(Math.random() * 10)} />)
    for (let i = 0; i < (pollCount || 1); i++) {
      const randomKey = Math.floor(Math.random() * 100)
      skeletons.push(<Skeleton active key={randomKey} />)
    }
    return (
      <VerticalSpace size='large'>
        {skeletons}
      </VerticalSpace>
    )
  }

  if (!polls) {
    return <Empty image={empty} description={t('election.agenda.empty')} />
  }

  const pollCards = [...polls]
  const lastPoll = pollCards.length ? pollCards[pollCards.length - 1] : null
  if (!lastPoll || (lastPoll && lastPoll.title && lastPoll.answers && lastPoll.answers.length)) {
    pollCards.push({ title: '', created: true })
  }
  const reorderButton = (
    <Button
      title={hasCreatedPolls ? t('election.poll.reorderDisabled') : ''}
      icon={isReordering ? <CheckOutlined /> : <DragOutlined />}
      onClick={() => setIsReordering(!isReordering)}
      disabled={!polls || polls.length <= 1 || hasCreatedPolls}
    >
      {t(isReordering ? 'election.poll.saveOrder' : 'election.poll.reorder')}
    </Button>
  )
  return (
    <Row gutter={[16, 24]} align='middle'>
      <Col span={12}>
        <Checkbox checked={isModerated} onChange={onChangeModeration}>{t('election.isModerated')}</Checkbox>
      </Col>
      <Col span={12} align='end'>
        {reorderButton}
      </Col>
      {isReordering && <Col span={24}><Reorder polls={polls} onChangePolls={onChangePollOrder} /></Col>}
      {!isReordering && pollCards.map((poll, index) => (
        <Col span={24} key={poll.created ? 'newPoll' + index : poll.id}>
          <EditCard {...poll} onChange={handlePollChange(index)} onRemove={index < pollCards.length - 1 || poll.title ? handleRemovePoll(index) : null} />
        </Col>
      ))}
    </Row>
  )
}

export default Agenda

import { useEffect } from 'react'
import { Typography } from 'antd'
import { LinkOutlined as IconLink } from '@ant-design/icons'
import Table from '../../common/Table'
import getColumnSearchProps from '../../common/TableFilter'
import { useElection } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'

const { Text, Link } = Typography
const CONNEX_NETWORK_EXPLORER = process.env.REACT_APP_BLOCKCHAIN_EXPLORER

export default function ElectionAudit () {
  const { t } = useTranslation()
  const { events, loadEvents, listenForAllEvents, loading } = useElection()

  const COLUMNS = [
    {
      title: t('election.audit.timestamp'),
      dataIndex: 'timestamp',
      key: 'time',
      sorter: (a, b) => a.timestamp - b.timestamp,
      ...getColumnSearchProps({ dataIndex: 'time', handleSearch: (keys, confirm) => confirm() }),
      render: timestamp => (new Date(timestamp * 1000)).toISOString().split('.')[0].split('T').join(' ')
    },
    {
      title: t('election.audit.content'),
      key: 'text',
      ellipsis: true,
      filterSearch: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.name - b.name,
      ...getColumnSearchProps({ dataIndex: 'name', handleSearch: (keys, confirm) => confirm() }),
      render: ({ name, inputs, values }) => (
        <>
          <Text>{name}</Text>
          <ul>
            {inputs.map(({ name }) => <li key={name}><Text type='secondary'>{name}: {String(values[name])}</Text></li>)}
          </ul>
        </>
      )
    },
    {
      title: t('election.audit.executor'),
      dataIndex: 'origin',
      key: 'origin',
      ellipsis: true,
      filterSearch: true,
      onFilter: (value, record) => record.origin === value,
      sorter: (a, b) => a.origin - b.origin,
      ...getColumnSearchProps({ dataIndex: 'origin', handleSearch: (keys, confirm) => confirm() }),
      render: (origin, index) => <Link key={origin + index} href={`${CONNEX_NETWORK_EXPLORER}/accounts/${origin}`} target='_blank' rel='noreferrer' code>{origin}</Link>
    },
    {
      title: <IconLink />,
      dataIndex: 'txId',
      key: 'txId',
      width: 50,
      render: (txId, index) => <a key={txId + index} href={`${CONNEX_NETWORK_EXPLORER}/transactions/${txId}`} target='_blank' rel='noreferrer'><IconLink /></a>
    }
  ]

  useEffect(() => {
    loadEvents()
    const unlisteners = listenForAllEvents()
    return () => unlisteners.map(cb => cb())
  }, [loadEvents, listenForAllEvents])

  return (
    <Table
      sticky
      rowKey={({ meta, txId, name, timestamp, values, ...column }) => {
        return txId + name + timestamp + values?.answerId + values?.pollId + values?.voterId + values?.tokenId
      }}
      columns={COLUMNS}
      dataSource={events}
      loading={loading}
    />
  )
}

import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Col } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import Input, { TextArea } from '../../../common/Input'
import Button from '../../../common/Button'
import SelectImage from '../../../common/SelectImage'
import Attachments from '../../../common/Attachments'
import Spacer from '../../../common/Spacer'
import { useStorage } from '@hpm/voteos-hooks'

import { useTranslation } from 'react-i18next'

const ButtonCol = styled(Col)`
  margin-top: 48px;
`
const ElectionForm = ({
  onChange,
  onRemove,
  title: defaultTitle,
  description: defaultDescription,
  logo: defaultLogo,
  files,
  onUpdateFiles,
  loading,
  create
}) => {
  const { t } = useTranslation()
  const { uploadFile, getPublicUri, loading: uploadLoading } = useStorage()

  const [title, setTitle] = useState(!create ? defaultTitle : '')
  const [description, setDescription] = useState(!create ? defaultDescription : '')
  const [logo, setLogo] = useState(!create ? defaultLogo : '')

  const handleChangeTitle = (e) => setTitle(e.target.value)
  const handleChangeDescription = (e) => setDescription(e.target.value)
  const handleChangeLogo = (logo) => setLogo(logo)

  useEffect(() => {
    if (title !== defaultTitle || description !== defaultDescription || logo !== defaultLogo) {
      onChange({ title, description, logo })
    }
  }, [title, description, logo, onChange, defaultTitle, defaultDescription, defaultLogo])

  return (
    <Form layout='vertical'>
      <Form.Item label={t('title')}>
        <Input value={title} onChange={handleChangeTitle} bold={1} size='large' />
      </Form.Item>
      <Form.Item label={t('logo')} help={t('form.fileSize')}>
        <SelectImage maxWidth={480} maxHeight={480} defaultImage={logo} onSelect={handleChangeLogo} />
      </Form.Item>
      <Spacer height={24} />
      <Form.Item label={t('description')}>
        <TextArea rows={4} value={description} onChange={handleChangeDescription} />
      </Form.Item>

      <Form.Item label={t('attachments')}>
        <Attachments {...{ files, onUpdateFiles, uploadFile, getPublicUri, uploadLoading }} />
      </Form.Item>

      {!create && (
        <ButtonCol xs={24} sm={8}>
          <Button block danger icon={<DeleteOutlined />} onClick={onRemove}>{t('election.remove')}</Button>
        </ButtonCol>
      )}
    </Form>
  )
}

export default ElectionForm

import { useCallback, useEffect, useState } from 'react'
import Connex from '@vechain/connex'
import useInterval from '@hpm/useinterval'
import { Typography } from 'antd'
import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

const { Text, Link } = Typography

const CONNEX_NETWORK_URL = process.env.REACT_APP_CONNEX_NETWORK_URL
const CONNEX_NETWORK_TYPE = process.env.REACT_APP_CONNEX_NETWORK_TYPE
const CONNEX_NETWORK_EXPLORER = process.env.REACT_APP_BLOCKCHAIN_EXPLORER

const connex = new Connex({
  node: CONNEX_NETWORK_URL,
  network: CONNEX_NETWORK_TYPE
})

const DELAY_WARNING_SECONDS = 30
const DELAY_ERROR_SECONDS = 60

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.light};
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.light} !important;
`

const StatusCircle = ({ delay, loading }) => {
  const theme = useTheme()
  if (loading) {
    return <LoadingOutlined style={{ color: theme.sunriseYellow }} />
  }
  if (delay > DELAY_ERROR_SECONDS) {
    return <CloseCircleFilled style={{ color: theme.dustRed }} />
  }
  if (delay > DELAY_WARNING_SECONDS) {
    return <ClockCircleFilled style={{ color: theme.sunriseYellow }} />
  }
  return <CheckCircleFilled style={{ color: theme.lime }} />
}

export default function LayoutConnectivity () {
  const { t } = useTranslation()
  const [head, setHead] = useState()
  const [connecting, setConnecting] = useState(true)
  const [delay, setDelay] = useState(0)

  const waitForNextBlock = async function () {
    if (!connex) {
      return null
    }

    const head = await connex.thor.ticker().next()
    return head
  }

  useEffect(() => {
    let isUnmounted = false

    waitForNextBlock().then(head => {
      if (!head) {
        return
      }
      if (isUnmounted) {
        return
      }
      setHead(head)
    })
    return () => {
      isUnmounted = true
    }
  }, [head])

  useEffect(() => {
    if (connecting && head) {
      setConnecting(false)
    } else if (!connecting && !head) {
      setConnecting(true)
    }
  }, [head, connecting])

  useEffect(() => {
    if (!head) {
      return setDelay(0)
    }

    const now = +(new Date()) / 1000
    const delay = Math.round(now - head.timestamp)
    setDelay(delay < 0 ? 0 : delay)
  }, [head])

  const increaseDelay = useCallback(function () {
    setDelay(delay + 1)
  }, [delay])

  useInterval(increaseDelay, 1 * 1000)

  return (
    <StyledText>
      <StatusCircle delay={delay} loading={connecting} />
      &nbsp;&nbsp;
      <StyledLink href={`${CONNEX_NETWORK_EXPLORER}/blocks/${head?.id}`} target='_blank' rel='noreferrer'>{t('footer.latestBlock')}{Number(head?.number || 0).toLocaleString() || 'Disonnected'}</StyledLink>
      &nbsp;&nbsp;
      {t('footer.lastKnownData', { delay })}
    </StyledText>
  )
}

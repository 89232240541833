import { Space, Typography } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import Button from '../../../../common/Button'
import { useTranslation } from 'react-i18next'
import { generateMailtoLink } from '../../../../../lib/mailto'

const SubscriptionInfo = ({ organization }) => {
  const { t } = useTranslation()

  const SUPPORT_EMAIL_ADDRESS = 'gl+p-voe-support-3645-issue-@hpm.agency'
  if (!organization) return null
  if (organization.isSubscribed) {
    return <div />
  }
  const subscriptionMailto = generateMailtoLink(
    SUPPORT_EMAIL_ADDRESS,
    t('organization.account.subscriptionSubject', { organization: organization.title }),
    t('organization.account.subscriptionBody', organization)
  )
  return (
    <Space>
      <Typography.Text>{t('organization.account.subscriptionAsk')}</Typography.Text>
      <Button href={subscriptionMailto} type='link' target='_blank' icon={<MailOutlined />}>
        {t('organization.account.subscribe')}
      </Button>
    </Space>
  )
}

export default SubscriptionInfo

import { useState, useEffect, useCallback } from 'react'
import { Row, Col, Modal } from 'antd'
import { Headline, Description } from '../../../../common/Typography'
import { ExclamationCircleOutlined, UserAddOutlined } from '@ant-design/icons'
import Button from '../../../../common/Button'
import Message from '../../../../common/Message'
import Edit from '../Edit'
import Invite from '../Invite'
import MemberList from './Table'
import { useOrganization } from '@hpm/voteos-hooks'
import { useWallet } from '../../../../../hooks/useWallet'
import { useTranslation } from 'react-i18next'

const VOTEOS_ORGANIZATION_ADMIN_ROLE = process.env.REACT_APP_VOTEOS_ORGANIZATION_ADMIN_ROLE
const VOTEOS_ORGANIZATION_OWNER_ROLE = process.env.REACT_APP_VOTEOS_ORGANIZATION_OWNER_ROLE

const Error = ({ error }) => {
  if (!error) {
    return <></>
  }
  return (
    <Col span={24}>
      <Message type='error' text={error} />
    </Col>
  )
}

export default function OrganizationMemberList ({ params }) {
  const { t } = useTranslation()
  const { wallet } = useWallet()
  const { organization, loading, getPrivateMetadata, removeMembershipFor, addMembershipFor, setMembershipRole, setAddress } = useOrganization()
  const [members, setMembers] = useState([])
  const [error, setError] = useState()
  const [editing, setEditing] = useState()
  const [inviting, setInviting] = useState()
  const [loadingAdditionalData, setLoadingAdditionalData] = useState()
  useEffect(() => setAddress(params.address), [params.address, setAddress])

  const handleRemove = (member) => {
    Modal.confirm({
      title: t('organization.member.remove'),
      icon: <ExclamationCircleOutlined />,
      content: t('organization.member.removeConfirm', { member: member.email || member.id }),
      okText: t('yes'),
      cancelText: t('no'),
      okType: 'danger',
      onOk: async () => {
        setError('')
        try {
          await removeMembershipFor(member.id)
        } catch (err) {
          setError(err.message)
          console.error(err)
        }
      }
    })
  }

  const extendMemberList = useCallback(async () => {
    if (!organization?.members) {
      return setMembers([])
    }

    setLoadingAdditionalData(true)
    const privateMetadata = await getPrivateMetadata()
    const privateMemberships = privateMetadata?.memberships || {}
    const members = (organization.members || [])
      .filter((member) => member?.stateName !== 'inactive')
      .map(member => {
        const isCurrentUser = member?.id?.toLowerCase() === wallet?.address?.toLowerCase()
        const canDeleteMember = organization.role > VOTEOS_ORGANIZATION_ADMIN_ROLE && !isCurrentUser && member.stateName === 'active' && member.role < VOTEOS_ORGANIZATION_OWNER_ROLE

        const privateMembership = privateMemberships[member.membershipId] || {}
        return {
          ...privateMembership,
          ...member,
          lastActivityAt: member.user?.authenticatedAt,
          canDeleteMember,
          isCurrentUser,
          isOwner: member.role === VOTEOS_ORGANIZATION_OWNER_ROLE
        }
      })
    setMembers(members)
    setLoadingAdditionalData(false)
  }, [getPrivateMetadata, organization, wallet?.address])

  useEffect(() => {
    extendMemberList()
  }, [extendMemberList])

  return (
    <Row gutter={[0, 24]}>
      <Col flex={1} justify='center'>
        <Headline>{t('members.manage')}</Headline>
        <Description margin={8}>{t('members.description')}</Description>
      </Col>
      <Col span={24} align='right'>
        <Button size='large' type='primary' icon={<UserAddOutlined />} onClick={() => setInviting(true)}>{t('add')}</Button>
      </Col>
      <Error error={t(error)} />
      <Col span={24}>
        <MemberList
          members={members}
          loading={loading || loadingAdditionalData}
          onRemove={handleRemove}
          onEdit={setEditing}
        />
      </Col>
      <Edit member={editing} onCancel={setEditing} setMembershipRole={setMembershipRole} />
      <Invite open={inviting} onCancel={() => setInviting(false)} onInvite={addMembershipFor} address={params.address} />
    </Row>
  )
}

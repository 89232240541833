import { useState, useEffect } from 'react'
import { useLocation } from 'wouter'
import { Spin } from 'antd'
import { useOrganization } from '@hpm/voteos-hooks'
import Leave from '../../common/Confirmation'
import NotFound from '../../common/NotFound'

export default function OrganizationLeave ({ params }) {
  const [, setLocation] = useLocation()
  const { loading, leaveOrganization, organization, setAddress } = useOrganization()
  const [error, setError] = useState()

  useEffect(() => setAddress(params.address), [params.address, setAddress])

  const handleCancel = () => setLocation(`/organizations/${params.address}/view`)

  const handleLeave = async () => {
    setError('')
    try {
      await leaveOrganization(params.address)
      setLocation('/organizations')
    } catch (err) {
      setError(err.message)
    }
  }

  if (loading && !organization) {
    return <Spin />
  }

  if (!organization) {
    return <NotFound text={error} title='Organization' />
  }

  return <Leave {...organization} onConfirm={handleLeave} onCancel={handleCancel} error={error} loading={loading} type='organization' />
}

import { Typography } from 'antd'
const { Text } = Typography

const LastChange = ({ version }) => {
  return (
    <Text italic type='secondary'>{`v.${version}`}</Text>
  )
}

export default LastChange

import { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Checkbox, Typography, Upload, Alert } from 'antd'
import { saveAs } from 'file-saver'
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons'
import { determineChanges, applyChanges, parseCSV } from '../../../../../../lib/importExportTools'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../common/Button'
import RoundedModal from '../../../../../common/RoundedModal'
import { Subheadline, Description } from '../../../../../common/Typography'

const { Text, Paragraph } = Typography

const Dragger = styled(Upload.Dragger)`
  .ant-upload-btn {
    padding: 0px !important;
    display: flex !important;
    justify-content: center;
    align-items: center
  }
  .ant-upload-btn > .ant-upload-drag-container > .ant-typography {
    margin-bottom: 0px !important;
  }
  background: transparent !important;
  height: 40px !important;
`
export default function ImportDialog ({ voters, setError, updateVoters, visible, onExit }) {
  const { t } = useTranslation()
  const [mergeMethods, setMergeMethods] = useState([])
  const [changes, setChanges] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [fileError, setFileError] = useState('')

  let fileContent = ''

  const handleClose = () => {
    setFileError()
    onExit()
  }
  const determineChangesFromImportFile = (fileContent) => {
    // parse file content
    const importEntries = parseCSV(fileContent)
    // calculate changes to current entries
    const changes = determineChanges(voters, importEntries)
    return changes
  }

  const handleFileChange = async ({ file, fileList }) => {
    if (!file) {
      return setSelectedFile()
    }
    setFileError()

    // support removing a selected file
    if (fileList && fileList.length === 0) {
      fileContent = ''
      setChanges(null)
      setSelectedFile()
      return
    }

    // set upload status to done (we're not using the upload status)
    fileList.forEach(f => {
      f.status = 'done'
    })

    // read the selected files content
    try {
      const reader = new FileReader()
      reader.readAsText(file.originFileObj)
      reader.onloadend = () => {
        fileContent = reader.result
        try {
          const changesFromFile = determineChangesFromImportFile(fileContent)
          setChanges(changesFromFile)
          setSelectedFile(file.name)
        } catch (error) {
          setFileError(t('organization.voterRegistry.fileError') + error.message)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleImport = async () => {
    setError('')
    try {
      setError('')
      try {
        // read selected merge method & calculate new entries
        const updatedEntries = applyChanges(voters, changes, mergeMethods)
        updateVoters(updatedEntries)
      } catch (err) {
        setError(err.message)
      }
    } catch (err) {
      setError(err.message)
    }
    setSelectedFile()
    setChanges()
    onExit()
  }

  const downloadTemplate = () => {
    const templateString = 'externalId;name;email;weight;note'
    saveAs(new Blob([templateString], { type: 'text/csv;charset=utf-8' }), 'VOTEOS-Voter-Registry-Template.csv')
  }

  return (
    <RoundedModal
      open={visible}
      icon={<UploadOutlined />}
      onCancel={handleClose}
      closable={false}
      width={680}
      footer={[
        <Row key='footer'>
          <Col span={12} align='left'>
            <Button
              size='large'
              key='template'
              icon={<DownloadOutlined />}
              type='link'
              onClick={downloadTemplate}
            >{t('voterRegistries.import.fileSelect.suggestTemplate')}
            </Button>
          </Col>
          <Col span={12} align='right'>
            <Button size='large' key='cancel' type='secondary' onClick={handleClose}>{t('cancel')}</Button>
            <Button size='large' key='ok' type='primary' onClick={handleImport} disabled={!mergeMethods.length}>{t('voterRegistries.import.fileSelect.ok')}</Button>
          </Col>
        </Row>

      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Subheadline margin={8}>{t('voterRegistries.import.fileSelect.title')}</Subheadline>
        </Col>
        <Col span={24}>
          <Description>{t('voterRegistries.import.fileSelect.chooseFileLine')}</Description>
        </Col>
        {fileError && (
          <Col span={24}>
            <Alert type='error' message={fileError} />
          </Col>
        )}
        <Col span={24}>
          <Dragger onChange={handleFileChange} accept='.csv' maxCount={1} fileList={selectedFile ? [{ uid: 'csv', name: selectedFile }] : []}>
            <Paragraph type='secondary'>{t('voterRegistries.import.fileSelect.chooseFileButton')}</Paragraph>
          </Dragger>
        </Col>
        {changes &&
          <>
            <Col span={24} />
            <Col span={24} />
            <Col span={24}>

              <Checkbox.Group onChange={values => setMergeMethods(values)}>
                <Row gutter={[16, 4]}>
                  <Col span={24}><Text strong level={4}>{t('voterRegistries.import.mergeChanges.chooseChangesToApply')}</Text></Col>
                  <Col span={24} />
                  <Col span={12}>
                    <Checkbox value='new'>{t('voterRegistries.import.mergeChanges.newEntries')}</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Text mark={changes.new.length > 0}>{changes.new.length}</Text>
                  </Col>
                  <Col span={12}>
                    <Checkbox value='removed'>{t('voterRegistries.import.mergeChanges.removedEntries')}</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Text mark={changes.removed.length > 0}>{changes.removed.length}</Text>
                  </Col>
                  <Col span={12}>
                    <Checkbox value='changed'>{t('voterRegistries.import.mergeChanges.changedEntries')}</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Text mark={changes.changed.length > 0}>{changes.changed.length}</Text>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Col>
          </>}
      </Row>
    </RoundedModal>
  )
}

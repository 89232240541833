import { Button, Space } from 'antd'
import styled from 'styled-components'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import Table from '../../../../common/Table'
import getColumnSearchProps from '../../../../common/TableFilter'
import { useTranslation } from 'react-i18next'

const EditIcon = styled(EditOutlined)`
  color: ${({ theme, $active }) => $active && theme.daybreakBlue};
`

const MemberTable = ({ members, loading, onRemove, onEdit }) => {
  const { t } = useTranslation()

  const getTooltipMessage = ({ stateName, canDeleteMember, isCurrentUser }) => {
    if (canDeleteMember) return ''
    if (stateName === 'pending') {
      return t('members.deleteBlocked.pending')
    }
    if (isCurrentUser) {
      return t('members.deleteBlocked.own')
    }
    return t('members.deleteBlocked.owner')
  }

  const columns = ['email', 'roleName', 'stateName'].map((column) => {
    return {
      title: t('members.' + column),
      dataIndex: column,
      render: (text, record) => {
        if (column === 'roleName') {
          return t('roles.' + text)
        }
        if (column === 'stateName') {
          return t('members.' + text)
        }
        return text
      },
      key: column,
      ...getColumnSearchProps({ dataIndex: column, handleSearch: (keys, confirm) => confirm() }),
      sorter: ({ [column]: propA }, { [column]: propB }) => propA.localeCompare(propB)
    }
  })

  const handleRemove = (record) => {
    onRemove(record)
  }

  const extra = {
    fixed: 'right',
    width: 60,
    align: 'left',
    key: 'extra',
    render: (text, record) => (
      <Space>
        <Button
          type='text'
          icon={<EditIcon $active={!['pending', 'inactive'].includes(record.stateName) && !record.isOwner} />}
          disabled={['pending', 'inactive'].includes(record.stateName) || record.isOwner}
          onClick={() => onEdit(record)}
        />
        <Button
          danger
          type='text'
          icon={<DeleteOutlined />}
          onClick={() => handleRemove(record)}
          disabled={!record.canDeleteMember}
          title={getTooltipMessage(record)}
        />
      </Space>
    )
  }

  return (
    <Table
      bordered
      rowKey='id'
      columns={[...columns, extra]}
      dataSource={members}
      loading={loading}
    />
  )
}

export default MemberTable

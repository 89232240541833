import { Typography, Tag } from 'antd'
import styled, { useTheme } from 'styled-components'
import Icon, { StepForwardOutlined, LockOutlined, StopOutlined, LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const StateTag = styled(Tag)`
  background-color: ${({ background }) => background}}
  border: 0px;
`
const TagText = styled(Text)`
  color: ${({ theme }) => theme.light};
`
const TagIcon = styled(Icon)`
  color: ${({ theme }) => theme.light};
`

const ModerationStatus = ({ pollState, error }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  let color = theme.daybreakBlue
  let icon = LoadingOutlined
  const contrastText = false
  let label = '...'

  if (pollState === 'closed') {
    label = t('election.moderation.closed')
    icon = LockOutlined
  }
  if (pollState === 'open' || pollState === 'last') {
    color = theme.secondaryColor
    icon = StepForwardOutlined
    label = t('election.moderation.open')
  }
  if (pollState === 'ended' || pollState === 'next') {
    color = theme.lightGray
    icon = StopOutlined
    label = t('election.moderation.ended')
  }

  return (
    <StateTag background={color} icon={<TagIcon component={icon} />}>
      <TagText contrast={contrastText ? 1 : 0}>{label}</TagText>
    </StateTag>

  )
}

export default ModerationStatus

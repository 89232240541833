import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Dropdown, Space } from 'antd'
import { DownOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const StyledDropdown = styled(Dropdown.Button)`
  width: 100%;
  min-width: 180px;
  .ant-btn {
    color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor} !important;
    min-width: 16px;
  }
  .ant-btn:first-child {
    width: 100%;
  }
`

const DropdownButton = ({ overlay, open, onOpenChange }) => {
  const { t } = useTranslation()
  const [showDropdown, setShowDropdown] = useState(false)
  useEffect(() => {
    setShowDropdown(open)
  }, [open])

  const handleOpenChange = (value) => {
    onOpenChange(value)
    setShowDropdown(value)
  }
  return (
    <StyledDropdown
      autoFocus
      type='secondary'
      overlay={overlay}
      trigger={['click']}
      onClick={() => handleOpenChange(true)}
      icon={<DownOutlined />}
      open={showDropdown}
      onOpenChange={handleOpenChange}
    >
      <Space>
        <PlusOutlined />
        {t('election.registry.addVoter')}
      </Space>
    </StyledDropdown>
  )
}

export default DropdownButton

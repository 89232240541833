import styled from 'styled-components'
import { Typography, Row, Col } from 'antd'
import Preview from './Preview'
import Buttons from './Buttons'
import Error from '../../../../common/ErrorMessage'
import Loading from '../../../../common/Loading'
import NotFound from '../../../../common/NotFound'
import { Headline } from '../../../../common/Typography'

import { useTranslation } from 'react-i18next'

const TextContainer = styled.div`
  text-align: left;
  padding-top: 64px;
  padding-bottom: 20px;
  width: 100%;
`
const Text = styled(Typography.Text)`
  padding-bottom: 20px;
  font-size: 16px;
`

const Invite = ({ organization, onAccept, onReject, error, accepting, loading }) => {
  const { t } = useTranslation()
  if (loading && !organization) {
    return <Loading description={t('organization.invite.loading')} />
  }

  if (!organization) {
    return <NotFound text={error} title={t('organization.notFound')} />
  }
  return (
    <Row justify='space-around'>
      <Col xs={24}>
        <TextContainer>
          <Headline>{t('organization.invite.invitation')}</Headline>
          <Text type='secondary'>{t('organization.invite.message')}</Text>
        </TextContainer>
        <Preview {...organization} />
        <Buttons {...{ onAccept, onReject, loadingAccept: accepting && loading, loadingReject: !accepting && loading }} />
        <Error error={error} />
      </Col>
    </Row>
  )
}

export default Invite

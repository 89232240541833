import { Route, Switch } from 'wouter'

import Layout from '../Layout'

import OrganizationsRoutes from './Organizations/Routes'
import PublicJoin from './Organizations/Members/Join/Public'
import ElectionsRoutes from './Elections/Routes'
import SignIn from './SignIn'
import Legal from './Legal'
import Terms from './Terms'
import Home from './Home'
import NotFound from '../common/NotFound'

import { useAuth } from '../../hooks/useAuth'

export default function Pages () {
  const { loading: isAuthLoading, signedIn } = useAuth()
  return (
    <Layout signedIn={signedIn}>
      <Switch>
        <Route path='/' component={Home} />
        <Route path='/legal' component={Legal} />
        <Route path='/terms' component={Terms} />
        <Route path='/signin' component={SignIn} />
        {signedIn &&
          <>
            {[...OrganizationsRoutes, ...ElectionsRoutes].map(({ children, path, component, ...props }) => (
              <Route
                key={path}
                path={path}
                component={component || undefined}
                {...props}
              >
                {children}
              </Route>
            ))}
          </>}
        <Route path='/organizations/:id/join/:privateKey' component={PublicJoin} />
        {!isAuthLoading && <Route component={NotFound} />}

      </Switch>
    </Layout>
  )
}

import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'wouter'
import { Result, Grid } from 'antd'
import Icon from '@ant-design/icons'
import Button from '../../../../common/Button'
import VerticalSpace from '../../../../common/VerticalSpace'
import { Text } from '../../../../common/Typography'
import { useTranslation } from 'react-i18next'
import { useOrganization, formatEvent } from '@hpm/voteos-hooks'
import { ReactComponent as Success } from '../../../../../assets/success.svg'
import { ReactComponent as Error } from '../../../../../assets/error.svg'
import { ReactComponent as Sync } from '../../../../../assets/sync.svg'
import BalanceCard from '../BalanceCard'

const { useBreakpoint } = Grid

const LargeIcon = styled(Icon)`
  font-size: 200px !important;
`

const StyledResult = styled(Result)`
.ant-result-icon {
    margin-bottom: 48px;
  }
  .ant-result-title {
    margin-bottom: 24px;
  }
`

const RETRY_TIMEOUT_SECONDS = 5

const Payment = ({ params }) => {
  const { t } = useTranslation()
  const screens = useBreakpoint()
  const { contract, setAddress, refresh } = useOrganization()
  const [loading, setLoading] = useState(true)
  const [returnElection, setReturnElection] = useState()
  const [error, setError] = useState()
  const [paymentId, setPaymentId] = useState()
  const [amountReceived, setAmountReceived] = useState()

  useEffect(() => setAddress(params.address), [params.address, setAddress])

  const loadEvents = useCallback(async () => {
    if (!contract || !paymentId) { return }
    const rawEvents = await contract.fetchEventsFor('UpdateBallotBalance')
    const newEvents = rawEvents.map(formatEvent)
    const foundEvent = newEvents.find((newEvent) => newEvent.values[0] === paymentId)
    if (foundEvent) {
      setAmountReceived(foundEvent.values[1])
      setLoading(false)
      refresh()
    } else {
      console.log(`No events found, retrying in ${RETRY_TIMEOUT_SECONDS} seconds.`)
    }
  }, [contract, paymentId, refresh])

  useEffect(() => {
    if (!amountReceived) {
      loadEvents()
      const timer = setInterval(loadEvents, RETRY_TIMEOUT_SECONDS * 1000)
      return () => clearInterval(timer)
    }
  }, [loadEvents, amountReceived])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('canceled')) {
      setError('canceled')
    }
    if (queryParams.has('votes')) {
      setReturnElection(queryParams.get('votes'))
    }
    const storedPaymentId = window.localStorage.getItem('paymentId')
    if (storedPaymentId) {
      setPaymentId(storedPaymentId)
    }
  }, [params, t])

  const returnPath = returnElection ? `/elections/${returnElection}/votes/` : `/organizations/${params.address}/account/`
  const returnText = t('payment.goBack' + (returnElection ? 'Votes' : 'Account'))
  if (error) {
    return (
      <StyledResult
        status='error'
        icon={<LargeIcon component={Error} />}
        title={t('payment.' + error)}
        extra={
          <Link to={returnPath}><Button type='primary'>{returnText}</Button></Link>
        }
      />
    )
  }

  return (
    <StyledResult
      status='success'
      icon={loading ? <LargeIcon component={Sync} spin /> : <LargeIcon component={Success} />}
      title={t('payment.success')}
      subTitle={
        <VerticalSpace size='large'>
          <Text type='secondary'>{t('payment.transaction.' + (amountReceived ? 'completed' : 'pending'))}</Text>
          {!!amountReceived && (
            <BalanceCard
              ballots={Number(amountReceived)}
              paymentId={paymentId}
            />
          )}
        </VerticalSpace>
      }
      extra={<Link to={returnPath}><Button size='large' disabled={!amountReceived} block={screens.xs} type={loading ? 'secondary' : 'primary'}>{returnText}</Button></Link>}
    />
  )
}

export default Payment

import { useState } from 'react'
import styled from 'styled-components'
import RoundedModal from '../../../../common/RoundedModal'
import { Row, Col, Space, Input, Modal, Form, message } from 'antd'
import { Subheadline, Description } from '../../../../common/Typography'
import VerticalSpace from '../../../../common/VerticalSpace'
import InvitationLink from './InvitationLink'
import RolePicker from '../RolePicker'

import { useTranslation, Trans } from 'react-i18next'
const VOTEOS_ORGANIZATION_ADMIN_ROLE = process.env.REACT_APP_VOTEOS_ORGANIZATION_ADMIN_ROLE

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`
export default function OrganizationMemberInvite ({ address, onInvite, open, onCancel }) {
  const { t } = useTranslation()
  const [inviting, setInviting] = useState(false)

  const [role, setRole] = useState(VOTEOS_ORGANIZATION_ADMIN_ROLE)
  const [email, setEmail] = useState()

  const [link, setLink] = useState()
  const handleInvite = async () => {
    setInviting(true)
    try {
      const { privateKey } = await onInvite({ email }, role)
      setLink(`${window.location.protocol}//${window.location.host}/organizations/${address}/join/${privateKey}`)
    } catch (err) {
      message.error(err.message)
      console.error(err)
    }
    setInviting(false)
  }

  const mailToLink = () => {
    const mailToLink = `mailto:${email}?subject=${encodeURIComponent(t('organization.invite.mailSubject'))}&body=${encodeURIComponent(t('organization.invite.mailBody', { link }))}`
    window.open(mailToLink, '_blank')
    navigator.clipboard.writeText(link)
  }

  const handleCancel = () => {
    setInviting(false)
    setLink()
    setEmail()
    onCancel()
  }
  const handleChangeEmail = e => setEmail(e.target.value)
  const handleReturn = () => {
    Modal.confirm({
      title: t('organization.invite.leave'),
      content: <Trans t={t} i18nKey='organization.invite.leaveMessage' />,
      okText: t('continue'),
      cancelText: t('cancel'),
      onOk: handleCancel
    })
  }
  return (
    <RoundedModal
      open={open}
      onCancel={link || inviting ? handleReturn : handleCancel}
      cancelText={link ? t('leave') : t('cancel')}
      onOk={link ? mailToLink : handleInvite}
      okText={link ? t('organization.invite.mail') : t('invite')}
      disabled={!link && !email && !role}
      loading={inviting}
      closable={false}
      maskClosable={false}
    >
      <Row gutter={0}>
        <Col span={24}>
          <Space align='center'>
            <Subheadline>{t(`organization.invite.${link ? 'link' : 'member'}`)}</Subheadline>
          </Space>
        </Col>
        <Col span={24}>
          {link
            ? <InvitationLink {...{ link, email, address }} onClose={onCancel} />
            : (
              <VerticalSpace size='large'>
                <Description>{t('organization.invite.email')}</Description>
                <Form layout='vertical'>
                  <Form.Item
                    label={t('emailAddress')}
                  >
                    <Input value={email} onChange={handleChangeEmail} placeholder={t('email')} size='large' />
                  </Form.Item>
                  <FormItem
                    label={t('organization.invite.role')}
                  >
                    <RolePicker onSelect={setRole} value={role} />
                  </FormItem>
                </Form>
              </VerticalSpace>
              )}
        </Col>
      </Row>
    </RoundedModal>
  )
}

import { Card } from 'antd'
import styled, { useTheme } from 'styled-components'

const RoundedCard = styled(Card)`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  ${({ clickable }) => clickable && 'cursor: pointer;'}}
`
const ReadOnlyCard = ({ children, ...restProps }) => {
  const theme = useTheme()
  return (
    <RoundedCard bodyStyle={{ backgroundColor: theme.background }} {...restProps}>
      {children}
    </RoundedCard>
  )
}

export default ReadOnlyCard

import { Typography, Tag } from 'antd'
import styled, { useTheme } from 'styled-components'
import { EditOutlined, SyncOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const StateTag = styled(Tag)`
  background-color: ${({ background }) => background}}
  border-radius: ${({ theme }) => theme.borderRadius};
  height: 22px;
  border: 0px;
  display: flex;
  align-items: center;
`

const TagText = styled(Text)`
  color: ${({ theme, contrast }) => contrast ? theme.darkGray : theme.light};
`

const ElectionStatus = ({ state, error }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  let color = 'default'
  let icon
  let contrastText = false
  switch (state) {
    case 'created':
      icon = <EditOutlined style={{ color: theme.light }} />
      color = theme.daybreakBlue
      break
    case 'opened':
      icon = <ClockCircleOutlined style={{ color: theme.darkGray }} />
      color = theme.sunriseYellow
      contrastText = true
      break
    case 'started':
      icon = <SyncOutlined style={{ color: theme.light }} />
      color = theme.secondaryColor
      break
    case 'ended':
    case 'cancelled':
    case 'closed':
      color = theme.lightGray
      icon = <CloseCircleOutlined style={{ color: theme.light }} />
      break
    default:
  }

  return (
    <StateTag background={color} icon={icon}>
      <TagText contrast={contrastText ? 1 : 0}>{t(state)}</TagText>
    </StateTag>

  )
}

export default ElectionStatus

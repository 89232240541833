import { useState, useRef, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Card, Row, Col } from 'antd'
import Button from '../../../../common/Button'
import Progress from '../../../../common/Progress'
import VerticalSpace from '../../../../common/VerticalSpace'
import { Text, Description } from '../../../../common/Typography'
import Question from './Question'
import { WeightIcon } from './Result'
import Tag from './Tag'
import { UnlockOutlined, CloseCircleOutlined, StepForwardOutlined, TeamOutlined, FieldTimeOutlined } from '@ant-design/icons'
import { useElection, useOrganization } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
import calculatePercentage from '../../../../../lib/calculatePercentage'

const RoundedCard = styled(Card)`
  border-radius: ${({ theme }) => theme.borderRadius}}
  border-color: ${({ theme }) => theme.borderColor};
  margin-bottom: 24px;
  ${({ active }) => active && 'box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);'}
  transition: box-shadow 0.3s ease-in-out;
`
const QuestionDescription = styled(Description)`
  color: ${({ theme }) => theme.titleText}}
`
const ButtonContainer = styled(Col)`
  padding-bottom: 16px;
`
const Time = styled(Text)`
  ${({ theme, activecolor }) => activecolor && 'color: ' + theme.primaryColor};
`
const formatDuration = (seconds) => {
  if (seconds < 0) {
    return ''
  }
  const date = new Date(1000 * seconds)
  const durationString = date.toISOString().substring(11, 19).replace('00:', '')
  return durationString
}

const ModerationCard = ({ poll, number, totalPolls, nextPollId, voteCount, totalVoteWeight, ended, activeIndex, isModerated }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const cardRef = useRef(null)
  const { startPollId, stopPoll, showPollId, loading, loadingPolls, setState } = useElection()
  const { refresh: refreshOrganization } = useOrganization()
  const [currentVoteWeight, setCurrentVoteWeight] = useState()
  const [expanded, setExpanded] = useState(poll.active || ended || !isModerated)
  const [isPreparingStart, setIsPreparingStart] = useState(true)
  const [currentTime, setCurrentTime] = useState(new Date())
  const [pollState, setPollState] = useState('closed')

  const scrollTo = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  useEffect(() => {
    if (poll.answers && poll.answers.length) {
      let voteWeight = 0
      poll.answers.forEach(answer => {
        voteWeight += Number(answer.votes)
      })
      setCurrentVoteWeight(voteWeight + poll.abstentionWeight)
    }
    if (poll.active && !poll?.time?.end) {
      setExpanded(true)
      scrollTo(cardRef)
      const interval = setInterval(() => setCurrentTime(Date.now()), 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [poll, ended])

  useEffect(() => {
    if (isModerated) {
      if (poll.isAcceptingVotes && !loading && !loadingPolls) {
        setPollState('open')
      }
      if (!poll.isAcceptingVotes && isPreparingStart && !loading && !loadingPolls) {
        setPollState('closed')
      }
      if (!poll.isAcceptingVotes && (!isPreparingStart || poll.voterCount) && !loading && !loadingPolls) {
        setPollState('next')
      }
      if (!nextPollId && poll.isAcceptingVotes) {
        setPollState('last')
      }
      if (ended && !poll.isAcceptingVotes) {
        setPollState('ended')
      }
    } else {
      setPollState(ended ? 'ended' : 'open')
    }
  }, [poll, isPreparingStart, loadingPolls, loading, ended, nextPollId, isModerated])

  const handleStartPoll = async () => {
    await startPollId(poll.id)
    setIsPreparingStart(false)
  }

  const handleClosePoll = async () => {
    await stopPoll(poll.id)
    setIsPreparingStart(false)
  }

  const handleShowNextPoll = async () => {
    await showPollId(nextPollId || poll.id)
    setIsPreparingStart(true)
  }

  const handleFinishElection = async () => {
    await stopPoll(poll.id)
    await setState('ended')
    refreshOrganization()
  }

  const buttonForPollState = {
    closed: {
      handleAction: handleStartPoll,
      title: t('election.moderation.openAction'),
      icon: <UnlockOutlined />
    },
    open: {
      handleAction: handleClosePoll,
      title: t('election.moderation.closeAction'),
      icon: <CloseCircleOutlined />
    },
    next: {
      handleAction: handleShowNextPoll,
      title: t('election.moderation.nextAction'),
      icon: <StepForwardOutlined />
    },
    last: {
      handleAction: handleFinishElection,
      title: t('election.moderation.finish'),
      icon: <CloseCircleOutlined />
    }
  }

  let time = ''
  if (['next', 'ended'].includes(pollState)) {
    time = poll.time.end || poll.time.duration
  }
  if (poll.isAcceptingVotes && poll.time.start) {
    time = formatDuration(Math.floor(currentTime / 1000) - poll.time.start)
  }

  const votingInfo = (
    <Col span={24}>
      <VerticalSpace>
        {currentVoteWeight > poll.voterCount && <Text><WeightIcon /> {t('election.moderation.weight', { currentVoteWeight, totalVoteWeight: totalVoteWeight * poll.maxVotes })}</Text>}
        {(poll.active || !isModerated) && <Progress
          percent={calculatePercentage(currentVoteWeight, totalVoteWeight * poll.maxVotes)}
          strokeColor={{
            '0%': theme.primaryColor,
            '100%': theme.voteos2
          }}
          status={pollState === 'open' ? 'active' : 'normal'}
                                          />}
        <Text><TeamOutlined /> {t('election.moderation.voterCount', { currentCount: poll.voterCount, totalCount: voteCount })}</Text>
      </VerticalSpace>
    </Col>
  )

  return (
    <RoundedCard ref={cardRef} active={poll.active && isModerated ? 1 : 0}>
      <Row gutter={[0, 24]} align='middle'>
        <Col span={12}>
          {t('election.moderation.questionNumber', { number, total: totalPolls })}
        </Col>
        <Col span={12} align='right'>
          <Time activecolor={poll.active ? 1 : 0}>{!!time && <FieldTimeOutlined />} {time}</Time>
        </Col>
        <Col span={24}>
          <Tag {...poll} pollState={pollState} isModerated={isModerated} />
        </Col>
        <Col span={24}>
          <Question
            poll={poll}
            expanded={expanded}
            onToggleExpand={() => setExpanded(!expanded)}
            state={pollState}
            currentVoteWeight={currentVoteWeight}
            totalVoteWeight={totalVoteWeight}
            votingInfo={votingInfo}
            isModerated={isModerated}
          />
        </Col>
        {!expanded && (poll?.active || pollState === 'ended' || !isModerated) && votingInfo}
        {!expanded && pollState !== 'ended' && poll?.metadata?.description && (
          <Col span={24}>
            <QuestionDescription margin={10} style={{ whiteSpace: 'break-spaces' }}>{poll.metadata.description}</QuestionDescription>
          </Col>
        )}
        {(poll.active || (activeIndex < 0 && number === 1)) && !!buttonForPollState[pollState] && isModerated && (
          <ButtonContainer span={24} align='right'>
            <Button
              size='large'
              type='primary'
              icon={buttonForPollState[pollState].icon}
              onClick={buttonForPollState[pollState].handleAction}
              loading={loading || loadingPolls}
            >
              {buttonForPollState[pollState].title}
            </Button>
          </ButtonContainer>
        )}
      </Row>

    </RoundedCard>
  )
}

export default ModerationCard

import { useState, useEffect, useContext, createContext } from 'react'
import OpenLogin from '@toruslabs/openlogin'
import theme from '../theme/theme.json'

const torusOptions = {
  clientId: process.env.REACT_APP_TORUS_PROJECT_ID,
  network: process.env.REACT_APP_TORUS_NETWORK,
  replaceUrlOnRedirect: false,
  whiteLabel: {
    theme: {
      primary: theme.primaryColor
    }
  }
}

const AuthContext = createContext()

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [signedIn, setSignedIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openlogin, setOpenLogin] = useState();
  const [loginEmail, setLoginEmail] = useState('');
  const [currentOrganizationAddress, setCurrentOrganization] = useState(window.localStorage.getItem('currentOrganizationAddress'))

  const rememberCurrentOrganizationAddress = async (id) => {
    if (!id) {
      window.localStorage.removeItem('currentOrganizationAddress')
    } else {
      window.localStorage.setItem('currentOrganizationAddress', id)
    }
    setCurrentOrganization(id)
  }

  const autoLogin = async function () {
    setLoading(true)

    try {
      const openloginSdk = new OpenLogin(torusOptions)
      setOpenLogin(openloginSdk)

      await openloginSdk.init()
      console.log('autoLogin openlogin.privKey: ', openloginSdk.privKey)

      if (openloginSdk.privKey) {
        window.localStorage.setItem('_lastUser', openloginSdk.privKey)
        setUser({ privKey: openloginSdk.privKey })
      } else if (window.localStorage.getItem('_lastUser')) {
        setUser({ privKey: window.localStorage.getItem('_lastUser') })
      } else {
        setUser()
      }
    } catch (error) {
      console.error('openlogin.init(): ', error)
    }

    setLoading(false)
  }

  const handleLogin = async () => {
    setLoading(true)
    try {
      await openlogin.init()
    } catch (error) {
      console.error('openlogin.init(): ', error)
    }

    try {
      await openlogin.login({
        fastLogin: true,
        loginProvider: 'email_passwordless',// "email_passwordless",
        redirectUrl: `${process.env.PUBLIC_URL}/signin` | 'http://localhost:3000/signin',
        extraLoginOptions: {
          login_hint: loginEmail,
        }
      })
      setUser({
        privKey: openlogin.privKey
      })
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  async function handleLogout () {
    setLoading(true)
    window.localStorage.removeItem('currentOrganizationAddress')
    window.localStorage.removeItem('_lastUser')
    try {
      await openlogin.logout()
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => autoLogin(), [])
  useEffect(() => setSignedIn(!!user?.privKey), [user])

  const value = {
    signedIn,
    user,
    currentOrganizationAddress,
    rememberCurrentOrganizationAddress,
    login: handleLogin,
    loading,
    logout: handleLogout,
    setLoginEmail
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default useAuth

import { useEffect, useState, useCallback } from 'react'
import { Link } from 'wouter'
import { Row, Col, Grid, Space } from 'antd'
import NotFound from '../../../common/NotFound'
import BalanceCard from './BalanceCard'
import { Headline, Subheadline, Description } from '../../../common/Typography'
import Loading from '../../../common/Loading'
import Button from '../../../common/Button'
import CouponModal from './CouponModal'
import Table from './Table'
import { useTranslation } from 'react-i18next'
import { useOrganization } from '@hpm/voteos-hooks'
import SubscriptionInfo from './BuyVotes/SubscriptionInfo'
const { useBreakpoint } = Grid

const BALLOT_EVENTS = ['SetBallotBalance', 'UpdateBallotBalance', 'SetSubscribed']

const AccountOverview = ({ params }) => {
  const { t } = useTranslation()
  const screens = useBreakpoint()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { organization, events, loading, error, setAddress, loadEvents, refresh } = useOrganization()

  useEffect(() => setAddress(params.address), [params.address, setAddress])

  const onRefresh = useCallback(async () => {
    await loadEvents(BALLOT_EVENTS)
    refresh()
  }, [loadEvents, refresh])

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  if (loading && !organization) {
    return <Loading />
  }

  if (!organization && !events) {
    return <NotFound text={error} />
  }

  return (
    <Row gutter={[16, 32]} justify='space-between'>
      <Col span={24}>
        <Headline>{t('organization.account.overview')}</Headline>
        <Description margin={32}>{t('organization.account.titleDescription')}</Description>
        <BalanceCard {...organization} loading={loading} />
      </Col>

      {!organization.isSubscribed && (
        <Col span={24} align='end'>
          <Row justify='space-between' align='middle'>
            <SubscriptionInfo organization={organization} />
            <Space>
              <Button size='large' type='secondary' block={screens.xs} onClick={() => setIsModalOpen(!isModalOpen)}>{t('organization.account.redeemCoupon')}</Button>
              <Link to={`/organizations/${params.address}/votes/buy`}>
                <Button size='large' type='primary' block={screens.xs}>{t('organization.account.buyVotes')}</Button>
              </Link>
            </Space>
          </Row>
        </Col>
      )}
      <Col span={24}>
        <Subheadline level={2}>{t('organization.account.protocol')}</Subheadline>
        <Description margin={24}>{t('organization.account.description')}</Description>
        <Table data={events} loading={loading} />
      </Col>
      <CouponModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        address={params.address}
        onRefresh={onRefresh}
      />
    </Row>
  )
}

export default AccountOverview

import PropTypes from 'prop-types'
import { Space, Col } from 'antd'
import styled from 'styled-components'
import Button from '../../../../common/Button'

import { useTranslation } from 'react-i18next'

const Container = styled(Col)`
  padding-top: 20px;
  width: 100%;
`

const JoinButtons = ({ onAccept, onReject, loadingReject, loadingAccept }) => {
  const { t } = useTranslation()

  return (
    <Container align='right'>
      <Space size={16}>
        <Button size='large' block onClick={onReject} loading={loadingReject} disabled={loadingAccept}>
          {t('organization.invite.reject')}
        </Button>
        <Button size='large' block onClick={onAccept} type='primary' loading={loadingAccept} disabled={loadingReject}>
          {t('organization.invite.accept')}
        </Button>
      </Space>
    </Container>
  )
}
JoinButtons.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default JoinButtons

import { useState, useEffect, useCallback } from 'react'
import { Link } from 'wouter'
import styled, { useTheme } from 'styled-components'
import { Grid, Row, Col, Typography, Spin, Modal as AntModal } from 'antd'
import { FireOutlined, FileDoneOutlined, WarningFilled, ExceptionOutlined, BankOutlined } from '@ant-design/icons'
import Button from '../../../common/Button'
import ErrorMessage from '../../../common/ErrorMessage'
import VerticalSpace from '../../../common/VerticalSpace'
import Spacer from '../../../common/Spacer'
import LabeledValue from '../../../common/LabeledValue'
import Table from './Table'
import Modal from './Modal'
import EmptyCard from '../../../common/EmptyCard'
import mergeVotersWithVotes from './createVoterArrayWithVotes'
import useVoterRegistry from '../../../../hooks/useVoterRegistry'
import { useOrganization, useElection } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
const { useBreakpoint } = Grid
const { Text, Paragraph } = Typography

const Warning = styled(Col)`
  margin-top: -8px;
  padding-bottom: 8px;
`

const ButtonCol = styled(Col)`
  min-width: 300px;
`

const Votes = ({ saving }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const screens = useBreakpoint()
  const { election, listVotes, invalidateVotes, loading: loadingElection } = useElection()
  const { organization, loading: loadingOrganization, setAddress: setOrganizationAddress } = useOrganization()
  const { registryContent, loading: loadingVoters } = useVoterRegistry({ registryIdParam: election?.uriVoterRegistry })
  const [voters, setVoters] = useState()
  const [selectedVoters, setSelectedVoters] = useState([])
  const [generateVotesSelection, setGenerateVotesSelection] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (election && election.organizationAddress) {
      setOrganizationAddress(election.organizationAddress)
    }
  }, [election, setOrganizationAddress])

  const processVoterData = useCallback(async () => {
    if (!election || !organization || !registryContent || !registryContent.voters) { return }
    try {
      setLoading(true)
      setError()
      const votes = await listVotes()
      const votesAndVoters = mergeVotersWithVotes(registryContent.voters, votes)
      setVoters(votesAndVoters)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading()
    }
  }, [election, organization, registryContent, listVotes])

  useEffect(processVoterData, [processVoterData])

  const getVotersWithoutVote = (selection) => {
    if (!voters && !selection) {
      return []
    }
    const votersMissingVote = (selection?.length ? selection : voters).filter((voter) => !voter.hasVote)
    return votersMissingVote
  }

  const onConfirmSelection = (sendEmails) => {
    const voterSelection = getVotersWithoutVote(selectedVoters)
    setGenerateVotesSelection(voterSelection)
    setSelectedVoters([])
  }

  const handleInvalidateVotes = async (keys) => {
    setError()
    setLoading(true)
    try {
      await invalidateVotes(keys)
      setSelectedVoters([])
    } catch (err) {
      setLoading(false)
      setError(`Could not invalidate votes: ${err.message}`)
    }
  }

  const confirmInvalidation = (keys) => {
    const buttonStyle = { style: { borderRadius: theme.borderRadius } }
    AntModal.confirm({
      title: t('election.votes.invalidateVotes', { count: keys.length }),
      content: <Paragraph style={{ whiteSpace: 'break-spaces' }}>{t('election.votes.invalidateConfirm')}</Paragraph>,
      okText: t('yes'),
      cancelText: t('no'),
      okType: 'danger',
      onOk: () => handleInvalidateVotes(keys),
      okButtonProps: buttonStyle,
      cancelButtonProps: buttonStyle
    })
  }

  const confirmInvalidateAll = () => {
    const votersWithVote = voters.reduce((voterIds, { id, hasVote }) => {
      if (hasVote) {
        voterIds.push(id)
      }
      return voterIds
    }, [])
    confirmInvalidation(votersWithVote)
  }

  const selectedKeys = selectedVoters.map(({ id }) => id)
  const invalidateDisabled = !selectedVoters.length || selectedVoters.some(({ hasVote }) => !hasVote)
  const neededVotes = selectedVoters && selectedVoters.length ? selectedVoters.filter((voter) => !voter.hasVote).length : getVotersWithoutVote().length
  const generateDisabled = !organization?.isSubscribed &&
    (!selectedVoters.length || organization.ballots < neededVotes || selectedVoters.some(({ hasVote }) => hasVote))

  const buttons = []

  if (selectedVoters.length > 0) {
    buttons.push(
      {
        title: t('election.votes.generate') + (!generateDisabled ? ` (${selectedVoters.length})` : ''),
        icon: <FileDoneOutlined />,
        onClick: () => setShowModal(true),
        disabled: generateDisabled,
        type: 'primary',
        id: 'generate'
      },
      {
        title: t('election.votes.invalidate') + (!invalidateDisabled ? ` (${selectedVoters.length})` : ''),
        icon: <FireOutlined />,
        onClick: () => confirmInvalidation(selectedKeys),
        danger: true,
        disabled: invalidateDisabled,
        id: 'invalidate'
      })
  } else {
    const missingVotesCount = getVotersWithoutVote().length
    buttons.push(
      {
        title: t('election.votes.generateAll') + (voters && voters.length ? ` (${missingVotesCount}/${voters.length})` : ''),
        onClick: () => setShowModal(true),
        icon: <FileDoneOutlined />,
        id: 'generateAll',
        type: 'primary',
        disabled: loading || !voters || (!organization.isSubscribed && organization.ballots < neededVotes) || !missingVotesCount
      },
      {
        title: t('election.votes.invalidateAll') + (election && parseInt(election.voteCount) ? ` (${election.voteCount})` : ''),
        icon: <FireOutlined />,
        disabled: loading || !voters || !election || !election.voteCount || !parseInt(election.voteCount),
        onClick: confirmInvalidateAll,
        type: 'secondary',
        id: 'invalidateAll'
      })
  }

  const disableEmail = (selectedVoters && selectedVoters.length ? selectedVoters : voters || []).some((voter) => !voter.email)
  const buyVotesUrl = `/organizations/${election?.organizationAddress}/votes/buy?amount=${Number(neededVotes - organization?.ballots)}&electionAddress=${election.address}`

  const handleCloseModal = () => {
    setShowModal(false)
    setGenerateVotesSelection()
  }

  if (!loadingVoters && !loadingElection && !loading && (!voters || !voters.length)) {
    return <EmptyCard title={t('election.votes.votersMissing')} description={t('election.votes.voterMessage')} />
  }
  return (
    <Row gutter={[16, 24]}>
      {!!error && (
        <Col span={24} order={0}>
          <ErrorMessage error={error} />
        </Col>
      )}
      {!organization?.isSubscribed && (
        <Col xs={24} order={1}>
          <Spacer height={8} />
          <VerticalSpace size={8} width='282px'>
            <LabeledValue title={t('election.votes.requiredBallots')} icon={<ExceptionOutlined />}>
              {(neededVotes || neededVotes === 0) && !loading && !loadingVoters ? neededVotes : <Spin size='small' />}
            </LabeledValue>
            <LabeledValue title={t('election.votes.availableBallots')} icon={<BankOutlined />} loading={loadingOrganization || (!organization?.ballots && organization?.ballots !== 0)}>{organization?.ballots}</LabeledValue>
            {neededVotes > organization?.ballots && (
              <>
                <LabeledValue title={t('difference')} icon={<ExceptionOutlined />}>{organization?.ballots - neededVotes}</LabeledValue>
              </>
            )}
          </VerticalSpace>
          <Spacer height={8} />
        </Col>
      )}
      {neededVotes > organization?.ballots && !organization?.isSubscribed && (
        <Warning span={24} order={1}>
          <Text type='warning'><WarningFilled /> {t('election.votes.coverageWarning')}</Text>
        </Warning>
      )}
      {buttons.map(({ id, title, icon, ...restProps }, index) => (
        <ButtonCol xs={24} md={8} lg={6} order={2} key={id}>
          <Button block icon={icon} {...restProps}>{title}</Button>
        </ButtonCol>
      ))}
      {neededVotes > organization?.ballots && !organization?.isSubscribed && (
        <Col span={screens.lg ? null : 24} order={screens.lg ? 2 : 1} flex={screens.lg ? 1 : null} align='right'>
          <Link to={buyVotesUrl}><Button block={!screens.lg} type='primary'>{t('organization.account.buyVotes')}</Button></Link>
        </Col>
      )}
      <Col xs={24} order={3}>
        <Table
          voters={voters}
          loading={loadingElection || loadingVoters || loading || saving}
          selectedKeys={selectedKeys}
          onChangeSelection={setSelectedVoters}
          invalidateVote={(id) => confirmInvalidation([id])}
          generateVote={(voter) => {
            setSelectedVoters([voter])
            setShowModal(true)
          }}
          generateDisabled={!organization?.isSubscribed && organization?.ballots < 1}
        />
      </Col>
      <Modal
        closeable={false}
        visible={showModal}
        onConfirm={onConfirmSelection}
        onClose={handleCloseModal}
        disableEmail={disableEmail}
        generateVotesSelection={generateVotesSelection}
      />
    </Row>
  )
}

export default Votes

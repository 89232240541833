import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

const formatDate = (date = new Date()) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  return date.toLocaleString('de-DE', options) + ' ' + date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
}

const LastChange = ({ date }) => {
  const { t } = useTranslation()
  if (!date) {
    return null
  }
  const formattedDate = formatDate(date)
  return (
    <Text type='secondary' ellipsis title={formattedDate}>{`${t('election.lastChange')} ${formattedDate}`}</Text>
  )
}

export default LastChange

import { List } from 'antd'
import ModerationCard from './ModerationCard'
import { formatTime } from '../../../../../lib/formatDate'

const ModerationAgenda = ({ polls = [], events, voteCount, totalVoteWeight, loading, state, isModerated }) => {
  const activeIndex = polls.findIndex(poll => poll.active)
  polls = polls.map((poll) => {
    const time = {}
    const startEvent = events.find((event) => event.values.pollId === poll.id && event.values.acceptingVotes)
    const endEvent = events.find((event) => event.values.pollId === poll.id && !event.values.acceptingVotes)
    if (startEvent) {
      time.start = startEvent.timestamp
    }
    if (endEvent) {
      time.end = formatTime(endEvent.timestamp * 1000)
    }
    return { ...poll, time }
  })
  return (
    <List
      dataSource={polls}
      loading={loading}
      rowKey='id'
      renderItem={(poll, index) => (
        <ModerationCard
          poll={poll}
          number={index + 1}
          nextPollId={polls[index + 1]?.id}
          ended={activeIndex >= 0 && isModerated ? index < activeIndex : state === 'ended'}
          totalPolls={polls.length}
          voteCount={voteCount}
          totalVoteWeight={totalVoteWeight}
          activeIndex={activeIndex}
          isModerated={isModerated}
        />
      )}
    />
  )
}

export default ModerationAgenda

import styled from 'styled-components'
import { Tooltip, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import roles from '../../../config/membershipRoles'

const { Option } = Select
const MemberSelect = styled(Select)`
  width: 100%;
`
const RolePicker = ({ onSelect, value }) => {
  const { t } = useTranslation()

  return (
    <MemberSelect value={value} onChange={onSelect} size='large'>
      {roles.map(({ id, title }) => (
        <Option value={id} key={id}>
          <Tooltip title={t('roles.descriptions.' + title)} placement='right'>
            {t('roles.' + title)}
          </Tooltip>
        </Option>
      ))}
    </MemberSelect>
  )
}

export default RolePicker

import styled from 'styled-components'
import { Row, Col, Typography, Space, Image, Spin } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import BallotSmall from './BuyVotes/Stimmzettel_S.png'
import BallotMedium from './BuyVotes/Stimmzettel_M.png'
import BallotLarge from './BuyVotes/Stimmzettel_L.png'
import ReadOnlyCard from '../../../common/ReadOnlyCard'
import Button from '../../../common/Button'

const { Text } = Typography

const BallotCount = styled(Text)`
  font-size: 46px;
`
const BallotSpin = styled(Spin)`
  margin-top: 23px;
  margin-bottom: 23px;
`
const Title = styled(Text)`
  font-size: 20px;
  color: ${({ theme }) => theme.titleText}
`
const Label = styled(Text)`
  font-size: 16px;
`

const BallotImage = ({ ballots }) => {
  let image = BallotLarge
  if (ballots <= 500) {
    image = BallotMedium
  }
  if (ballots <= 100) {
    image = BallotSmall
  }
  const size = 90
  return <Image width={size} height={size} src={image} preview={false} />
}

const BalanceCard = ({ ballots, paymentId, isSubscribed, loading }) => {
  const { t } = useTranslation()

  let currentBallots = (
    <>
      <Title>{t('election.currentBallots')}</Title>
      {ballots === undefined || loading ? <BallotSpin /> : <BallotCount>{ballots}</BallotCount>}
      <Text>{t('election.ballots')}</Text>
    </>
  )
  if (isSubscribed) {
    currentBallots = (
      <>
        <Title>{t('organization.account.subscription')}</Title>
        <BallotCount>{t('organization.account.subscriptionActive')}</BallotCount>
        <Button type='link' href={process.env.REACT_APP_STRIPE_PORTAL_URL} target='_blank' style={{ paddingLeft: 0 }}>
          {t('organization.account.subscriptionManage')}
          <SettingOutlined style={{ marginLeft: 4, marginTop: 5 }} />
        </Button>
      </>
    )
  }

  const paymentBallots = (
    <>
      <Title>{t('election.paymentBallots', { count: Number(ballots) })}</Title>
      <Text>{t('election.transaction')}:</Text>
      <Label ellipsis>{paymentId}</Label>
    </>
  )

  return (
    <ReadOnlyCard>
      <Row gutter={[16, 16]} align='middle'>
        <Col flex={1} align='left'>
          <Space direction='vertical'>
            {!paymentId ? currentBallots : paymentBallots}
          </Space>

        </Col>
        <Col xs={24} flex='80px' align='center'>
          <BallotImage ballots={isSubscribed ? 1000 : Number(ballots)} />
        </Col>
      </Row>
    </ReadOnlyCard>
  )
}

export default BalanceCard

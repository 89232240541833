import { useEffect, useState } from 'react'
import { List } from 'antd'
import Item from './Item'
import Sorter from './Sorter'

export default function OrganizationList ({ organizations, orderBy = 'title', loading, onAccept = () => {}, onReject = () => {} }) {
  const [sortedOrganizations, setSortedOrganizations] = useState(organizations)

  useEffect(() => {
    const orderedData = Sorter(organizations, orderBy)
    setSortedOrganizations(orderedData)
  }, [organizations, orderBy])

  const handleAccept = (organization) => () => onAccept(organization)
  const handleReject = (organization) => () => onReject(organization)

  return (
    <List
      split={false}
      itemLayout='vertical'
      dataSource={sortedOrganizations}
      size='large'
      renderItem={organization => (
        <Item {...organization} onAccept={handleAccept(organization)} onReject={handleReject(organization)} />
      )}
      loading={loading}
    />
  )
}

import BigNumber from 'bignumber.js';
import * as rlp from 'rlp';
export class RLP {
    constructor(profile) {
        this.profile = profile;
    }
    /**
     * encode data according to profile
     * @param data the structured data to be encoded
     */
    encode(data) {
        const packed = pack(data, this.profile, '');
        return rlp.encode(packed);
    }
    /**
     * decode buffer according to profile
     * @param buf rlp encoded data
     */
    decode(buf) {
        const packed = rlp.decode(buf);
        return unpack(packed, this.profile, '');
    }
}
(function (RLP) {
    /** base class of scalar kind */
    class ScalarKind {
    }
    RLP.ScalarKind = ScalarKind;
    /** a buffer kind to keep buffer type */
    class BufferKind extends ScalarKind {
        data(data, ctx) {
            assert(Buffer.isBuffer(data), ctx, 'expected buffer');
            return { encode() { return data; } };
        }
        buffer(buf, ctx) {
            return { decode() { return buf; } };
        }
    }
    RLP.BufferKind = BufferKind;
    /** a scalar kind to presents number */
    class NumericKind extends ScalarKind {
        /**
         * create a numeric kind
         * @param maxBytes up limit of data in bytes
         */
        constructor(maxBytes) {
            super();
            this.maxBytes = maxBytes;
        }
        data(data, ctx) {
            assert(typeof data === 'string' || typeof data === 'number', ctx, 'expected string or number');
            if (typeof data === 'string') {
                const isHex = isHexString(data);
                const isDec = isDecString(data);
                assert(isHex || isDec, ctx, 'expected non-negative integer in hex or dec string');
                if (isHex) {
                    assert(data.length > 2, ctx, 'expected valid hex string');
                }
            }
            else {
                assert(Number.isSafeInteger(data) && data >= 0, ctx, 'expected non-negative safe integer');
            }
            const bn = new BigNumber(data);
            if (bn.isZero()) {
                return {
                    encode() {
                        return Buffer.alloc(0);
                    }
                };
            }
            let hex = bn.toString(16);
            if (hex.length % 2 !== 0) {
                hex = '0' + hex;
            }
            assert(this.maxBytes ? hex.length <= this.maxBytes * 2 : true, ctx, `expected number in ${this.maxBytes} bytes`);
            return {
                encode() {
                    return Buffer.from(hex, 'hex');
                }
            };
        }
        buffer(buf, ctx) {
            assert(this.maxBytes ? buf.length <= this.maxBytes : true, ctx, `expected less than ${this.maxBytes} bytes`);
            assert(buf.length === 0 || buf[0] !== 0, ctx, `expected canonical integer (no leading zero bytes)`);
            return {
                decode() {
                    if (buf.length === 0) {
                        return 0;
                    }
                    const bn = new BigNumber(buf.toString('hex'), 16);
                    const num = bn.toNumber();
                    return Number.isSafeInteger(num) ? num : '0x' + bn.toString(16);
                }
            };
        }
    }
    RLP.NumericKind = NumericKind;
    /** a scalar kind to present blob */
    class BlobKind extends ScalarKind {
        data(data, ctx) {
            assert(isHexString(data), ctx, 'expected hex string');
            assert(data.length % 2 === 0, ctx, 'expected even length hex');
            return {
                encode() {
                    return Buffer.from(data.slice(2), 'hex');
                }
            };
        }
        buffer(buf, ctx) {
            return {
                decode() {
                    return '0x' + buf.toString('hex');
                }
            };
        }
    }
    RLP.BlobKind = BlobKind;
    /** fixed length blob */
    class FixedBlobKind extends BlobKind {
        constructor(bytes) {
            super();
            this.bytes = bytes;
        }
        data(data, ctx) {
            const encoder = super.data(data, ctx);
            assert(data.length === this.bytes * 2 + 2, ctx, `expected hex string presents ${this.bytes} bytes`);
            return encoder;
        }
        buffer(buf, ctx) {
            const decoder = super.buffer(buf, ctx);
            assert(buf.length === this.bytes, ctx, `expected ${this.bytes} bytes`);
            return decoder;
        }
    }
    RLP.FixedBlobKind = FixedBlobKind;
    /** fixed length blob allowing null */
    class NullableFixedBlobKind extends FixedBlobKind {
        data(data, ctx) {
            if (!data) {
                return {
                    encode() {
                        return Buffer.alloc(0);
                    }
                };
            }
            return super.data(data, ctx);
        }
        buffer(buf, ctx) {
            if (buf.length === 0) {
                return { decode() { return null; } };
            }
            return super.buffer(buf, ctx);
        }
    }
    RLP.NullableFixedBlobKind = NullableFixedBlobKind;
    /** fixed length blob kind that will remove leading zero on encoding and pad zero on decoding */
    class CompactFixedBlobKind extends FixedBlobKind {
        data(data, ctx) {
            const buf = super.data(data, ctx).encode();
            return {
                encode() {
                    const nzIndex = buf.findIndex(v => v !== 0);
                    if (nzIndex >= 0) {
                        return buf.slice(nzIndex);
                    }
                    return Buffer.alloc(0);
                }
            };
        }
        buffer(buf, ctx) {
            assert(buf.length <= this.bytes, ctx, `expected less than ${this.bytes} bytes`);
            assert(buf.length === 0 || buf[0] !== 0, ctx, `expected no leading zero bytes`);
            const bytes = this.bytes;
            return {
                decode() {
                    const zeros = '0'.repeat((bytes - buf.length) * 2);
                    return '0x' + zeros + buf.toString('hex');
                }
            };
        }
    }
    RLP.CompactFixedBlobKind = CompactFixedBlobKind;
})(RLP || (RLP = {}));
function pack(obj, profile, ctx) {
    ctx = ctx ? ctx + '.' + profile.name : profile.name;
    const kind = profile.kind;
    if (kind instanceof RLP.ScalarKind) {
        return kind.data(obj, ctx).encode();
    }
    if (Array.isArray(kind)) {
        return kind.map(k => pack(obj[k.name], k, ctx));
    }
    assert(Array.isArray(obj), ctx, 'expected array');
    const item = kind.item;
    return obj.map((part, i) => pack(part, { name: '#' + i, kind: item }, ctx));
}
function unpack(packed, profile, ctx) {
    ctx = ctx ? ctx + '.' + profile.name : profile.name;
    const kind = profile.kind;
    if (kind instanceof RLP.ScalarKind) {
        assert(Buffer.isBuffer(packed), ctx, 'expected Buffer');
        return kind.buffer(packed, ctx).decode();
    }
    if (Array.isArray(kind)) {
        assert(Array.isArray(packed), ctx, 'expected array');
        const parts = packed;
        assert(parts.length === kind.length, ctx, `expected ${kind.length} items, but got ${parts.length}`);
        return kind.reduce((o, p, i) => {
            o[p.name] = unpack(parts[i], p, ctx);
            return o;
        }, {});
    }
    assert(Array.isArray(packed), ctx, 'expected array');
    const item = kind.item;
    return packed.map((part, i) => unpack(part, { name: '#' + i, kind: item }, ctx));
}
function assert(cond, ctx, msg) {
    if (!cond) {
        throw new RLPError(`${ctx}: ${msg}`);
    }
}
function isHexString(str) {
    return /^0x[0-9a-f]*$/i.test(str);
}
function isDecString(str) {
    return /^[0-9]+$/.test(str);
}
class RLPError extends Error {
    constructor(msg) {
        super(msg);
        this.name = RLPError.name;
    }
}

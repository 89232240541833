import { Tabs, Row, Col } from 'antd'
import styled from 'styled-components'
import { Text } from '../../../common/Typography'

const LabelItem = styled.div`
  ${({ icon }) => icon && `
    .anticon {
      margin-left: 12px;
    }
  `}}
`

const TabList = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 24px;
  }
`

const ElectionTabs = ({ options = [], collapsed, ...restProps }) => {
  const tabItems = options.map((item) => ({
    label: (
      <LabelItem icon={collapsed} title={item.name}>
        {item.icon}
        {!collapsed && item.name}
      </LabelItem>
    ),
    key: item.id,
    ...item,
    children: (
      <Row gutter={[0, 24]}>
        {!!item.description && (
          <Col span={24}><Text style={{ whiteSpace: 'break-spaces' }}>{item.description}</Text></Col>
        )}
        <Col span={24}>{item.children}</Col>
      </Row>
    )
  }))

  return (
    <TabList
      items={tabItems}
      {...restProps}
    />
  )
}

export default ElectionTabs

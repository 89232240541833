import styled from 'styled-components'
import { Row, Col, Typography, Skeleton } from 'antd'
import ElectionCard from './Card'
import VerticalSpace from '../../../common/VerticalSpace'
import EmptyCard from '../../../common/EmptyCard'
import { useTranslation } from 'react-i18next'

const Title = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`

const StatesList = ({ elections = [], onRemove: handleRemove, loading }) => {
  const { t } = useTranslation()

  if (!elections || !elections.length) {
    return <EmptyCard title={t('organization.empty')} description={t('organization.emptyDescription')} />
  }
  const electionStates = ['started', 'opened', 'created', 'ended']
  const electionLists = electionStates.map((electionState) => {
    const filteredElections = elections.filter(({ state }) => state === electionState)
    return <ListWithHeadline key={electionState} title={t('election.states.' + electionState)} elections={filteredElections} onRemove={handleRemove} loading={loading} />
  })

  return (
    <VerticalSpace size={32}>
      {electionLists}
    </VerticalSpace>
  )
}

function ListWithHeadline ({ title, elections, onRemove: handleRemove, loading }) {
  if (!elections.length) {
    return <></>
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title>{title}</Title>
      </Col>
      {loading && <Skeleton active />}
      {elections.map((election) => {
        return (
          <Col xs={24} lg={elections.length === 1 ? 24 : 12} key={election.address}>
            <ElectionCard {...election} onRemove={handleRemove} />
          </Col>
        )
      })}
    </Row>
  )
}

export default StatesList

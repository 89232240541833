import * as HD from '@vechain/ethers/utils/hdnode';
import { randomBytes } from 'crypto';
import { HDNode } from './hdnode';
export var mnemonic;
(function (mnemonic) {
    /**
     * generate BIP39 mnemonic words
     * @param rng the optional random number generator, which generates 16~32 (step 4) random bytes.
     * Every 4 bytes produce 3 words.
     */
    function generate(rng) {
        rng = rng || (() => randomBytes(128 / 8));
        return HD.entropyToMnemonic(rng()).split(' ');
    }
    mnemonic.generate = generate;
    /**
     * check if the given mnemonic words have valid checksum
     * @param words mnemonic words
     */
    function validate(words) {
        return HD.isValidMnemonic(words.join(' '));
    }
    mnemonic.validate = validate;
    /**
     * derive private key at index 0 from mnemonic words according to BIP32.
     * the derivation path is defined at https://github.com/satoshilabs/slips/blob/master/slip-0044.md
     */
    function derivePrivateKey(words) {
        return HDNode.fromMnemonic(words).derive(0).privateKey;
    }
    mnemonic.derivePrivateKey = derivePrivateKey;
})(mnemonic || (mnemonic = {}));

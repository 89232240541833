import { Space, Typography } from 'antd'
import { PaperClipOutlined as LinkIcon } from '@ant-design/icons'
import styled from 'styled-components'

const Link = styled(Typography.Link)`
  font-size: 16px;
`
const Attachment = ({ name, link }) => (
  <Link key={name} href={link} target='_blank' rel='noreferrer' underline>
    <LinkIcon /> {name}
  </Link>
)

const Attachments = ({ attachments }) => {
  if (!attachments || !attachments.length) return null
  return (
    <Space direction='vertical' size={16}>
      {attachments.map((attachment) => (
        <Attachment {...attachment} key={attachment.name} />
      ))}
    </Space>
  )
}

export default Attachments

import { useEffect } from 'react'
import { Link, useLocation } from 'wouter'
import { Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useOrganizations } from '@hpm/voteos-hooks'
import { useAuth } from '../../../../hooks/useAuth'
import Button from '../../../common/Button'
import Message from '../../../common/Message'
import List from './Entries'
import { useTranslation } from 'react-i18next'

const Error = ({ error }) => {
  if (!error) {
    return <></>
  }

  return (
    <Col span={24}>
      <Message type='error' text={error} />
    </Col>
  )
}

export default function OrganizationList () {
  const { t } = useTranslation()
  const [, setLocation] = useLocation()
  const { organizations, loading, error, refreshOrganizations } = useOrganizations()
  const { currentOrganizationAddress, rememberCurrentOrganizationAddress } = useAuth()

  useEffect(() => {
    refreshOrganizations()
  }, [refreshOrganizations])

  useEffect(() => {
    // automatically open first organization when none selected or current organization is inactive
    if (organizations && organizations.length && (!currentOrganizationAddress || currentOrganizationAddress === 'undefined' || !organizations.some((organization) => organization.address === currentOrganizationAddress))) {
      const firstActiveOrganization = organizations.find((organization) => organization.membershipStateName === 'active')
      if (firstActiveOrganization) {
        rememberCurrentOrganizationAddress(firstActiveOrganization.address)
        setLocation(`/organizations/${firstActiveOrganization.address}/`)
      }
    }
  }, [currentOrganizationAddress, rememberCurrentOrganizationAddress, organizations, setLocation])

  useEffect(() => {
    // create new organization if no organization exists
    if (!loading && organizations && !organizations.some((organization) => organization.membershipStateName === 'active')) {
      setLocation('/organizations/create/default')
    }
  }, [organizations, setLocation, loading])

  const formattedOrganizations = organizations
    .filter(organization => organization.membershipStateName !== 'inactive')
    .map(organization => ({
      ...organization,
      uriView: `/organizations/${organization.address}/`
    }))

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} align='right'>
        <Link to='/organizations/create'>
          <Button type='primary' icon={<PlusOutlined />}>{t('organization.new')}</Button>
        </Link>
      </Col>

      <Error error={t(error)} />

      <Col span={24}>
        <List organizations={formattedOrganizations} loading={loading} />
      </Col>
    </Row>
  )
}

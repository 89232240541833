import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'wouter'
import Invite from './Invite'
import { useAuth } from '../../../../../hooks/useAuth'

import { useOrganization, useOrganizations } from '@hpm/voteos-hooks'

export default function OrganizationJoin ({ params }) {
  const [, setLocation] = useLocation()
  const { logout, rememberCurrentOrganizationAddress } = useAuth()
  const { organizations, refreshOrganizations } = useOrganizations()
  const { loading, organization, setOrganization, acceptMembership, rejectMembership, setAddress } = useOrganization()
  const [error, setError] = useState()
  const [accepting, setAccepting] = useState(false)
  useEffect(() => setAddress(params.address), [params.address, setAddress])

  const handleAccept = useCallback(async () => {
    setError('')
    setAccepting(true)
    try {
      await acceptMembership(params.privateKey)
      await refreshOrganizations()
      setOrganization() // reset organization to force reload in hook
      setAddress()
      setLocation(`/organizations/${params.address}`)
    } catch (err) {
      console.error(err)
      setError(err.message)
    }
  }, [setLocation, acceptMembership, setOrganization, setAddress, params, refreshOrganizations])

  const handleReject = useCallback(async () => {
    setError('')
    try {
      await rejectMembership(params.privateKey)
      await refreshOrganizations()
      if (!organizations.length || (organizations.length === 1 && organizations[0].address === params.address)) {
        rememberCurrentOrganizationAddress(null)
        await logout()
        window.location.replace('/')
      } else {
        setLocation('/organizations')
      }
    } catch (err) {
      setError(err.message)
    }
  }, [setLocation, rejectMembership, params, logout, refreshOrganizations, organizations, rememberCurrentOrganizationAddress])

  return (
    <Invite
      organization={organization}
      onAccept={handleAccept}
      onReject={handleReject}
      loading={loading}
      accepting={accepting}
      error={error}
    />
  )
}

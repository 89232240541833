import { useEffect } from 'react'
import { Link } from 'wouter'
import { Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Loading from '../../../common/Loading'
import NotFound from '../../../common/NotFound'
import Button from '../../../common/Button'
import { Headline, Description } from '../../../common/Typography'
import ElectionStates from './States'

import { useOrganization } from '@hpm/voteos-hooks'
import { useAuth } from '../../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

export default function OrganizationElections ({ params }) {
  const { t } = useTranslation()
  const { currentOrganizationAddress, rememberCurrentOrganizationAddress } = useAuth()
  const { organization, setAddress, removeElection, loading, setOrganization, error: organizationError } = useOrganization()

  useEffect(() => {
    if (params.address !== currentOrganizationAddress) {
      setOrganization()
      rememberCurrentOrganizationAddress(params.address)
    } else if (!loading && organization && !organization.role) {
      rememberCurrentOrganizationAddress(false)
    }
    setAddress(params.address)
  }, [params.address, currentOrganizationAddress, rememberCurrentOrganizationAddress, setAddress, setOrganization, organization, loading])

  if (loading && !organization) {
    return <Loading description={t('organization.loading')} />
  }

  if (!organization) {
    return <NotFound text={t(organizationError)} title={t('organization.notFound')} homeLink='/organizations/list' />
  }

  return (
    <Row gutter={[16, 40]}>
      <Col span={24}>
        <Headline>{t('organization.elections')}</Headline>
        <Description margin={32} ellipsis={{ rows: 3 }} style={{ whiteSpace: 'break-spaces' }}>{t('organization.electionListDescription')}</Description>
        <Link to={`/organizations/${organization.address}/elections/create`}>
          <Button
            size='large'
            type='primary'
            icon={<PlusOutlined />}
            style={{ float: 'right' }}
          >
            {t('election.create')}
          </Button>
        </Link>
      </Col>

      <Col span={24}>
        <ElectionStates elections={organization.elections} onRemove={removeElection} loading={loading && !organization.elections} />
      </Col>
    </Row>
  )
}

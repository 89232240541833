import { useState, useEffect } from 'react'
import { Row, Col, Space } from 'antd'
import Button from '../../../common/Button'
import EmptyCard from '../../../common/EmptyCard'
import SelectModal from '../../Organizations/VoterRegistries/Edit/SelectModal'
import Edit from './Edit'
import { useOrganization, useElection } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'

const Voters = ({ organizationAddress, uriVoterRegistry, voters, voteCount, onChangeVoters, registryTitle, onChangeTitle, onCreateRegistry, creatingNewRegistry, handleExportCsv }) => {
  const { t } = useTranslation()
  const { setVoterRegistryUri } = useElection()
  const { organization, loading: loadingOrganization, setAddress } = useOrganization()
  const [showSelectModal, setShowSelectModal] = useState(false)
  const [selectedVoterRegistryUri, setSelectedVoterRegistryUri] = useState(uriVoterRegistry || (organization?.voterRegistries && organization?.voterRegistries[0] && organization?.voterRegistries[0].id))
  const [loading, setLoading] = useState()

  useEffect(() => setAddress(organizationAddress), [organizationAddress, setAddress])

  const handleSelect = (registry) => {
    setSelectedVoterRegistryUri(registry)
  }

  const handleConfirm = async () => {
    setLoading(true)
    if (selectedVoterRegistryUri !== uriVoterRegistry) {
      await setVoterRegistryUri(selectedVoterRegistryUri)
    }
    setShowSelectModal(false)
    setLoading(false)
  }

  const selectModal = (
    <SelectModal
      visible={showSelectModal}
      onCancel={() => setShowSelectModal(false)}
      onConfirm={handleConfirm}
      loading={loading}
      selectedVoterRegistryUri={selectedVoterRegistryUri}
      onSelectRegistry={handleSelect}
      voterRegistries={organization?.voterRegistries}
    />
  )
  if (!uriVoterRegistry && !creatingNewRegistry) {
    return (
      <Row gutter={[16, 24]}>
        <Col span={24}><EmptyCard title={t('election.registry.missing')} description={t('election.registry.missingHow')} /></Col>
        <Col flex={1} align='right'>
          <Space wrap>
            <Button block loading={loadingOrganization} disabled={!organization || !organization?.voterRegistries?.length} onClick={setShowSelectModal}>{t('election.registry.linkExisting')}</Button>
            <Button block onClick={onCreateRegistry}>{t('election.registry.newRegistry')}</Button>
          </Space>
        </Col>
        {selectModal}
      </Row>
    )
  }

  return (
    <>
      <Edit
        uriVoterRegistry={uriVoterRegistry}
        onSwitchRegistry={() => setShowSelectModal(true)}
        voters={voters}
        voteCount={voteCount}
        onChangeVoters={onChangeVoters}
        handleExportCsv={handleExportCsv}
        title={registryTitle}
        onChangeTitle={onChangeTitle}
        onCreateRegistry={onCreateRegistry}
      />
      {selectModal}
    </>
  )
}

export default Voters

import { Link } from 'wouter'
import styled from 'styled-components'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import PropTypes from 'prop-types'

const Breadcrumb = styled(AntBreadcrumb)`
  .ant-breadcrumb-link a {
    text-decoration: none !important;
  }
`
const Breadcrumbs = ({ breadcrumbs = [] }) => {
  return (
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb) => {
        let item = <span>{breadcrumb.icon} {breadcrumb.title}</span>
        if (breadcrumb.link) {
          item = <Link to={breadcrumb.link}>{breadcrumb.icon} {breadcrumb.title}</Link>
        }
        return (
          <Breadcrumb.Item key={breadcrumb.title}>
            {item}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}
Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string,
    icon: PropTypes.node
  }))
}

export default Breadcrumbs

import { Button } from 'antd'
import styled from 'styled-components'

const DefaultOrSecondaryButton = styled(Button)`
  color: ${({ theme }) => theme.primaryColor};
  border-color: ${({ theme }) => theme.primaryColor} !important;
  &:focus:not(:hover) {
    color: ${({ theme }) => theme.primaryLight};
  }
  &:hover, &:focus-visible {
    color: ${({ theme }) => theme.primaryLight};
  }
  &:active {
    color: ${({ theme }) => theme.primaryDark};
    border-color: ${({ theme }) => theme.primaryDark} !important;
  }
`

const DefaultOrSecondaryDangerButton = styled(Button)`
  color: ${({ theme }) => theme.dustRed};
  border-color: ${({ theme }) => theme.dustRed} !important;
  &:focus:not(:hover) {
    color: ${({ theme }) => theme.dustRed};
  }
  &:hover, &:focus-visible {
    color: ${({ theme }) => theme.dustRedLight};
    border-color: ${({ theme }) => theme.dustRedLight} !important;
  }
  &:active {
    color: ${({ theme }) => theme.dustRedDark};
    border-color: ${({ theme }) => theme.dustRedDark} !important;
  }
`

const PrimaryButton = styled(Button)`
  border-color: ${({ theme }) => theme.primaryColor} !important;
  &:focus:not(:hover) {
    background-color: ${({ theme }) => theme.primaryColor} !important;
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }
  &:hover, &:focus-visible {
    background-color: ${({ theme }) => theme.primaryLight};
    border-color: ${({ theme }) => theme.primaryLight} !important;
  }
  &:active {
    background-color: ${({ theme }) => theme.primaryDark};
    border-color: ${({ theme }) => theme.primaryDark} !important;
  }
`

const PrimaryDangerButton = styled(Button)`
  background-color: ${({ theme }) => theme.dustRed};
  border-color: ${({ theme }) => theme.dustRed} !important;
  &:focus:not(:hover) {
    background-color: ${({ theme }) => theme.dustRed} !important;
    border-color: ${({ theme }) => theme.dustRed} !important;
  }
  &:hover, &:focus-visible {
    background-color: ${({ theme }) => theme.dustRedLight};
    border-color: ${({ theme }) => theme.dustRedLight} !important;
  }
  &:active {
    background-color: ${({ theme }) => theme.dustRedDark};
    border-color: ${({ theme }) => theme.dustRedDark} !important;
  }
`

const ThemedButton = ({ type, disabled, danger, ...restProps }) => {
  if (disabled || ['link', 'text'].includes(type)) {
    return <Button {...{ type, disabled, danger, ...restProps }} />
  }
  if (type === 'primary') {
    if (danger) {
      return <PrimaryDangerButton type={type} danger={danger} {...restProps} />
    }
    return <PrimaryButton type={type} {...restProps} />
  }
  if (danger) {
    return <DefaultOrSecondaryDangerButton type={type} danger={danger} {...restProps} />
  }
  return <DefaultOrSecondaryButton type={type} {...restProps} />
}

export default ThemedButton

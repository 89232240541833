import styled from 'styled-components'
import { Steps, Row, Col, Typography, Modal } from 'antd'
import { CloudUploadOutlined, CaretRightOutlined, StopOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import Button from '../../../../common/Button'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const Title = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`

const Step = styled(Steps.Step)`
  .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: ${({ theme, index, activeindex }) => index >= activeindex ? theme.lightGray : theme.secondaryColor} !important;
  }
  .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: ${({ theme, index, activeindex }) => index > activeindex ? theme.lightGray : theme.secondaryColor} !important;
  }
  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-size: 16px !important;
  }
  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    padding-top: 8px;
  }
`

const Status = ({ status, setState, loading, voteCount = 0, polls = [], hasPendingChanges, isModerated, debug }) => {
  const { t } = useTranslation()
  const statusToSteps = [
    'created',
    'opened',
    'started',
    'ended'
  ]

  const statusChangeIcons = {
    created: <CloudUploadOutlined />,
    opened: <CaretRightOutlined />,
    started: <StopOutlined />
  }

  const handleNextStatus = async () => {
    if (status === 'started') {
      const confirmType = !isModerated ? 'finish' : 'cancel'
      return Modal.confirm({
        title: t(`election.status.${confirmType}`),
        content: t(`election.status.${confirmType}Confirm`),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk: async () => setState(statusToSteps[statusToSteps.indexOf('ended')])
      })
    }
    await setState(statusToSteps[statusToSteps.indexOf(status) + 1])
  }

  const unpublish = async () => setState(statusToSteps[0])

  const pollsInvalid = !polls || !polls.length || polls.some((poll) => !poll.answers || !poll.answers.length)

  const getTooltipMessage = () => {
    if (polls && polls.length && pollsInvalid) {
      return t('election.status.pollsInvalid')
    }
    if (hasPendingChanges) {
      return t('election.status.pendingChanges')
    }
    if (status === 'opened' && !voteCount) {
      return t('election.status.voteCount')
    }
    return ''
  }

  const activeStatusIndex = statusToSteps.indexOf(status)
  return (
    <Row gutter={[64, 24]}>
      <Col span={24}>
        <Title level={3}>{t('status')}</Title>
      </Col>
      <Col span={24}>
        <Steps progressDot size='small' current={activeStatusIndex} direction='vertical'>
          {statusToSteps.map((status, index) => (
            <Step
              key={status}
              title={t('election.status.' + status)}
              description={t('election.status.description.' + status)}
              index={index}
              activeindex={activeStatusIndex}
            />
          ))}
        </Steps>
      </Col>
      {status !== 'ended' && (
        <Col span={24}>
          {['created'].includes(status) && (
            <Button
              block
              type='primary'
              loading={loading}
              onClick={handleNextStatus}
              icon={statusChangeIcons[status]}
              disabled={pollsInvalid || hasPendingChanges}
              title={getTooltipMessage()}
            >
              {t(`election.status.change.${status}`)}
            </Button>
          )}

          {['opened'].includes(status) && (
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Button type='secondary' loading={loading} block icon={<ArrowLeftOutlined />} onClick={unpublish} disabled={loading}>
                  {t('election.status.change.unpublish')}
                </Button>
              </Col>
              <Col span={24}>
                <Button
                  type='primary'
                  loading={loading}
                  block
                  icon={statusChangeIcons[status]}
                  onClick={handleNextStatus}
                  disabled={!voteCount || pollsInvalid || hasPendingChanges}
                  title={getTooltipMessage()}
                >
                  {t(`election.status.change.${status}`)}
                </Button>
              </Col>
            </Row>
          )}

          {['started'].includes(status) && (
            <Button
              block
              type='primary'
              loading={loading}
              icon={statusChangeIcons[status]}
              onClick={handleNextStatus}
            >
              {t(`election.status.change.${status === 'started' && !isModerated ? 'finish' : status}`)}
            </Button>
          )}
          {debug && (
            <Button
              block
              type='secondary'
              loading={loading}
              icon={statusChangeIcons[status]}
              onClick={unpublish}
            >
              Reset Status
            </Button>
          )}
        </Col>
      )}
    </Row>
  )
}

export default Status

import { useContext, createContext, createElement } from 'react'
import { Form } from 'antd'

const EditableContext = createContext(null)

export const useTable = () => {
  const context = useContext(EditableContext)

  if (context === undefined) {
    throw new Error('useTable must be used within a TableProvider')
  }

  return context
}

export const TableProvider = ({ children }) => {
  const [form] = Form.useForm()

  return createElement(EditableContext.Provider, { value: form }, children)
}

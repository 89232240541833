import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Modal, Alert } from 'antd'
import { PlusOutlined as IconAdd, ExclamationCircleOutlined } from '@ant-design/icons'
import Message from '../../../../common/Message'
import Button from '../../../../common/Button'
import { Headline, Description } from '../../../../common/Typography'
import VoterRegistryList from './Table'
import { useOrganization } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'

const Error = ({ error }) => {
  if (!error) {
    return <></>
  }

  return (
    <Col span={24}>
      <Message type='error' text={error} />
    </Col>
  )
}
Error.propTypes = {
  error: PropTypes.string
}

export default function VoterRegistryListing ({ params }) {
  const { t } = useTranslation()
  const { organization, loading, updateMetadata, setAddress } = useOrganization()
  const [error, setError] = useState()
  const [, setLocation] = useLocation()

  useEffect(() => setAddress(params.address), [params.address, setAddress])

  const handleRemove = (voterRegistry) => async (event) => {
    event.stopPropagation()
    Modal.confirm({
      title: t('organization.voterRegistry.remove'),
      icon: <ExclamationCircleOutlined />,
      content: t('organization.voterRegistry.removeConfirm', { title: voterRegistry.title }),
      okText: t('yes'),
      cancelText: t('no'),
      okType: 'danger',
      onOk: async () => {
        setError('')
        try {
          await updateMetadata({
            ...organization.metadata,
            voterRegistries: organization.voterRegistries.filter(elem => elem.id !== voterRegistry.id)
          })
        } catch (err) {
          setError(err.message)
        }
      }
    })
  }

  const handleCreate = async () => {
    setLocation(`/organizations/${params.address}/voterregistries/new`)
  }

  const handleRowClick = (voterRegistry) => () => {
    setLocation(`/organizations/${params.address}/voterregistries/edit/${voterRegistry.id}`)
  }

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Headline>{t('voterRegistries.manage')}</Headline>
        <Description margin={8}>{t('voterRegistries.description')}</Description>
      </Col>
      {organization?.error ? <Col span={24}><Alert type='warning' message={t(organization.error)} /></Col> : null}
      <Col span={12}>
        <Error error={error} />
      </Col>
      <Col span={12} align='right'>
        <Button
          type='primary'
          size='large'
          icon={<IconAdd />}
          onClick={handleCreate}
          disabled={!!organization?.error}
        >
          {t('voterRegistries.createNew')}
        </Button>
      </Col>
      <Col span={24}>
        <VoterRegistryList voterRegistries={organization?.voterRegistries} loading={loading} onRemove={handleRemove} onRowClick={handleRowClick} />
      </Col>
    </Row>
  )
}
VoterRegistryListing.propTypes = {
  params: PropTypes.shape({
    address: PropTypes.string.isRequired
  }).isRequired
}

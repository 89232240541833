import { useState } from 'react'
import styled from 'styled-components'
import { Typography, Row, Col, Spin, Dropdown, Menu, Modal } from 'antd'
import { SwapOutlined, TeamOutlined, PlusOutlined, DeleteOutlined, ExportOutlined } from '@ant-design/icons'
import { ReactComponent as WeightSvg } from '../../../../assets/Weight.svg'
import Input from '../../../common/Input'
import Button from '../../../common/Button'
import LabeledValue from '../../../common/LabeledValue'
import VerticalSpace from '../../../common/VerticalSpace'
import ImportDialog from '../../Organizations/VoterRegistries/Edit/ImportDialog'
import DropdownButton from '../../Organizations/VoterRegistries/Edit/DropdownButton'
import Table from '../../Organizations/VoterRegistries/Edit/Table'
import AddModal from '../../Organizations/VoterRegistries/Edit/AddModal'
import { useTranslation } from 'react-i18next'
import { generateVoter } from '../../../../lib/importExportTools'

const { Text } = Typography

export const WeightIcon = styled(WeightSvg)`
  width: 16px;
  height: 16px;
`

const EditRegistry = ({ title, onChangeTitle, voters, voteCount, onChangeVoters, onSwitchRegistry, onCreateRegistry, handleExportCsv }) => {
  const { t } = useTranslation()
  const [selectedVoters, setSelectedVoters] = useState()
  const [addingVoters, setAddingVoters] = useState(false)
  const [showImportDialog, setShowImportDialog] = useState()
  const [showDropdown, setShowDropdown] = useState(false)
  const [, setError] = useState()
  if (!voters) {
    return <Spin />
  }
  const onMenuClick = ({ key }) => {
    switch (key) {
      case 'newRegistry':
        onCreateRegistry()
        break
      case 'linkOther':
        onSwitchRegistry()
        break
      case 'contacts':
        setAddingVoters(true)
        setShowDropdown(false)
        break
      case 'upload':
        setShowImportDialog(true)
        setShowDropdown(false)
        break
      default:
        console.error(`option ${key} not available`)
    }
  }
  const switchMenu = (
    <Menu
      onClick={onMenuClick}
      items={['linkOther', 'newRegistry'].map((item) => ({ key: item, label: t('election.registry.' + item) }))}
    />
  )
  const voterMenu = (
    <Menu
      onClick={onMenuClick}
      items={['contacts', 'upload'].map((item) => ({ key: item, label: t('election.registry.' + item) }))}
    />
  )

  const handleAddVoter = () => {
    const newVoter = generateVoter({}, voters)
    onChangeVoters([...voters, newVoter])
  }

  const handleAddVoters = (votersData) => {
    const addedVoters = votersData.reduce((voterList, current) => {
      const newVoter = generateVoter(current, voterList)
      return [...voterList, newVoter]
    }, voters)
    onChangeVoters(addedVoters)
  }

  const removeSelectionFromRegistry = () => {
    const filteredVoters = voters.filter((voter) => !selectedVoters.some(({ externalId }) => externalId === voter.externalId))
    onChangeVoters(filteredVoters)
    setSelectedVoters()
  }

  const confirmRemove = () => {
    Modal.confirm({
      title: t('election.registry.remove'),
      content: t('election.registry.removeConfirm'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: removeSelectionFromRegistry
    })
  }

  const weightSum = voters?.reduce((sum, current) => {
    return sum + Number(current.weight)
  }, 0)

  return (
    <Row gutter={[16, 8]}>

      <Col span={24}><Text>{t('election.registry.title')}</Text></Col>
      <Col flex={1}>
        <Input size='large' value={title} bold='true' onChange={(e) => onChangeTitle(e.target.value)} />
      </Col>
      <Col>
        <Dropdown overlay={switchMenu} autoFocus trigger={['click']} disabled={!!voteCount}>
          <Button
            title={voteCount ? t('election.registry.locked') : ''}
            size='large'
            icon={<SwapOutlined />}
          />
        </Dropdown>
      </Col>
      <Col span={24} />
      <Col flex='282px'>
        <VerticalSpace>
          <LabeledValue title={t('election.registry.voters')} icon={<TeamOutlined />}>{voters?.length}</LabeledValue>
          {weightSum && (weightSum > voters?.length) && (
            <LabeledValue title={t('election.votes.weight')} icon={<WeightIcon />}>{weightSum}</LabeledValue>
          )}
        </VerticalSpace>
      </Col>
      <Col span={24} />
      <Col span={24} />

      <Col xs={{ order: 3, span: 24 }} sm={{ order: 3, span: 12 }} xxl={{ order: 1, span: 6 }}>
        <Button block type='primary' icon={<PlusOutlined />} onClick={handleAddVoter}>{t('election.registry.newLine')}</Button>
      </Col>
      <Col xs={{ order: 4, span: 24 }} sm={{ order: 4, span: 12 }} xxl={{ order: 1, span: 6 }}>
        <Button block danger icon={<DeleteOutlined />} onClick={confirmRemove} disabled={!selectedVoters?.length}>
          {t('election.registry.removeVoter', { count: selectedVoters?.length || 0 })}
        </Button>
      </Col>
      <Col xs={{ order: 1, span: 24 }} sm={{ order: 1, span: 12 }} xxl={{ order: 1, span: 5 }}>
        <Button block icon={<ExportOutlined />} onClick={handleExportCsv}>{t('election.registry.export')}</Button>
      </Col>
      <Col xs={{ order: 2, span: 24 }} sm={{ order: 2, span: 12 }} xxl={{ order: 1, span: 7 }}>
        <DropdownButton overlay={voterMenu} open={showDropdown} onOpenChange={setShowDropdown} />
      </Col>

      <Col span={24} order={5} />
      <Col span={24} order={5} />

      <Col span={24} order={5}>
        <Table
          voters={voters}
          onChangeVoters={onChangeVoters}
          selectedKeys={selectedVoters && selectedVoters.map(({ externalId }) => externalId)}
          onChangeSelection={setSelectedVoters}
        />
      </Col>
      <AddModal visible={addingVoters} onClose={setAddingVoters} onAddVoters={handleAddVoters} />
      <ImportDialog
        visible={showImportDialog}
        onExit={() => setShowImportDialog()}
        voters={voters}
        updateVoters={onChangeVoters}
        setError={setError}
      />
    </Row>
  )
}

export default EditRegistry

import { Select } from 'antd'
import RoundedModal from '../../../../common/RoundedModal'
import { Subheadline, Description } from '../../../../common/Typography'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const SelectModal = ({ visible, loading, voterRegistries = [], selectedVoterRegistryUri, onSelectRegistry, onCancel, onConfirm }) => {
  const { t } = useTranslation()
  return (
    <RoundedModal
      open={visible}
      onCancel={onCancel}
      onOk={onConfirm}
      disabled={!selectedVoterRegistryUri}
      loading={loading}
    >
      <Subheadline>{t('election.registry.select.title')}</Subheadline>
      <Description>{t('election.registry.select.description')}</Description>
      <Select
        size='large'
        style={{ width: '100%' }}
        value={selectedVoterRegistryUri}
        placeholder={t('election.registry.select.placeholder')}
        onChange={onSelectRegistry}
      >
        {voterRegistries?.map(registry => <Option key={registry.id} value={registry.id}>{registry.title}</Option>)}
      </Select>
    </RoundedModal>
  )
}

export default SelectModal

import { AbiCoder, formatSignature as _formatSignature } from '@vechain/ethers/utils/abi-coder';
import { keccak256 } from './keccak';
class Coder extends AbiCoder {
    constructor() {
        super((type, value) => {
            if ((type.match(/^u?int/) && !Array.isArray(value) && typeof value !== 'object') ||
                value._ethersType === 'BigNumber') {
                return value.toString();
            }
            return value;
        });
    }
    encode(types, values) {
        try {
            return super.encode(types, values);
        }
        catch (err) {
            if (err.reason) {
                throw new Error(err.reason);
            }
            throw err;
        }
    }
    decode(types, data) {
        try {
            return super.decode(types, data);
        }
        catch (err) {
            if (err.reason) {
                throw new Error(err.reason);
            }
            throw err;
        }
    }
}
const coder = new Coder();
function formatSignature(fragment) {
    try {
        return _formatSignature(fragment)
            .replace(/\(tuple\(/g, '((')
            .replace(/\,tuple\(/g, ',(');
    }
    catch (err) {
        if (err.reason) {
            throw new Error(err.reason);
        }
        throw err;
    }
}
/** encode/decode parameters of contract function call, event log, according to ABI JSON */
export var abi;
(function (abi) {
    /**
     * encode single parameter
     * @param type type of the parameter
     * @param value value of the parameter
     * @returns encoded value in hex string
     */
    function encodeParameter(type, value) {
        return coder.encode([type], [value]);
    }
    abi.encodeParameter = encodeParameter;
    /**
     * decode single parameter
     * @param type type of the parameter
     * @param data encoded parameter in hex string
     * @returns decoded value
     */
    function decodeParameter(type, data) {
        return coder.decode([type], data)[0];
    }
    abi.decodeParameter = decodeParameter;
    /**
     * encode a group of parameters
     * @param types type array
     * @param values value array
     * @returns encoded values in hex string
     */
    function encodeParameters(types, values) {
        return coder.encode(types, values);
    }
    abi.encodeParameters = encodeParameters;
    /**
     * decode a group of parameters
     * @param types type array
     * @param data encoded values in hex string
     * @returns decoded object
     */
    function decodeParameters(types, data) {
        const result = coder.decode(types, data);
        const decoded = {};
        types.forEach((t, i) => {
            decoded[i] = result[i];
            if (t.name) {
                decoded[t.name] = result[i];
            }
        });
        return decoded;
    }
    abi.decodeParameters = decodeParameters;
    /** for contract function */
    class Function {
        /**
         * create a function object
         * @param definition abi definition of the function
         */
        constructor(definition) {
            this.definition = definition;
            this.canonicalName = formatSignature(definition);
            this.signature = '0x' + keccak256(this.canonicalName).slice(0, 4).toString('hex');
        }
        /**
         * encode input parameters into call data
         * @param args arguments for the function
         */
        encode(...args) {
            return this.signature + encodeParameters(this.definition.inputs, args).slice(2);
        }
        /**
         * decode output data
         * @param outputData output data to decode
         */
        decode(outputData) {
            return decodeParameters(this.definition.outputs, outputData);
        }
    }
    abi.Function = Function;
    /** for contract event */
    class Event {
        /** for contract event */
        constructor(definition) {
            this.definition = definition;
            this.canonicalName = formatSignature(definition);
            this.signature = '0x' + keccak256(this.canonicalName).toString('hex');
        }
        /**
         * encode an object of indexed keys into topics.
         * @param indexed an object contains indexed keys
         */
        encode(indexed) {
            const topics = [];
            if (!this.definition.anonymous) {
                topics.push(this.signature);
            }
            for (const input of this.definition.inputs) {
                if (!input.indexed) {
                    continue;
                }
                const value = indexed[input.name];
                if (value === undefined || value === null) {
                    topics.push(null);
                }
                else {
                    let topic;
                    // https://docs.soliditylang.org/en/v0.8.11/abi-spec.html#encoding-of-indexed-event-parameters
                    if (isValueType(input.type)) {
                        topic = encodeParameter(input.type, value);
                    }
                    else {
                        if (input.type === 'string') {
                            topic = '0x' + keccak256(value).toString('hex');
                        }
                        else if (typeof value === 'string' && /^0x[0-9a-f]+$/i.test(value) && value.length % 2 === 0) {
                            // value is encoded
                            topic = '0x' + keccak256(Buffer.from(value.slice(2), 'hex')).toString('hex');
                        }
                        else {
                            throw new Error(`event.encode: invalid ${input.type} value`);
                        }
                    }
                    topics.push(topic);
                }
            }
            return topics;
        }
        /**
         * decode event log
         * @param data data in event output
         * @param topics topics in event
         */
        decode(data, topics) {
            if (!this.definition.anonymous) {
                topics = topics.slice(1);
            }
            if (this.definition.inputs.filter(t => t.indexed).length !== topics.length) {
                throw new Error('invalid topics count');
            }
            const decodedNonIndexed = coder.decode(this.definition.inputs.filter(t => !t.indexed), data);
            const decoded = {};
            this.definition.inputs.forEach((t, i) => {
                if (t.indexed) {
                    const topic = topics.shift();
                    decoded[i] = isValueType(t.type) ? decodeParameter(t.type, topic) : topic;
                }
                else {
                    decoded[i] = decodedNonIndexed.shift();
                }
                if (t.name) {
                    decoded[t.name] = decoded[i];
                }
            });
            return decoded;
        }
    }
    abi.Event = Event;
    function isValueType(type) {
        return type === 'address' || type === 'bool' || /^(u?int)([0-9]*)$/.test(type) || /^bytes([0-9]+)$/.test(type);
    }
})(abi || (abi = {}));

import { Redirect } from 'wouter'

import CreateOrganization from './Create'
import ListOrganization from './List'
import ElectionList from './Elections'
import ElectionCreate from '../Elections/View'
import EditOrganization from './Edit'
import AuditOrganization from './Audit'
import OrganizationAccount from './Account'
import BuyVotes from './Account/BuyVotes'
import Payment from './Account/Payment'
import ListMembers from './Members/List'
import InviteMember from './Members/Invite'
import EditMember from './Members/Edit'
import LeaveOrganization from './Leave'
import JoinOrganization from './Members/Join'
import ListVoterRegistries from './VoterRegistries/List'
import EditVoterRegistry from './VoterRegistries/Edit'

const Routes = [
  { path: '/organizations', component: ListOrganization },
  { path: '/organizations/create/:auto?', component: CreateOrganization },
  { path: '/organizations/list', component: ListOrganization },
  { path: '/organizations/list/:filter', component: ListOrganization },

  { path: '/organizations/:address', children: ({ address }) => <Redirect to={`/organizations/${address}/elections`} /> }, // eslint-disable-line react/prop-types
  { path: '/organizations/:address/edit', component: EditOrganization },

  { path: '/organizations/:address/account', component: OrganizationAccount },
  { path: '/organizations/:address/votes/buy', component: BuyVotes },
  { path: '/organizations/:address/payment', component: Payment },
  { path: '/organizations/:address/logs', component: AuditOrganization },

  { path: '/organizations/:address/members', component: ListMembers },
  { path: '/organizations/:address/invite', component: InviteMember },
  { path: '/organizations/:address/members/:memberId', component: EditMember },
  { path: '/organizations/:address/leave', component: LeaveOrganization },
  { path: '/organizations/:address/join/:privateKey', component: JoinOrganization },

  { path: '/organizations/:address/elections', component: ElectionList },
  { path: '/organizations/:address/elections/create', children: ({ address }) => <ElectionCreate create={address} /> }, // eslint-disable-line react/prop-types

  { path: '/organizations/:address/voterregistries', component: ListVoterRegistries },
  { path: '/organizations/:address/voterregistries/edit/:registryId', component: EditVoterRegistry },
  { path: '/organizations/:address/voterregistries/new', component: EditVoterRegistry }
]

export default Routes

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// Available JSON formatting for translation files: https://www.i18next.com/misc/json-format
import en from './en.json'
import de from './de.json'
import af from './af.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({ // look here for more config options: https://www.i18next.com/overview/configuration-options
    resources: {
      en,
      de,
      af
    },
    fallbackLng: {
      af: ['af'],
      default: ['en']
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['navigator'],
      caches: []
    }
  })

export default i18n

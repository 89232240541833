import { useState } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import getColumnSearchProps from '../../../../common/TableFilter'
import Table from '../../../../common/Table'
import { TableProvider, useTable } from '../../../../../hooks/useTable'
import EditableCell from './TableCell'

const EditableRow = ({ index, ...props }) => {
  const form = useTable()
  return (
    <Form form={form} component={false}>
      <tr {...props} />
    </Form>
  )
}

const VoterTable = ({ voters, onChangeVoters, loading, selectedKeys, onChangeSelection }) => {
  const { t } = useTranslation()
  const [, setSearchInfo] = useState({ searchText: '', searchedColumn: '' })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchInfo({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }
  const handleSave = (row, previousId) => {
    const newData = [...voters]
    const index = newData.findIndex((item) => (previousId || row.externalId) === item.externalId)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    onChangeVoters(newData)
  }

  const columns = [
    {
      title: t('voterRegistries.tableHeaders.externalId'),
      dataIndex: 'externalId',
      width: 140,
      sorter: ({ externalId: propA }, { externalId: propB }) => String(propA).localeCompare(String(propB), undefined, { numeric: true }),
      ...getColumnSearchProps({ dataIndex: 'externalId', handleSearch })
    },
    {
      title: t('name'),
      dataIndex: 'name',
      sorter: ({ name: propA }, { name: propB }) => propA.localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'name', handleSearch })
    },
    {
      title: t('email'),
      dataIndex: 'email',
      sorter: ({ email: propA }, { email: propB }) => propA.localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'email', handleSearch })
    },
    {
      title: t('election.votes.note'),
      dataIndex: 'note',
      sorter: ({ note: propA = '' }, { note: propB = '' }) => propA.localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'note', handleSearch })
    },
    {
      title: t('voterRegistries.tableHeaders.weight'),
      dataIndex: 'weight',
      align: 'right',
      sorter: ({ weight: propA }, { weight: propB }) => propA - propB,
      ...getColumnSearchProps({ dataIndex: 'weight', handleSearch })
    }
  ].map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        externalIds: voters.map(({ externalId }) => externalId),
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    }
  })

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows) {
        onChangeSelection(selectedRows)
      }
    },
    selectedRowKeys: selectedKeys
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }
  return (
    <TableProvider>
      <Table
        loading={loading}
        components={components}
        rowKey={(record) => record.externalId || record.id}
        pagination={false}
        scroll={{ x: 400 }}
        columns={columns}
        dataSource={voters}
        rowSelection={rowSelection}
      />
    </TableProvider>
  )
}

export default VoterTable

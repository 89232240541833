export const getInitials = (title) => {
  if (!title) return ''
  if (title.length <= 3) {
    return title
  }
  const words = title.split(' ')
  const initials = words.map((word) => {
    if (word && word.length) {
      return word[0].toUpperCase()
    }
    return ''
  })
  return initials.join('')
}

export default getInitials

import styled from 'styled-components'
import Linkedin from './img/Linkedin.svg'
import Xing from './img/Xing.svg'
import Instagram from './img/Instagram.svg'
import YouTube from './img/youtube.png'
import Gdpr from './img/Gdpr.png'
import MadeInGermany from './img/MadeInGermany.png'

const MaxMarginDiv = styled.div`
  width: 4vw;
  max-width: 15px;
  display: inline-block;
`

const ScalingImage = styled.img`
  height: auto;
  width: 17vw;
  max-width: 80px;
  max-height: 30px;
`

const ScalingSocials = styled.img`
  height: auto;
  width: 6vw;
  max-width: 30px;
  max-height: 30px;
`

const Row = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
`

const Col = styled.div`
  display: flex;
  width: auto;
`

const YOUTUBE_URL = 'https://www.youtube.com/channel/UCmuaH-htNYqOk_PsSXaxPfQ'
const LINKEDIN_URL = 'https://www.linkedin.com/company/voteos/about/'
const XING_URL = 'https://www.xing.com/pages/voteos'
const INSTAGRAM_URL = 'https://www.instagram.com/voteos.voting/'
const IMPRINT_URL = 'https://voteos.com/Impressum.html'
const HPM_URL = 'https://web.hpm.agency/'

const LegalInfos = (props) => {
  return (
    <Row>
      <Col>
        <a href={IMPRINT_URL} target='_blank' rel='noreferrer'>
          <ScalingImage alt='GDPR' src={Gdpr} />
        </a>
        <MaxMarginDiv />
        <a href={HPM_URL} target='_blank' rel='noreferrer'>
          <ScalingImage alt='Made in Germany' src={MadeInGermany} />
        </a>
      </Col>
      <Col>
        <a href={YOUTUBE_URL} target='__blank' rel='noreferrer'>
          <ScalingSocials alt='YouTube' src={YouTube} />
        </a>
        <MaxMarginDiv />
        <a href={LINKEDIN_URL} target='_blank' rel='noreferrer'>
          <ScalingSocials alt='LinkedIn' src={Linkedin} />
        </a>
        <MaxMarginDiv />
        <a href={XING_URL} target='_blank' rel='noreferrer'>
          <ScalingSocials alt='Xing' src={Xing} />
        </a>
        <MaxMarginDiv />
        <a href={INSTAGRAM_URL} target='_blank' rel='noreferrer'>
          <ScalingSocials alt='Instagram' src={Instagram} />
        </a>
      </Col>
    </Row>
  )
}

export default LegalInfos

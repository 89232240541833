import { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, FireOutlined, FileDoneOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import getColumnSearchProps from '../../../common/TableFilter'
import Table from '../../../common/Table'
const FireIcon = styled(FireOutlined)`
  color: ${({ theme }) => theme.dustRed};
`
const FileIcon = styled(FileDoneOutlined)`
  color: ${({ theme, disabled }) => !disabled && theme.daybreakBlue};
`
const CheckIcon = styled(CheckCircleOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme.lime};
`
const CloseIcon = styled(CloseCircleOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme.dustRed};
`

const VoterTable = ({ voters, loading, selectedKeys, onChangeSelection, generateVote, invalidateVote, generateDisabled }) => {
  const { t } = useTranslation()
  const [, setSearchInfo] = useState({ searchText: '', searchedColumn: '' })
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchInfo({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }
  const columns = [
    {
      title: t('election.votes.status'),
      dataIndex: 'hasVote',
      render: (hasVote) => hasVote ? <CheckIcon /> : <CloseIcon />,
      width: '15%',
      align: 'center',
      filters: [{ text: t('election.votes.hasVote'), value: true }, { text: t('election.votes.noVote'), value: false }],
      onFilter: (value, record) => value === record.hasVote,
      sorter: ({ hasVote: propA }, { hasVote: propB }) => (propA === propB) ? 0 : propA ? -1 : 1
    },
    {
      title: t('voterRegistries.tableHeaders.externalId'),
      dataIndex: 'externalId',
      width: 80,
      sorter: ({ externalId: propA }, { externalId: propB }) => String(propA).localeCompare(String(propB)),
      ...getColumnSearchProps({ dataIndex: 'name', handleSearch })
    },
    {
      title: t('name'),
      dataIndex: 'name',
      render: (text, record) => !record.externalId ? t('election.votes.deletedVoter') : text,
      sorter: ({ name: propA }, { name: propB }) => propA.localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'name', handleSearch })
    },
    {
      title: t('email'),
      dataIndex: 'email',
      sorter: ({ email: propA }, { email: propB }) => propA.localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'email', handleSearch })
    },
    {
      title: t('election.votes.note'),
      dataIndex: 'note',
      sorter: ({ note: propA = '' }, { note: propB = '' }) => propA.localeCompare(propB),
      ...getColumnSearchProps({ dataIndex: 'note', handleSearch })
    },
    {
      title: t('voterRegistries.tableHeaders.weight'),
      dataIndex: 'weight',
      fixed: 'right',
      align: 'right',
      sorter: ({ weight: propA }, { weight: propB }) => propA - propB,
      ...getColumnSearchProps({ dataIndex: 'weight', handleSearch })
    },
    {
      fixed: 'right',
      width: 60,
      key: 'actions',
      align: 'center',
      render: (text, record) => {
        const handleClick = () => {
          if (record.hasVote) {
            invalidateVote(record.id)
          } else {
            generateVote(record)
          }
        }
        return (
          <Button
            type='text'
            onClick={handleClick}
            icon={record.hasVote ? <FireIcon /> : <FileIcon disabled={generateDisabled} />}
            disabled={generateDisabled}
            title={generateDisabled ? t('election.votes.coverageWarning') : ''}
          />
        )
      }
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows) {
        onChangeSelection(selectedRows)
      }
    },
    selectedRowKeys: selectedKeys
  }
  return (
    <Table
      loading={loading}
      rowKey='id'
      pagination={false}
      scroll={{ x: 700 }}
      columns={columns}
      dataSource={voters}
      rowSelection={rowSelection}
    />
  )
}

export default VoterTable

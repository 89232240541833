import Message from './Message'

const ErrorMessage = ({ error = '' }) => {
  if (!error) {
    return <></>
  }

  return <Message type='error' text={error} />
}

export default ErrorMessage

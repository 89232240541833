import { useEffect } from 'react'
import { useLocation } from 'wouter'
import styled from 'styled-components'
import { Row, Col, Alert, Layout, Modal } from 'antd'
import { FileUnknownOutlined, FormatPainterOutlined, SolutionOutlined, FileDoneOutlined, MailOutlined, CalendarOutlined, FieldTimeOutlined } from '@ant-design/icons'
import { Headline } from '../../../common/Typography'
import Button from '../../../common/Button'
import Loading from '../../../common/Loading'
import NotFound from '../../../common/NotFound'
import Breadcrumb from '../../../common/Breadcrumb'
import Version from '../../../common/Version'
import Tabs from './Tabs'
import LastChange from '../../Organizations/Elections/Card/LastChange'
import Sidebar from './Sidebar'
import Agenda from '../Agenda'
import Edit from '../Edit'
import Voters from '../Voters'
import Votes from '../Votes'
import Mail from '../Mail'
import AuditLog from '../AuditLog'
import { useElection, useOrganization } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'
import useSaveState from './hooks/useSaveState'
import { useLayout } from '../../../../hooks/useLayout'
import { useBeforeunload } from 'react-beforeunload'

export const LayoutContainer = styled(Layout)`
  width: 100%;
  background: ${({ theme }) => theme.lightBackground};
  justify-content: center;
`

export const Content = styled(Layout.Content)`
  max-width: 792px;
  width: 100%;
  @media (min-width: 992px) {
    margin-right: ${({ theme }) => theme.sidebarWidth - 30}px;
  }
  @media (min-width: 1440px) {
    max-width: 55%;
    min-width: 792px;
  }
`

const SaveButton = styled(Button)``

export default function Overview ({ params: { address, feature } = {}, create }) {
  const { t } = useTranslation()
  const { screens } = useLayout()

  const [, setLocation] = useLocation()
  const { election, loading, loadingPolls, setAddress, setState, resetDataStates, startAnswerAutoUpdate, refresh } = useElection()
  const { removeElection, refresh: refreshOrganization, setAddress: setOrganizationAddress } = useOrganization()

  const {
    voters,
    polls,
    handleChangePolls,
    changedModerated,
    handleChangeModerated,
    handleSave,
    hasPendingChanges,
    hasCreatedPolls,
    handleUpdateFiles,
    changedFiles,
    handleChangePollOrder,
    setChangedData,
    handleChangeVoters,
    handleExportCsv,
    registryTitle,
    handleChangeRegistryTitle,
    handleCreateRegistry,
    creatingNewRegistry,
    emailTemplate,
    handleChangeTemplate,
    saving,
    creating,
    handleCreate
  } = useSaveState()

  useBeforeunload((event) => {
    if (hasPendingChanges) {
      event.preventDefault()
    }
  })

  useEffect(() => {
    if (address) {
      setAddress(address)
      if (!election) {
        refresh()
      }
    }
    if (create) {
      resetDataStates()
      setOrganizationAddress(create)
    }
  }, [election, address, setAddress, create, setOrganizationAddress, resetDataStates, refresh])

  useEffect(startAnswerAutoUpdate, [startAnswerAutoUpdate])
  useEffect(() => {
    if (election && election.address === address && election.state && ['started', 'closed', 'ended'].includes(election.state)) {
      setLocation(`/elections/${election.address}/moderate`, { replace: true })
    }
  }, [election, setLocation, address])

  const confirmRemove = () => {
    Modal.confirm({
      title: t('election.remove'),
      content: t('election.removeConfirm'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        await removeElection(address)
        await refreshOrganization()
        setLocation(`/organizations/${election?.organizationAddress}/elections`)
      }
    })
  }

  if (loading && !election && !create) {
    return <Loading description={t('election.loading')} />
  }

  if (!loading && !election && !create) {
    return <NotFound title={t('election.notFound')} homeLink='/organizations' />
  }

  const breadcrumbs = [
    {
      title: t('navigation.elections'),
      link: election || create ? `/organizations/${create || election.organizationAddress}/elections` : null,
      icon: <CalendarOutlined />
    },
    { title: t(create ? 'election.create' : 'election.overview') }
  ]

  const { title, metadata, lastChange } = election || {}

  const agenda = (
    <Agenda
      {...election}
      isModerated={changedModerated}
      onChangeModeration={handleChangeModerated}
      loading={loading}
      loadingPolls={loadingPolls}
      polls={polls}
      onChangePolls={handleChangePolls}
      onChangePollOrder={handleChangePollOrder}
      hasCreatedPolls={hasCreatedPolls}
    />
  )

  const edit = (
    <Edit
      {...election}
      onChange={setChangedData}
      onRemove={confirmRemove}
      files={changedFiles || metadata?.attachments}
      onUpdateFiles={handleUpdateFiles}
      create={!!create}
    />
  )

  const voterRegistry = (
    <Voters
      {...election}
      voters={voters}
      registryTitle={registryTitle}
      onChangeTitle={handleChangeRegistryTitle}
      onChangeVoters={handleChangeVoters}
      onCreateRegistry={handleCreateRegistry}
      creatingNewRegistry={creatingNewRegistry}
      handleExportCsv={handleExportCsv}
    />
  )

  const tabOptions = [
    { id: 'questions', icon: <FileUnknownOutlined />, children: agenda },
    { id: 'data', icon: <FormatPainterOutlined />, children: edit },
    { id: 'voters', icon: <SolutionOutlined />, children: voterRegistry },
    { id: 'email', icon: <MailOutlined />, children: <Mail {...emailTemplate} onChange={handleChangeTemplate} /> },
    { id: 'votes', icon: <FileDoneOutlined />, children: <Votes saving={saving} /> },
    { id: 'history', icon: <FieldTimeOutlined />, children: <AuditLog /> }
  ].map((option) => ({ ...option, name: t('election.features.' + option.id), description: t('election.features.description.' + option.id) }))

  const handleTabChange = (activeKey) => {
    if (['voters', 'email'].includes(feature) && hasPendingChanges && !saving) {
      handleSave()
    }
    setLocation(`/elections/${election.address}/${activeKey}`)
  }

  const handleStateChange = async (state) => {
    await setState(state)
    await refreshOrganization()
  }

  return (
    <LayoutContainer>
      <Content>
        <Row gutter={[64, 32]}>
          <Col span={24}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
          </Col>

          {election?.error ? <Col span={24}><Alert type='warning' message={t(election.error)} /></Col> : null}

          <Col span={24}>
            <Row gutter={[0, 40]} justify='space-between'>
              <Col span={24}>
                <Headline margin={32}>{title || t('election.new')}</Headline>
                <Row justify='space-between'>
                  <Col span={20}>
                    <LastChange date={lastChange} />
                  </Col>
                  {!!election?.version && (
                    <Col span={1}>
                      <Version version={election.version} />
                    </Col>
                  )}
                </Row>
              </Col>

              <Col span={24} align='right'>
                <SaveButton
                  type='primary'
                  size='large'
                  onClick={create ? handleCreate : handleSave}
                  disabled={!hasPendingChanges}
                  loading={create ? creating : loading || saving}
                >
                  {t(create ? 'create' : 'save')}
                </SaveButton>
              </Col>
              <Col span={24}>
                <Tabs
                  options={create ? tabOptions.filter(tab => tab.id === 'data') : tabOptions}
                  onChange={handleTabChange}
                  activeKey={feature}
                  collapsed={screens.xs}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24} />
        </Row>
      </Content>
      <Sidebar
        election={election}
        polls={polls}
        voters={voters}
        loading={loading}
        setState={handleStateChange}
        hasPendingChanges={hasPendingChanges}
        handleChangePolls={handleChangePolls}
      />
    </LayoutContainer>
  )
}

import { Progress } from 'antd'
import styled from 'styled-components'

const StyledProgress = styled(Progress)`
  .ant-progress-bg {
    background-color: ${({ theme }) => theme.primaryColor};
  }
`

export default StyledProgress

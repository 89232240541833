import styled from 'styled-components'
import { Layout } from 'antd'
import { ReactComponent as Logo } from './Logo.svg'
import { Link } from 'wouter'

const Header = styled(Layout.Header)`
  position: fixed;
  z-index: 1;
  width: 100%;
  align-items: center;
  background: #fff !important;
  border-bottom: 1px solid #0000000f;
`

const StyledLogo = styled(Logo)`
  margin-top: 16px;
  path { fill: ${({ theme }) => theme.primaryColor}}; };
  cursor: pointer;
`

const LogoHeader = ({ path = '/' }) => {
  return (
    <Header theme='light'>
      <Link to={path}>
        <StyledLogo />
      </Link>
    </Header>
  )
}

export default LogoHeader

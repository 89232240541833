import View from './View'
import Moderation from './Moderation'
import Organizers from './Organizers'

const Routes = [
  { path: '/elections/:address/organizers', component: Organizers },
  { path: '/elections/:address/moderate', component: Moderation },
  { path: '/elections/:address/:feature?', component: View }
]

export default Routes

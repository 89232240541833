import { useState } from 'react'
import { Form, Row, Col } from 'antd'
import Button from '../../../common/Button'
import Input from '../../../common/Input'
import SelectImage from '../../../common/SelectImage'
import { useTranslation } from 'react-i18next'
import { useBeforeunload } from 'react-beforeunload'

const OrganizationForm = ({ onSubmit: handleSubmit, onCancel: handleCancel, id, title: defaultTitle, description: defaultDescription, logo: defaultLogo, loading }) => {
  const { t } = useTranslation()

  const [title, setTitle] = useState(defaultTitle || '')
  const [logo, setLogo] = useState(defaultLogo)

  const handleChangeTitle = (e) => setTitle(e.target.value)
  const handleChangeLogo = (logo) => setLogo(logo)
  const handleButtonSubmit = () => handleSubmit({ id, title, logo })

  const hasChanges = title !== defaultTitle || logo !== defaultLogo

  useBeforeunload((event) => {
    if (hasChanges) {
      event.preventDefault()
    }
  })
  return (
    <>
      <Form layout='vertical'>
        <Form.Item>
          <Row gutter={[16, 16]}>
            <Col xs={24} align='right'>
              <Button
                size='large'
                type='primary'
                loading={loading}
                onClick={handleButtonSubmit}
                disabled={!hasChanges}
              >
                {t('organization.editButton')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label={t('title')}>
          <Input size='large' bold='true' value={title} onChange={handleChangeTitle} onPressEnter={handleButtonSubmit} />
        </Form.Item>
        <Form.Item label={t('logo')} help={t('form.fileSize')}>
          <SelectImage maxWidth={480} maxHeight={480} defaultImage={logo} onSelect={handleChangeLogo} />
        </Form.Item>
      </Form>
    </>
  )
}

export default OrganizationForm

export default function ListSorter (array, property) {
  if (!array || !property) {
    throw new Error('You need to pass both arguments (array & property) to the function')
  }
  if (!Array.isArray(array)) {
    throw new Error('Passed array needs to be of type array')
  }

  const sortedArray = array
  sortedArray.sort(function (a, b) {
    if (a[property] < b[property]) { return -1 }
    if (a[property] > b[property]) { return 1 }
    return 0
  })

  return sortedArray
}

import { useState } from 'react'
import styled from 'styled-components'
import { Link, useRoute } from 'wouter'
import { Menu, Avatar, Divider, Skeleton } from 'antd'
import { PlusOutlined, CalendarOutlined, BankOutlined, ContactsOutlined, TeamOutlined, HistoryOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons'
import { ReactComponent as Logo } from './Logo.svg'
import { useTranslation } from 'react-i18next'
import getInitials from '../../../lib/getInitials'

const icons = {
  calendar: <CalendarOutlined />,
  bank: <BankOutlined />,
  contacts: <ContactsOutlined />,
  team: <TeamOutlined />,
  history: <HistoryOutlined />,
  settings: <SettingOutlined />
}

const TopDivider = styled(Divider)`
  margin-bottom: 16px;
`
const SidebarDivider = styled(Divider)`
  margin-top: 16px;
  margin-bottom: 16px;
`
const StyledMenu = styled(Menu)`
  border-right-width: 0px !important;
  .ant-menu-item, .ant-menu-submenu-title {
    ${({ theme, toplevel }) => toplevel && `background-color: ${theme.lightBackground} !important;`}
    padding-top: ${({ spacing = 24 }) => spacing}px;
    padding-bottom: ${({ spacing = 24 }) => spacing}px;
    ${({ indentation }) => indentation ? `padding-left: ${indentation}px !important;` : null}}
  }
  .ant-menu-item-selected{
    background-color: ${({ theme }) => theme.voteos3} !important;
  }
  .ant-menu-submenu-title {
    ${({ theme, opened }) => opened && `background-color: ${theme.voteos3} !important;`}}
  }
  .ant-menu-item::after {
    border-right: 0px;
  }
  .ant-menu-sub {
    background-color: ${({ theme }) => theme.lightBackground};
  }
  .ant-menu-item-divider {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .ant-menu-item:first-child {
    margin-top: 0px !important;
  }
`
const PlusIcon = styled(PlusOutlined)`
  padding-left: 8px;
`
const StyledLogo = styled(Logo)`
  margin-top: 34px;
  margin-left: 20px;
  path { fill: ${({ theme }) => theme.primaryColor}}; };
  cursor: pointer;
`
const LogoutMenu = styled(Menu)`
  border-right-width: 0px !important;
  text-align: left;
  .ant-menu-item {
    padding-left: 24px;
  }
`

const MenuDivider = styled(Divider)`
  margin-top: 40px !important;
  margin-bottom: 40px;
`
const TitleSkeleton = styled(Skeleton.Button)`
  display: flex;
  align-items: center;
  .ant-skeleton-button {
    min-width: 100px;
  }
`

const DefaultAvatar = styled(Avatar)`
  background-color: ${({ theme, src }) => !src && theme.primaryLight}}
`

const getLink = ({ title, to, address, type = 'organizations' }) => <Link to={to || `/${type}/${address}`}>{title}</Link>
const getLogo = ({ title, logo } = {}) => <DefaultAvatar shape='circle' src={logo}>{getInitials(title)}</DefaultAvatar>

const NavMenu = ({ activeOrganization, organizations = [], handleLogout, handleCollapse, loading }) => {
  const { t } = useTranslation()
  const [, params] = useRoute('/organizations/:address/:function?/:subpage?/:id?')
  const [matchElectionsRoute] = useRoute('/elections/:address/:function?')
  const [switcherOpen, setSwitcherOpen] = useState(false)

  const newOrganization = {
    label: <Link to='/organizations/create'>{t('organization.new')}</Link>,
    key: 'add-organization',
    icon: <PlusIcon />
  }
  const otherOrganizations = organizations
    .filter(organization => organization.address !== activeOrganization?.address && organization.membershipStateName !== 'inactive' && organization.membershipStateName !== 'unknown')
    .map((organization) => ({ label: getLink(organization), key: organization.address, icon: getLogo(organization) }))

  const organizationMenu = [
    {
      label: activeOrganization?.title || <TitleSkeleton active={loading} />,
      icon: getLogo(activeOrganization || {}),
      key: 'currentOrganization',
      children: [
        { label: <MenuDivider />, key: 'top-divider', type: 'divider' },
        ...otherOrganizations,
        newOrganization
      ]
    }
  ]

  const electionFeatures = [
    { title: 'elections', route: 'elections', icon: 'calendar' },
    { title: 'account', route: 'account', icon: 'bank' },
    { title: 'registries', route: 'voterregistries', icon: 'contacts' },
    { title: 'team', route: 'members', icon: 'team' },
    { title: 'history', route: 'logs', icon: 'history' },
    { title: 'settings', route: 'edit', icon: 'settings' }
  ].map((feature) => ({
    label: getLink({ title: t('navigation.' + feature.title), to: `/organizations/${activeOrganization?.address}/${feature.route}` }),
    key: feature.route,
    icon: icons[feature.icon]
  }))

  let activeMenu = []
  if (params && params.function) {
    const route = params.function === 'votes' ? 'account' : params.function
    activeMenu = [route]
  }
  if (matchElectionsRoute) {
    activeMenu = ['elections']
  }

  return (
    <>
      <Link to={`/organizations/${activeOrganization?.address}`}>
        <StyledLogo />
      </Link>
      <TopDivider />
      <StyledMenu
        opened={switcherOpen ? 'true' : ''}
        items={organizationMenu}
        onOpenChange={(openKeys) => setSwitcherOpen(openKeys.includes('currentOrganization'))}
        openKeys={switcherOpen ? ['currentOrganization'] : []}
        selectedKeys={[]}
        onClick={(item, key) => key !== 'currentOrganization' ? setSwitcherOpen(false) : null}
        mode='inline'
        inlineIndent={0}
        indentation={18}
      />
      <SidebarDivider />
      {!!organizations.length && (
        <StyledMenu
          selectedKeys={activeMenu}
          spacing={32}
          items={electionFeatures}
          onClick={handleCollapse}
          mode='inline'
          toplevel='true'
        />
      )}
      <Divider />
      <LogoutMenu items={[{ key: 'logout', icon: <LogoutOutlined />, onClick: handleLogout, label: t('logout') }]} />
      <Divider />
    </>
  )
}

export default NavMenu

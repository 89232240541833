export const formatDate = (dateString) => {
  if (!dateString || dateString === '0') {
    return null
  }
  try {
    dateString = parseInt(dateString, 10)
  } catch (error) {}

  const date = new Date(dateString)

  if (isNaN(date)) {
    return null
  }
  return date.toLocaleDateString()
}

export const formatDateTime = (dateString) => {
  if (!dateString || dateString === '0') {
    return null
  }
  try {
    dateString = parseInt(dateString, 10)
  } catch (error) {}

  const date = new Date(dateString)

  if (isNaN(date)) {
    return null
  }
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}

export const formatTime = (timeString) => {
  if (!timeString || timeString === '0') {
    return null
  }
  try {
    timeString = parseInt(timeString, 10)
  } catch (error) {}

  const date = new Date(timeString)

  if (isNaN(date)) {
    return null
  }
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

export default formatDate

import { Redirect } from 'wouter'
import { useAuth } from '../../../../../hooks/useAuth'
import { HomeContent } from '../../../Home'

const PublicJoin = ({ params }) => {
  const { signedIn, login, loading, setLoginEmail } = useAuth()

  if (signedIn) {
    return <Redirect to={`/organizations/${params.address}/join/${params.privateKey}`} />
  }

  return <HomeContent onLogin={login} invite loading={loading} setLoginEmail={setLoginEmail} />
}

export default PublicJoin

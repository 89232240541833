import styled from 'styled-components'
import { Collapse, List, Row, Col } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import { Text, Description, SecondaryText } from '../../../../common/Typography'
import Result, { InfoCircle } from './Result'
import Attachments from './Attachments'
import Answer from './Answer'

import { useStorage } from '@hpm/voteos-hooks'
import { useTranslation } from 'react-i18next'

const QuestionDescription = styled(Description)`
  color: ${({ theme }) => theme.titleText}}
`
const Panel = styled(Collapse.Panel)`
  .ant-collapse-header {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .ant-collapse-header, .ant-collapse-content-box {
    padding-left: 0px !important;
  }
  .ant-collapse-arrow {
    right: 0px !important;
  }
`

const PanelContent = styled(Row)`
  padding-top: 16px !important;
`

const Headline = styled(Text)`
    font-size: 30px !important;
    line-height: 38px !important;
    color: ${({ theme, active }) => active ? theme.primaryColor : theme.titleText};
`

const Question = ({ poll, state, currentVoteWeight, totalVoteWeight, expanded, onToggleExpand, votingInfo, isModerated }) => {
  const { t } = useTranslation()
  const { getPublicUri } = useStorage()
  const { title, metadata = {}, active, allowAbstention, allowMultiple, minVotes, maxVotes } = poll
  const { attachments, description, answerImages } = metadata
  let answers = poll.answers || []
  if (answerImages) {
    answers = answers.map((answer, index) => {
      const foundUri = answerImages[answer.id || index]
      const image = foundUri ? getPublicUri(foundUri) : ''
      return { ...answer, image }
    })
  }

  return (
    <Collapse
      expandIconPosition='end'
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
      ghost
      activeKey={expanded ? 'question' : null}
      onChange={onToggleExpand}
      title=''
    >
      <Panel header={<Headline active={active && isModerated ? 1 : 0}>{title}</Headline>} key='question'>
        <PanelContent gutter={[16, 40]}>
          {expanded && votingInfo}
          {!!description && (
            <Col span={24}>
              <QuestionDescription margin={0} style={{ whiteSpace: 'break-spaces', marginBottom: 0 }}>{description}</QuestionDescription>
            </Col>
          )}
          {attachments && !!attachments.length && (
            <Col span={24}>
              <Attachments {...{ attachments }} />
            </Col>
          )}
          {['ended', 'next'].includes(state)
            ? (
              <Col span={24}>
                <Result {...poll} currentVoteWeight={currentVoteWeight} totalVoteWeight={totalVoteWeight} />
              </Col>
              )
            : (
              <Col span={24}>
                <List
                  itemLayout='horizontal'
                  dataSource={[...answers, ...(allowAbstention ? [{ title: t('election.poll.abstain'), allowMultiple: false }] : [])]}
                  renderItem={(answer) => <Answer allowMultiple={allowMultiple} {...answer} />}
                />
                <SecondaryText><InfoCircle />{minVotes === maxVotes ? t('election.poll.equalVoteCountDescription', { count: minVotes }) : t('election.poll.voteCountDescription', { min: minVotes, max: maxVotes })}</SecondaryText>
              </Col>
              )}
        </PanelContent>
      </Panel>
    </Collapse>
  )
}

export default Question

import { address } from './address';
import { blake2b256 } from './blake2b';
import { secp256k1 } from './secp256k1';
const fastJsonStableStringify = require('fast-json-stable-stringify');
export var Certificate;
(function (Certificate) {
    function safeToLowerCase(str) {
        return typeof str === 'string' ? str.toLowerCase() : str;
    }
    /**
     * deterministically encode cert into JSON
     * @param cert cert object
     */
    function encode(cert) {
        return fastJsonStableStringify(Object.assign(Object.assign({}, cert), { signer: safeToLowerCase(cert.signer), signature: cert.signature ? safeToLowerCase(cert.signature) : cert.signature }));
    }
    Certificate.encode = encode;
    /**
     * verify the cert
     * @param cert cert object with signature
     */
    function verify(cert) {
        if (!cert.signature) {
            throw new Error('signature missing');
        }
        const signature = cert.signature;
        if (!/^0x[0-9a-f]+$/i.test(signature) || signature.length % 2 !== 0) {
            throw new Error('invalid signature');
        }
        const encoded = encode(Object.assign(Object.assign({}, cert), { signature: undefined }));
        const signingHash = blake2b256(encoded);
        const pubKey = secp256k1.recover(signingHash, Buffer.from(signature.slice(2), 'hex'));
        if (address.fromPublicKey(pubKey) !== safeToLowerCase(cert.signer)) {
            throw new Error('signature does not match with signer');
        }
    }
    Certificate.verify = verify;
})(Certificate || (Certificate = {}));

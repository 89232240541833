import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form, Input, InputNumber, Tooltip } from 'antd'
import { useTable } from '../../../../../hooks/useTable'
import { useTranslation } from 'react-i18next'

const EditableCellValue = styled.div`
  cursor: pointer;
  padding: 5px 12px;
  min-height:32px;
  &:hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`
const EditableCell = ({ record, rules, handleSave, dataIndex, children, context, externalIds, ...restProps }) => {
  const { t } = useTranslation()
  const form = useTable()
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState(record && record[dataIndex])

  useEffect(() => {
    setInputValue(record && record[dataIndex])
  }, [record, dataIndex])

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    })
  }

  const idExists = externalIds && externalIds.some(externalId => String(externalId) === inputValue) && inputValue !== record[dataIndex]

  const save = async () => {
    if (idExists || !inputValue) {
      toggleEdit()
      return
    }
    try {
      const values = await form.validateFields()
      toggleEdit()
      const previousId = dataIndex === 'externalId' ? record.externalId : null
      handleSave({ ...record, ...values, [dataIndex]: inputValue }, previousId)
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  const saveProps = {
    value: inputValue,
    onChange: (e) => setInputValue(e.target.value),
    onPressEnter: save,
    onBlur: save,
    status: dataIndex === 'externalId' && idExists ? 'error' : ''
  }

  if (!dataIndex) {
    return <td style={{ position: 'relative' }} {...restProps}>{children}</td>
  }
  return (
    <td style={{ position: 'relative' }} {...restProps}>
      {editing
        ? (

          <Form.Item
            validateTrigger='onSubmit'
            name={dataIndex}
            style={{ margin: 0 }}
            rules={rules}
          >
            {dataIndex === 'weight'
              ? <InputNumber min={1} ref={inputRef} {...saveProps} onChange={setInputValue} />
              : (
                <Tooltip title={t('election.registry.uniqueId')} open={idExists} placement='right'>
                  <Input ref={inputRef} {...saveProps} />
                </Tooltip>
                )}
          </Form.Item>
          )
        : (
          <EditableCellValue
            onClick={toggleEdit}
          >
            {children}
          </EditableCellValue>
          )}
    </td>
  )
}
EditableCell.propTypes = {
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  editing: PropTypes.bool,
  handleSave: PropTypes.func,
  record: PropTypes.shape({ id: PropTypes.string }),
  rules: PropTypes.array
}

export default EditableCell

import { Menu, Typography } from 'antd'
import { Link } from 'wouter'
import PropTypes from 'prop-types'

const ContextMenu = ({ items = [] }) => (
  <Menu selectable={false}>
    {items.map(({ title, icon, uri, onClick, type }) => {
      const text = <Typography.Link type={type}>{title}</Typography.Link>
      return (
        <Menu.Item key={title} icon={icon} onClick={onClick}>
          {uri && <Link to={uri}>{text}</Link>}
          {!uri && text}
        </Menu.Item>
      )
    })}

  </Menu>
)
ContextMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.node,
    uri: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string
  }))
}
ContextMenu.defaultProps = {
  items: []
}

export default ContextMenu

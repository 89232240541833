import { useState, useEffect } from 'react'
import { Input, Typography, Tooltip, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { CopyOutlined, WarningOutlined } from '@ant-design/icons'
import Button from '../../../../common/Button'
import VerticalSpace from '../../../../common/VerticalSpace'

const { Text } = Typography
const InvitationLink = ({ email, link, onClose }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
  }, [copied])

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
    setCopied(true)
  }
  const copyButton = (
    <Tooltip title={t(copied ? 'copied' : 'copy')}>
      <Button
        block
        type='text'
        icon={<CopyOutlined />}
        onClick={handleCopy}
      />
    </Tooltip>
  )
  return (
    <VerticalSpace size='large'>
      <Text>{t('organization.invite.linkMessage')}</Text>
      <Form layout='vertical'>
        <Form.Item label={t('organization.invite.link')} style={{ marginBottom: '0px' }}>
          <Input value={link} suffix={copyButton} />
        </Form.Item>
      </Form>
      <Text type='warning'><WarningOutlined /> {t('organization.invite.linkWarning')}</Text>
    </VerticalSpace>
  )
}

export default InvitationLink

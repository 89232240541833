import { blake2b256 } from './blake2b';
/**
 * 2048 bits Bloom filter
 */
export class Bloom {
    /**
     * new bloom filter instance
     * @param k number of hash functions
     * @param bits leave it out to construct an empty one
     */
    constructor(k, bits) {
        if (bits) {
            this.bits = bits;
        }
        else {
            this.bits = Buffer.alloc(Bloom.BITS_LENGTH / 8);
        }
        this.k = k;
    }
    /** estimate k(number of hash functions) according to item count */
    static estimateK(itemCount) {
        const k = Math.round(this.BITS_LENGTH / itemCount * Math.LN2);
        return Math.max(Math.min(k, this.MAX_K), 1);
    }
    /**
     * add item
     * @param item
     */
    add(item) {
        this.distribute(item, (index, bit) => {
            // tslint:disable-next-line:no-bitwise
            this.bits[index] |= bit;
            return true;
        });
    }
    /**
     * test if an item contained. (false positive)
     * @param item
     */
    test(item) {
        return this.distribute(item, (index, bit) => {
            // tslint:disable-next-line:no-bitwise
            return (this.bits[index] & bit) === bit;
        });
    }
    distribute(item, cb) {
        const hash = blake2b256(item);
        for (let i = 0; i < this.k; i++) {
            // tslint:disable-next-line:no-bitwise
            const d = (hash[i * 2 + 1] + (hash[i * 2] << 8)) % Bloom.BITS_LENGTH;
            // tslint:disable-next-line:no-bitwise
            const bit = 1 << (d % 8);
            if (!cb(Math.floor(d / 8), bit)) {
                return false;
            }
        }
        return true;
    }
}
/** number of hash functions */
Bloom.MAX_K = 16;
/** bit length */
Bloom.BITS_LENGTH = 2048;

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { List, Row, Col } from 'antd'
import { DeleteOutlined as IconDelete, HolderOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import MoodImage from './MoodImage'
import Input from '../../../../../common/Input'
import Button from '../../../../../common/Button'

const DeleteButton = styled(Button)`
  opacity: ${({ onClick }) => onClick ? 1 : 0};
  width: 20px;
`
const DragHandle = styled(HolderOutlined)`
  opacity: ${({ draggable }) => draggable ? 1 : 0};
`
const ListRow = styled(Row)`
  flex: 1;
`

const Answer = React.forwardRef(({
  title = '',
  image,
  uri,
  onDelete,
  onFocus,
  onChangeText,
  onChangeImage,
  onPressEnter,
  allowMultiple,
  editing,
  disabled,
  draggable
}, ref) => {
  const { t } = useTranslation()

  const handleTextChange = (event) => {
    if (!editing || !event || !event.target) {
      return
    }
    onChangeText(event.target.value)
  }

  return (
    <List.Item>
      <ListRow gutter={[8, 0]} align='middle' wrap={false}>
        <Col>
          {editing && <MoodImage image={image} uri={uri} onChange={onChangeImage} onRemove={onChangeImage} editing={editing} disabled={disabled} />}
        </Col>
        <Col flex={1}>
          <Input
            style={{ fontSize: 16 }}
            bordered={!!editing}
            disabled={disabled}
            value={title}
            placeholder={t('election.agenda.enterAnswer')}
            onChange={handleTextChange}
            suffix={<DeleteButton
              danger
              type='link'
              onClick={onDelete}
              icon={<IconDelete />}
                    />}
            {...{ ref, onPressEnter, onFocus }}
          />
        </Col>
        <DragHandle draggable={draggable} />
      </ListRow>
    </List.Item>
  )
})

Answer.propTypes = {
  text: PropTypes.string,
  editing: PropTypes.bool,
  onDelete: PropTypes.func,
  onChangeText: PropTypes.func,
  onPressEnter: PropTypes.func
}

export default Answer

import { Typography } from 'antd'
import styled from 'styled-components'
const { Title } = Typography

const SHRINK_TEXT_WIDTH = 576
const Headline = styled(Title)`
  font-size: 46px !important;
  line-height: 56px !important;
  font-weight: 400 !important;
  margin-bottom: ${({ margin = 48 }) => margin}px !important;
  @media (max-width: ${SHRINK_TEXT_WIDTH}px) {
    font-size: 30px !important;
    line-height: 38px !important;
  }
`

const Subheadline = styled(Title)`
  font-size: 30px !important;
  font-weight: 400 !important;
  line-height: 38px !important;
  margin-bottom: ${({ margin = 24 }) => margin}px !important;
  @media (max-width: ${SHRINK_TEXT_WIDTH}px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
`

const Text = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: ${SHRINK_TEXT_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`

const Paragraph = styled(Typography.Paragraph)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: ${SHRINK_TEXT_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`

const TitleText = styled(Typography.Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`

const SecondaryText = styled(Typography.Text).attrs({ type: 'secondary' })`
  font-size: 14px !important;
`

const Description = styled(Paragraph)`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${({ margin = 16 }) => margin}px !important;
  color: ${({ theme }) => theme.darkGray}}
`

export { Headline, Subheadline, Title, Text, SecondaryText, Paragraph, Description, TitleText }

export default Typography

import styled, { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { Tag, Image, Row, Col } from 'antd'
import { Text } from '../../../../common/Typography'
import { useTranslation } from 'react-i18next'
import BallotSmall from './Stimmzettel_S.png'
import BallotMedium from './Stimmzettel_M.png'
import BallotLarge from './Stimmzettel_L.png'

const TopTitle = styled(Text)`
  font-size: 16px;
`

const DiscountTag = styled(Tag)`
  border-radius: 10px;
`

const PriceCard = ({ amount, price, discount, currency, size, active, hasNoUpperLimit, onClick, ...restProps }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const getImage = () => {
    switch (size) {
      case 'small':
        return BallotSmall
      case 'medium':
        return BallotMedium
      case 'large':
        return BallotLarge
      default:
        return BallotLarge
    }
  }
  return (
    <Row align='middle' justify='center' gutter={16} wrap={false}>
      <Col flex='72px'>
        <Image src={getImage()} preview={false} onClick={onClick} />
      </Col>
      <Col flex={1}>
        <TopTitle level={3} $active={active}>{t(`votes.${size}Event`)} {discount && <DiscountTag color={theme.secondaryColor}>{discount}</DiscountTag>}</TopTitle>
        <br />
        <Text type='secondary'>{t('votes.' + (hasNoUpperLimit ? 'after' : 'upTo'))} {amount} {t('election.ballots')}</Text>
      </Col>
      <Col flex='150px' align='right'>
        <Text strong $active={active}>{price.toFixed(2)} {currency}</Text><br />
        <Text $active={active} type='secondary'>{t('votes.pricePerVote')}</Text>
      </Col>
    </Row>
  )
}
PriceCard.propTypes = {
  amount: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  active: PropTypes.bool,
  hasNoUpperLimit: PropTypes.bool
}
PriceCard.defaultProps = {
  currency: '€',
  active: false,
  hasNoUpperLimit: false
}

export default PriceCard

import { useState, useEffect, useRef } from 'react'
import { Subheadline, Description, SecondaryText } from '../../../../common/Typography'
import RoundedModal from '../../../../common/RoundedModal'
import Input from '../../../../common/Input'
import { useTranslation } from 'react-i18next'

const AddModal = ({ visible, loading, onClose, onAddVoters }) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const [value, setValue] = useState()

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 200)
    }
  }, [visible])

  const handleConfirm = () => {
    const voters = value.split(',')
    const formattedVoters = voters.map((voter) => {
      const nameAndEmail = /([\s\S]*)<(\w*@\w*.\w*)>/
      if (nameAndEmail.test(voter)) {
        const [, name, email] = voter.match(nameAndEmail)
        return { name: name.trim(), email }
      }
      if (voter.includes('@')) {
        return { email: voter.trim() }
      }
      return { name: voter.trim() }
    })
    onAddVoters(formattedVoters)
    setValue('')
    onClose()
  }

  const handleCancel = () => {
    setValue('')
    onClose()
  }

  return (
    <RoundedModal
      open={visible}
      onCancel={handleCancel}
      onOk={handleConfirm}
      disabled={!value}
      loading={loading}
    >
      <Subheadline>{t('election.registry.contacts')}</Subheadline>
      <Description margin={24}>{t('election.registry.contactsDescription')}</Description>
      <Input
        size='large'
        ref={inputRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t('election.registry.placeholder')}
        onPressEnter={handleConfirm}
      />
      <SecondaryText>{t('election.registry.example')}</SecondaryText>
    </RoundedModal>
  )
}

export default AddModal

export default function createVoterArrayWithVotes (voterList, voteList) {
  if (!voterList || !voteList) {
    return []
  }
  const newVoterList = [...voterList]
  newVoterList.map(voter => (voter.hasVote = false))

  for (let i = 0, l = voteList.length; i < l; i++) {
    const vote = voteList[i]
    const voter = voterList.find(voter => voter.id === vote.voterId)

    if (voter) {
      newVoterList[newVoterList.indexOf(voter)] = {
        ...voter,
        ...vote,
        hasVote: true,
        id: vote.voterId
      }
    } else {
      newVoterList.push(
        {
          ...vote,
          id: vote.voterId,
          hasVote: true
        }
      )
    }
  }

  return newVoterList
}

import { useState } from 'react'
import styled from 'styled-components'
import { Input, Typography, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useWallet } from '../../../../../hooks/useWallet'
import RoundedModal from '../../../../common/RoundedModal'
import { Subheadline, Description } from '../../../../common/Typography'

const COUPON_ENDPOINT = process.env.REACT_APP_COUPON_ENDPOINT

const Spacer = styled.div`
  height: ${({ height }) => height || 22}px;
`

const CouponModal = ({ open, address, onClose, onRefresh, ...restProps }) => {
  const { t } = useTranslation()
  const { session } = useWallet()
  const [couponCode, setCouponCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleRedemption = async () => {
    setError(false)
    setLoading(true)
    try {
      const paymentData = {
        code: couponCode,
        organizationAddress: address
      }
      const res = await window.fetch(COUPON_ENDPOINT, {
        method: 'POST',
        headers: {
          'X-Contract-Address': address,
          Authorization: `Bearer ${session}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(paymentData)
      })
      const { amount } = await res.json()

      if (res.status === 400) {
        setError(true)
      } else {
        message.success(t('organization.account.couponRedeemed', { count: amount }))
        setCouponCode('')
        await onRefresh()
        onClose()
      }
    } catch (err) {
      message.error(err.message)
    }
    setLoading(false)
  }

  const handleCancel = () => {
    setCouponCode('')
    setError(false)
    onClose()
  }

  return (
    <RoundedModal
      open={open}
      width={500}
      onCancel={handleCancel}
      disabled={couponCode.length < 12}
      loading={loading}
      okText={t('organization.account.redeem')}
      onConfirm={handleRedemption}
      {...restProps}
    >
      <Subheadline>{t('organization.account.redeemCoupon')}</Subheadline>
      <Description margin={24}>
        {t('organization.account.couponCodeDescription')}
      </Description>
      <Input
        placeholder={t('organization.account.couponCodeInput')}
        value={couponCode}
        size='large'
        onChange={e => setCouponCode(e.target.value)}
        disabled={loading}
        status={error ? 'error' : ''}
        onPressEnter={handleRedemption}
      />
      {error ? <Typography.Text type='danger'>{t('organization.account.couponCodeError')}</Typography.Text> : <Spacer />}
    </RoundedModal>
  )
}

export default CouponModal

import styled, { useTheme } from 'styled-components'
import { Layout } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { useLayout } from '../../../../../hooks/useLayout'

const Sider = styled(Layout.Sider)`
  background: transparent;
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0;
  z-index: 2;
  .ant-layout-sider-zero-width-trigger-right {
    color: ${({ theme }) => theme.primaryColor};
    top: -3px;
    right: -21px !important;
    clip-path: inset(0px 0px -30px -30px);
    box-shadow: ${({ theme }) => theme.fancyShadow};
    background-color: ${({ theme }) => theme.lightBackground};
    border-radius: ${({ theme }) => theme.borderRadius} !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  @media only screen and (max-width: 991.99px) {
    background: white !important;
    box-shadow: ${({ theme }) => theme.fancyShadow};
  }
  .ant-layout-sider-children {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .ant-layout-sider-children::-webkit-scrollbar {
    display: none;
  }
`

const SiderBar = ({ children }) => {
  const theme = useTheme()
  const { isSidebarCollapsed, toggleSidebar } = useLayout()
  return (
    <Sider
      width={theme.sidebarWidth}
      collapsedWidth={0}
      breakpoint='lg'
      reverseArrow
      collapsed={isSidebarCollapsed}
      trigger={isSidebarCollapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
      onCollapse={toggleSidebar}
    >
      {children}
    </Sider>
  )
}
export default SiderBar

import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import Connectivity from './Connectivity'
import LegalInfos from './LegalInfos'
import Version from './Version'
import Logo from './img/Logo.svg'
import { Link } from 'wouter'
import { useTranslation } from 'react-i18next'

const { Footer: AntDFooter } = Layout

const VoeFooter = styled(AntDFooter)`
  background: ${({ theme }) => theme.primaryColor};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px;
`

const StyledAnchor = styled.a`
  font-size: 16px;
  color: #fff;
  text-decoration: underline;
  &:active, &:hover, &:focus, &:visited {
    color: #fff;
    text-decoration: underline;
  }
`

const ScalingLogo = styled.img`
  height: auto;
  width: 30vw;
  max-width: 130px;
  max-height: 30px;
`

const ContentRow = styled(Row)`
  width: 100%;
  max-width: ${({ theme }) => theme.contentWidth}}
  padding: 48px;
  @media (min-width: 1440px) {
    padding: 48px 0px 48px 0px;
  }
`
const HOMEPAGE_URL = 'https://voteos.com'
const SUPPORT_EMAIL_ADDRESS = 'service+p-voe-support-request@hpm.agency'

const Footer = (props) => {
  const { t } = useTranslation()

  return (
    <VoeFooter {...props}>
      <ContentRow>
        <Col span={22}>
          <Row gutter={[0, 32]}>
            <Col span={24}>
              <a href={HOMEPAGE_URL} target='_blank' rel='noreferrer'>
                <ScalingLogo alt='Logo' src={Logo} />
              </a>
            </Col>
            <Col span={24}>
              <Connectivity /> &nbsp;&nbsp; <Version />
            </Col>
            <Col span={24}>
              <Row gutter={[16, 0]}>
                <Col>
                  <Link href='/legal'><StyledAnchor>{t('footer.legal')}</StyledAnchor></Link>
                </Col>
                <Col>
                  <Link href='/terms'><StyledAnchor>{t('footer.terms')}</StyledAnchor></Link>
                </Col>
                <Col>
                  <StyledAnchor href={`mailto:${SUPPORT_EMAIL_ADDRESS}`} target='_blank' rel='noreferrer'>{t('footer.support')}</StyledAnchor>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={2}>
          <LegalInfos />
        </Col>
      </ContentRow>
    </VoeFooter>
  )
}

export default Footer
